import React from 'react'
import PageHeader from '../components/pageHeader/PageHeader'
import { Tanimlar, VeriGirisi, Onay, Onay2 } from '../resources/resources'
const ResourcesPage = () => {
  return (
    <div className='page-wrapper'>
        <PageHeader title="Kısa Eğitim Videoları" />
        <div className="resources">
            <div className="videos">
                <div className="videos--card">
                    <div className="videos--card--title">
                        Şirket Genel Müdür - Tanımlamalar
                    </div>
                    <video className='videos--card--video' src={Tanimlar} controls />
                </div>
                <div className="videos--card">
                    <div className="videos--card--title">
                        Şirket Yetkilisi - Veri Girişi
                    </div>
                    <video className='videos--card--video' src={VeriGirisi} controls />
                </div>
                <div className="videos--card">
                    <div className="videos--card--title">
                        Şirket CFO/Genel Müdür - Veri Girişi Onayı
                    </div>
                    <video className='videos--card--video' src={Onay} controls />
                </div>
                <div className="videos--card">
                    <div className="videos--card--title">
                        Şirket Genel Müdür – Nihai Kart Onayı
                    </div>
                    <video className='videos--card--video' src={Onay2} controls />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResourcesPage