import React, {useEffect,useState,useRef} from 'react'
import Backdrop from '@mui/material/Backdrop';
import { useAlertDispatch, useAlertState } from '../../context/alert/alertContext';
import * as api from '../../api/index'
import { toast } from 'react-toastify';
import images from '../../images/images';
import roles from '../../constants/Roles'
import IconButton from '../button/IconButton';
import {useCompanyDispatch} from '../../context/company/companyContext';
import Select from 'react-select'
import { COMPANY_POST_DATA, COMPANY_UPDATE_DATA } from '../../context/company/actions';
import {CREATE_COMPANY_MODAL, DELETE_POST, DETAIL_MODAL} from '../../context/alert/actions';
import {RiDeleteBinLine, RiEditBoxLine} from 'react-icons/ri';
import {useLoader} from '../../hooks/useLoader';
import {useRequest} from '../../hooks/useRequest';
import CheckBox from '../checkbox/Checkbox'
import {getUserInfo} from '../../utils/Auth';
const Modal = ({title,whichModal}) => {
    const {detailModal,createCompanyModal,deletePost} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const companyDispatch = useCompanyDispatch()
    const setLoader = useLoader() 
    const request  = useRequest()
    const [companyGroup, setCompanyGroup] = useState()
    const [detail, setDetail] = useState(null)
    const [users,setUsers] = useState()
    const managerDropDownRef = useRef(null)
    const cfoDropdownRef = useRef(null)
    const relatingUsersDropdownRef = useRef(null)
    const companyOfUsersDropdownRef = useRef(null)
    const [generalManagers, setGeneralManagers] = useState([])
    const [companyRelatingUsers, setCompanyRelatingUsers] = useState([])
    const [companyCfo, setCompanyCfo] = useState([])
    const [dragActive, setDragActive] = useState(false);
    const [addedUsers, setAddedUsers] = useState({list:[],send:false})
    const [isLoading,setIsLoading] = useState(false)
    const [noOptionsMessage,setNoOptionsMessage] = useState('En az 3 karakter girmeniz gerekiyor')
    const [cupidUsers, setCupidUsers] = useState([])
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()
    const emptGuid = '00000000-0000-0000-0000-000000000000'
    const [errors,setErrors] = useState({name:"",code:"",status:"",generalManagerId:""})
    const [newRow, setNewRow] = useState({
        status:0, name:'', code:'', logo:'', generalManagerId:emptGuid, cfoId:emptGuid,companyOfCompanyUsers : [],companyOfCompanyRelatingUsers:[], companyDefinition:false,cfoApprovable:false,hyperionField:'', dialogField:''
      })
    const isGm = getUserInfo()?.RoleId == roles.GENERAL_MANAGER_ROLE_ID
    const isCfo = getUserInfo()?.RoleId == roles.CFO_ROLE_ID
    const isStrategy = getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID

    const companyGroups =  companyGroup?.map(c => {
        return {label:c.name,value:c.id,id:'companyGroupId'}
    })

    useEffect(()=> {
    setLoader(true)
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetData(detailModal.id)
                setDetail(data?.data?.result)
                setNewRow(data?.data?.result)
                setPreview(data?.data?.result?.logo)
             }
         } catch (error) {
             console.log(error);
         }   
     }
     const getUsers = async()=> {
        try {
            if(detailModal.open || createCompanyModal.open){
                const data = await api.getAllUsers()
                if (data.data.result) {
                    setUsers(data?.data?.result.sort(function(a, b) {
                        var nameA = a.firstName.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.firstName.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                      
                        // names must be equal
                        return 0;
                      }))
                }
             }
         } catch (error) {
             console.log(error);
         } 
     }
     const getCompanyGroup = async()=> {
        try {
            if(detailModal.open || createCompanyModal.open){
                const data = await api.fetchDataGroup()
                setCompanyGroup(data?.data?.result)
             }
         } catch (error) {
             console.log(error);
         } 
     }
     getCompanyGroup()
     getUsers()
     getDetail()
     setLoader(false)
    },[detailModal,createCompanyModal])
    useEffect(() => {
        if (newRow.code) {
            setGeneralManagers(users?.filter(u => u.roles[0].roleId == roles.GENERAL_MANAGER_ROLE_ID && u.company == newRow.code).map(user => {
                return {value:user.id,label:`${user.firstName} ${user.lastName}`,id:"generalManagerId"}
            }))
            setCompanyCfo(users?.filter(u => u.roles[0].roleId == roles.CFO_ROLE_ID && u.company == newRow.code).map(user => {
                return {value:user.id,label:`${user.firstName} ${user.lastName}`,id:"cfoId"}
            }))
            if ( (managerDropDownRef.current?.getValue() != emptGuid || cfoDropdownRef.current?.getValue() != emptGuid)  && whichModal == 1) {
                managerDropDownRef.current.setValue({})
                cfoDropdownRef.current.setValue({})
                setNewRow(prev => ({...prev,generalManagerId:emptGuid,cfoId:emptGuid}))
            }
            if ((managerDropDownRef.current.getValue() != emptGuid || cfoDropdownRef.current.getValue() != emptGuid) && whichModal == 2 && newRow.code != detail.code) {
                managerDropDownRef.current.setValue({})
                cfoDropdownRef.current.setValue({})
                setNewRow(prev => ({...prev,generalManagerId:emptGuid,cfoId:emptGuid}))
            }
            if ((relatingUsersDropdownRef.current?.getValue().length > 0) && whichModal == 1) {
                relatingUsersDropdownRef.current.setValue([])
                setNewRow(prev => ({...prev,companyOfCompanyRelatingUsers:[]}))
            }
            if ((relatingUsersDropdownRef.current.getValue().length > 0) && whichModal == 2 && newRow.code != detail.code) {
                relatingUsersDropdownRef.current.setValue([])
                setNewRow(prev => ({...prev,companyOfCompanyRelatingUsers:[]}))
            }
            if ((companyOfUsersDropdownRef.current.getValue().length > 0) && whichModal == 1) {
                companyOfUsersDropdownRef.current.setValue([])
                setNewRow(prev => ({...prev,companyOfCompanyUsers:[]}))
            }
            if ((companyOfUsersDropdownRef.current.getValue().length > 0) && whichModal == 2 && newRow.code != detail.code) {
                companyOfUsersDropdownRef.current.setValue([])
                setNewRow(prev => ({...prev,companyOfCompanyUsers:[]}))
            }
            setCompanyRelatingUsers(users?.filter(u => u.roles[0].roleId == roles.STRATEGY_TEAM_ROLE_ID ).map(user => {
                return {value:user.id,label:`${user.firstName} ${user.lastName}`,id:'companyOfCompanyRelatingUsers'}
            }))
        }
    },[newRow.code,users])
    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const handleSearchInputChange = (e) => {
        if (e.length > 2) {
          setIsLoading(true)
          setNoOptionsMessage('Yükleniyor...')
          api.searchUsers(e).then(res => {
            if (res.status == 204 || res.data.result.pidUsers.length < 1) {
              setNoOptionsMessage('Sonuç Bulunamadı')
            }else if (res.status == 200) {
                if(res.data?.result.pidUsers?.filter(u => u.companyCode == newRow?.code)){
                    setNoOptionsMessage('Sonuç Bulunamadı')
                }
                setCupidUsers(res.data?.result.pidUsers?.filter(u => u.companyCode == newRow?.code))
            }
            setIsLoading(false)
          })
        }else {
          setNoOptionsMessage('En az 3 karakter girmeniz gerekiyor')
          setCupidUsers([])
        }
      }

    const handleCheckBox = (e) => {
        setNewRow(prev => ({...prev,[e.target.name]:e.target.checked}))
    }
    // drag and drop functions
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!isGm) {
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
              } else if (e.type === "dragleave") {
                setDragActive(false);
              }
        } else {
            return
        }
      };

      const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!isGm) {
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                let reader = new FileReader();
                reader.readAsDataURL(e.dataTransfer.files[0]);
                let baseURL = "";
        
                // on reader load somthing...
                reader.onload = () => {
                  // Make a fileInfo Object
                  setNewRow({...newRow,logo:reader.result})
                };
                // I've kept this example simple by using the first image instead of multiple
                setSelectedFile(e.dataTransfer.files[0])
            }
        }
        else {
            return
        }
      };

    // select image 
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          setNewRow({...newRow,logo:reader.result})
        };
        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }


    const deleteFunc = async() => {
    setLoader(true)
     try {
           await api.detailDeleteData(detailModal.id)
           alertDispatch({type:DETAIL_MODAL, payload: false})
           alertDispatch({type:DELETE_POST, payload: {id:detailModal.id}})
           toast.success('Silme İşlemi Başarılı')
            setLoader(false)
     } catch (error) {
        setLoader(false)
     }
    }

    const updateFunc = async() => {
        setLoader(true)
        try {
            await addUsers()
           
        } catch (error) {
            setLoader(false)
        }
    
    }
    const modalForOpen = () => {
        switch (whichModal) {
            case 1:
                return createCompanyModal.open
            case 2:
                return detailModal.open
            default:
                return false;
       }
   }

    const closeModal = () => {
        if(whichModal == 1){
            alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
        }else if(whichModal == 2){
            alertDispatch({type: DETAIL_MODAL, payload: false})
        }
    }
    const  handleChangeDropdown = (e) => {
        setNewRow(prev => ({...prev,[e.id]:e.value}))
        setDetail({...detail , [e.id]: e.value})
        setErrors(prev => ({...prev,[e.id]:""}))
    };
    const handleChangeMultiDropDown = (e) => {
        setNewRow(prev => ({...prev, companyOfCompanyUsers:e}))
    }
    const handleNewRowChange = (e) => {
        setNewRow({...newRow, [e.target.name]:e.target.value})
        setErrors(prev => ({...prev,[e.target.name]:""}))
      }
    const validateForm = () => {
        let isValid = true
        if (!newRow.code) {
            setErrors(prev =>  ({...prev,code:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.name) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.generalManagerId) {
            setErrors(prev => ({...prev,generalManagerId:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        if (!newRow.companyGroupId) {
            setErrors(prev => ({...prev,companyGroupId:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        return isValid
    }
    
    const addUsers = async() => {
        if (newRow.companyOfCompanyUsers.filter(u => u.isNew)?.length <1) {
            setAddedUsers({list:[],send:true})
        }
        else {
            newRow.companyOfCompanyUsers?.forEach(async(data) => {
                if (data?.isNew) {
                    const jsonData = {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        userName: data.userName,
                        ePosta:data.ePosta,
                        company:data.company,
                        status:'Active',
                        roles:[
                          { roleId: roles.COMPANY_OFFICIALS_ROLE_ID }
                        ]
                      }
                     await request('/User/Create',{method:'POST',data:jsonData}).then(res => {
                        if (newRow.companyOfCompanyUsers.indexOf(data) == newRow.companyOfCompanyUsers.length -1) {
                            setAddedUsers(prev => ({...prev,list:[...prev.list,{...res.data.result}],send:true}))
                        }else {
                            setAddedUsers(prev => ({...prev,list:[...prev.list,{...res.data.result}]}))
                        }
                      })
                    }
                }
            )
        }

    }
    useEffect(() => {
       if (addedUsers.send == true) {
            addCompany().then(() => {
                setLoader(false)
            })
       }
    },[addedUsers])
    const addCompany = async() => {
        if (whichModal == 1) {
            request('/Company/Create',{method:'POST',data:{...newRow,companyOfCompanyUsers:[...newRow.companyOfCompanyUsers.filter(i => !i.isNew).map(u => ({userId:u.id})),...addedUsers.list.map(u => ({userId:u.id}))]}}).then(res => {
                if (res) {
                    alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
                    companyDispatch({type:COMPANY_POST_DATA, payload: res.data.result})
                    toast.success('Şirket EKleme İşlemi Başarılı')
                }
            })
        }else {
            request('/Company/Update',{method:'POST',data:{...newRow,companyOfCompanyUsers:[...newRow.companyOfCompanyUsers.filter(i => !i.isNew).map(u => ({userId:u.value ? u.value : u.userId})),...addedUsers.list?.map(u => ({userId:u.id}))]}}).then(res => {
                if (res) {
                    companyDispatch({type: COMPANY_UPDATE_DATA, payload: res.data.result})
                    alertDispatch({type:DETAIL_MODAL, payload: false})
                    toast.success('Güncelleme İşlemi Başarılı')
                    setLoader(false)
                }
            })
        }
        
    }
    const createRowBtn = async() => {
        setLoader(true)
        //const form = new FormData()
        //form.append('form',JSON.stringify(newRow)) //JSON.stringify(newRow)
        //console.log("object",form);
        
    //     if (!validateForm()) {
    //         return
    //     }
    //    else {
        // const newData = await api.createPostData(JSON.stringify(newRow)); 
        try {
            await addUsers()
        } catch (error) {
            setLoader(false)
        }
         
         //companyDispatch({type:COMPANY_POST_DATA, payload: newData.data.result})
        //setDataFetch([...dataFetch, newData.data.result])

       //}
    }
    if (whichModal == 2 && !detail) {
        return <div></div>
    }
    const handleFilter = () => {
        return true
      };
  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modalForOpen}  
        >
            <div className='modalCreateTarget'>
            <div className='company-modal'>
                <div className="company-modal--container">
                    <div className="company-modal--container--body--half-width">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Şirket Adı *
                            </div>
                            <input disabled={isGm || isCfo} type="text" placeholder='Şirket adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.name} name="name" onChange={handleNewRowChange} value={newRow.name} />
                            {errors.name && <span className='company-modal--container--error'>{errors.name}</span>}
                        </div>
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Şirket Hyperion Kodu
                            </div>
                            <input disabled={isGm || isCfo} type="text" placeholder='Şirket kodu girin' className='company-modal--container--input-area--input' defaultValue={detail?.hyperionField} name="hyperionField" onChange={handleNewRowChange} value={newRow.hyperionField}/>
                            {errors.hyperionField && <span className='company-modal--container--error'>{errors.hyperionField}</span>}
                        </div>
                    </div>
                    <div className="company-modal--container--body">
                        <div className="company-modal--container--body--half-width">
                            <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                    Şirket Kodu *
                                </div>
                                <input disabled={isGm || isCfo} type="text" placeholder='Şirket kodu girin' className='company-modal--container--input-area--input' defaultValue={detail?.code} name="code" onChange={handleNewRowChange} value={newRow.code}/>
                                {errors.code && <span className='company-modal--container--error'>{errors.code}</span>}
                            </div>
                            <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                    Grubu *
                                </div>
                                <div className='react-dropdown-div'>
                                    <Select isDisabled={isGm || isCfo} matchPos='start' matchProp='label' onChange={handleChangeDropdown}  defaultValue={detail?.companyGroup ? {label:detail.companyGroup.name,value:detail.companyGroup.id,id:'companyGroupId'}:null} placeholder="Seçiniz" name="companyGroupId" options={companyGroups} />
                                </div>
                                {errors.companyGroupId && <span className='company-modal--container--error'>{errors.companyGroupId}</span>}
                            </div>
                        </div>
                        <div className="company-modal--container--body--half-width">
                            <div className="company-modal--container--input-area">
                                <div className='company-modal--container--input-area-rows--row'>
                                    <div className="company-modal--container--input-area--label">
                                        Şirket Genel Müdürü *
                                    </div>
                                    <div className='react-dropdown-div'>
                                        <Select isDisabled={isGm || isCfo} ref={managerDropDownRef} onChange={handleChangeDropdown} defaultValue={detail && {value:detail?.generalManagerId,label:detail?.generalManager,id:"generalManagerId"}} name="generalManagerId" placeholder="Seçiniz"  options={generalManagers} />
                                    </div>
                                    {errors.generalManagerId && <span className='company-modal--container--error'>{errors.generalManagerId}</span>}
                                </div>
                            </div>
                            <div className="company-modal--container--input-area">
                            <div className='company-modal--container--input-area-rows--row'>
                                <div className="company-modal--container--input-area--label">
                                    Şirket İlgili Kişileri
                                </div>
                                <div className="react-dropdown-div">
                                    <Select ref={relatingUsersDropdownRef} isDisabled={!isStrategy} isMulti options={companyRelatingUsers} defaultValue={detail?.companyOfCompanyRelatingUsers ? [...detail.companyOfCompanyRelatingUsers.map(u => ({value:u.userId,label:u.companyUser,id:'companyOfCompanyRelatingUsers'}))]:null} onChange={(values)=>{values.length > 0 && setNewRow(prev => ({...prev,companyOfCompanyRelatingUsers:[...values?.map(i => ({userId:i.value}))]}))}} placeholder='Seçiniz' />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="company-modal--container--body--half-width">
                            <div className="company-modal--container--input-area-rows">
                                <div className='company-modal--container--input-area-rows--row'>
                                    <div className="company-modal--container--input-area--label">
                                        Şirket CFO'su *
                                    </div>
                                    <div className="react-dropdown-div">
                                        <Select isDisabled={isCfo} ref={cfoDropdownRef} options={companyCfo} defaultValue={detail && {label:detail.cfo,value:detail.cfoId,id:'cfoId'}} onChange={handleChangeDropdown} placeholder='Seçiniz' />
                                    </div>
                                    {errors.companyGroupId && <span className='company-modal--container--error'>{errors.companyGroupId}</span>}
                                </div>
                                <div className='company-modal--container--input-area-rows--row'>
                                    <div className="company-modal--container--input-area--label">
                                        Şirket Yetkilileri
                                    </div>
                                    <div className="react-dropdown-div">
                                        <Select ref={companyOfUsersDropdownRef}  filterOption={handleFilter} isMulti isLoading={isLoading} defaultValue={detail?.companyOfCompanyUsers ? [...detail.companyOfCompanyUsers.map(u => ({value:u.userId,label:u.companyUser,id:u.userId}))]:null} onChange={handleChangeMultiDropDown} onInputChange={handleSearchInputChange} options={cupidUsers && cupidUsers?.map(o => ({userId:o.id,label:`${o.firstName} ${o.lastName}`,value:o.id,firstName:o.firstName,lastName:o.lastName,userName:o.userName,company:o.companyCode,ePosta:o.eMail,id:o.id,isNew:true}))} noOptionsMessage={() => noOptionsMessage} loadingMessage={() => noOptionsMessage} placeholder='Seçiniz' />
                                    </div>
                                </div>
                            </div>
                            <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                    Şirket Logosunu Yükle
                                </div>
                                <div className={`company-modal--container--input-area--drag-drop ${dragActive ? 'active':''}`} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={ handleDrop}>
                                    <img className='company-modal--container--input-area--drag-drop--logo' src={preview ? preview : images.upload} alt="" />
                                    {
                                        preview && <div className='company-modal--container--input-area--drag-drop--success-area'>
                                        <img src={images.greenTick} alt="Ok" />
                                        <span>Logo Yüklendi</span>
                                    </div>
                                    }
                                    {
                                        (!isGm  && !isCfo) && <div className='company-modal--container--input-area--drag-drop--text-area'>
                                        Sürükle bırak veya <input onChange={onSelectFile} id="file-uploader" hidden type="file" className='company-modal--container--input-area--drag-drop--text-area--upload-input'/> <label htmlFor="file-uploader" className='company-modal--container--input-area--drag-drop--text-area--upload-label'>BURADAN</label> yükle
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="company-modal--container--body--half-width">
                        <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                    Diyalog Alanı
                                </div>
                                <input disabled={isGm || isCfo} type="text" placeholder='Diyalog alanı girin' className='company-modal--container--input-area--input' defaultValue={detail?.dialogField} name="dialogField" onChange={handleNewRowChange} value={newRow.dialogField}/>
                                {/* {errors.code && <span className='company-modal--container--error'>{errors.code}</span>} */}
                            </div>
                            <div className="company-modal--container--input-area">
                                <div className='company-modal--container--input-area-rows--row'>
                            
                                </div>
                            </div>
                        </div>
                        <div className="company-modal--container--body--half-width">
                        <div className="company-modal--container--input-area">

                            <div className='company-modal--container--body--checkbox'>
                                <CheckBox disabled={isCfo} name='companyDefinition' className='justify-content-start' label='Şirket Yetkilileri Onaylayabilir Mi?' handleChange={handleCheckBox} checked={newRow?.companyDefinition} />
                            </div>
                            </div>
                            <div className="company-modal--container--input-area">
                            <div className='company-modal--container--body--checkbox'>
                                <CheckBox disabled={isCfo} name='cfoApprovable' className='justify-content-start' label='Şirket CFO Onaylayabilir Mi?' handleChange={handleCheckBox} checked={newRow?.cfoApprovable} />
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='company-modal--container--footer'>
                    {
                        whichModal == 1 &&  <IconButton buttonClick={createRowBtn} imgRight="OK"  title="OLUŞTUR" image={images.rightArrow} maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                    }
                    {
                        whichModal == 2 && <>
                            <IconButton icon ={ <RiEditBoxLine style={{width:"20px"}} color="#fff" fill="#fff" /> }   buttonClick={updateFunc} imgRight="OK" title="Güncelle" maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                            <IconButton icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> } buttonClick={deleteFunc} imgRight="OK" title="Sil" maxWidth="130px" background="#f9423a" color='white' borderColor='#f9423a'/>
                        </>
                    }
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default Modal