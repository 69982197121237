import React from 'react'

const ChangedButton = ({bgColor, value, focusUpdate,onBlur, onChange, name,disabled}) => {
  return (
    <div className='changed-button'>
        <input disabled={disabled} onBlur={onBlur} onFocus={focusUpdate} name={name} style={{backgroundColor: bgColor ? bgColor : ''}} onChange={onChange}  type="text" key={value}  defaultValue={value} />
    </div>
  )
}

export default ChangedButton