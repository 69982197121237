import React from 'react'
import DataTableComp from '../components/groupManagementPage/DataTableComp'

const GroupManagementPage = () => {
  return (
 <div className='group-management-page' >
    <DataTableComp/>    
</div>
  )
}

export default GroupManagementPage