import React, {useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import Select from 'react-select'
import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import { useCompanyDispatch } from '../../../context/company/companyContext';
import images from '../../../images/images';
import IconButton from './../../button/IconButton';
import { AIM_POST_DATA } from '../../../context/company/actions';
import {CREATE_COMPANY_MODAL} from '../../../context/alert/actions';
import { useLoader } from '../../../hooks/useLoader';
import {useRequest} from '../../../hooks/useRequest';
import useToastify from '../../../hooks/useToastify';

const AimCreateModal = ({title}) => {
    const {detailModal,createCompanyModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const companyDispatch = useCompanyDispatch()
    const setLoader = useLoader()
    const request = useRequest()
    const { errorToast } = useToastify()
    const [detail, setDetail] = useState(null)
    const [errors,setErrors] = useState({name:"",description:"",isPossibleCompany:"",type:""})
    const [newRow, setNewRow] = useState({
        status:0, name:'', description:'', isPossibleCompany:'', type:'',hyperionField:'', isCurrencyCycleRequired:false, sourceCurrency:'', targetCurrency:'', targetOfCurrencyCycleCompanies:[],dialogField:''
      })
    const [companies, setCompanies] = useState([])

    const defaultOptions =  [
        { value: true, label: 'Evet',id:"isPossibleCompany" },
        { value: false, label: 'Hayır',id:"isPossibleCompany" },
      ]
      const defaultOptions2 =  [
        { value: 0, label: 'Manuel',id:"type" },
        { value: 1, label: 'Hyperion',id:"type" },
        { value: 2, label: 'Excel',id:"type" }

      ]
      const defaultOptions3 =  [
        { value: true, label: 'Evet',id:"isCurrencyCycleRequired" },
        { value: false, label: 'Hayır',id:"isCurrencyCycleRequired" },
      ]
    useEffect(()=> {
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetDataAim(detailModal.id)
                setDetail(data?.data?.result)
                setNewRow(data?.data?.result)
             }
         } catch (error) {
             console.log(error);
         }   
     }
     getDetail()
    },[detailModal])

   useEffect(() => {
    if (newRow.isCurrencyCycleRequired) {
        api.fetchData().then(res => {
            console.log(res,'resssss')
            setCompanies(res.data.result.map(t => ({label:t.name,value:t.id,...t})))
        })
    }
   },[newRow.isCurrencyCycleRequired])

    const closeModal = () => {
        alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
    }
    const  handleChangeDropdown = (e) => {
        setNewRow(prev => ({...prev,[e.id]:e.value}))
        setErrors(prev => ({...prev,[e.id]:""}))
    };
    const handleChangeCompanies = (e) => {
        setNewRow(prev => ({...prev,targetOfCurrencyCycleCompanies:e.map(t => ({companyId:t.value}))}))
    }
    const handleNewRowChange = (e) => {
        setNewRow({...newRow, [e.target.name]:e.target.value})
        setErrors(prev => ({...prev,[e.target.name]:""}))
      }
    const validateForm = () => {
        let isValid = true
        if (!newRow.description) {
            setErrors(prev =>  ({...prev,description:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.name) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.type) {
            setErrors(prev => ({...prev,type:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        if (!newRow.isPossibleCompany) {
            setErrors(prev => ({...prev,isPossibleCompany:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        return isValid
    }
    const createRowBtn = async() => {
        if (newRow.type == 1 && !newRow.hyperionField) {
            errorToast('Hyperion alanı boş bırakılmamalıdır.')
        } else if(newRow.type == 1 && newRow.isCurrencyCycleRequired && (!newRow.sourceCurrency || !newRow.targetCurrency)) {
            errorToast('Kaynak ve Hedef Kur alanları boş bırakılmamalıdır.')
        }
        else {
            setLoader(true)
            await request('/TargetKPI/Create',{method:'POST',data:newRow}).then(res => {
                if (res) {
                    companyDispatch({type: AIM_POST_DATA, payload: res.data.result})
                    alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
                    toast.success("Ekleme İşleminiz Başarılı !!!")
                }
            }).finally(() => setLoader(false))
        }
    }
    console.log('newRow', newRow)
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(1px)",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={createCompanyModal.open}
      >
        <div className="modalCreateTarget">
          <div className="company-modal">
            <div className="company-modal--container">
              <div className="company-modal--container--head">
                <div className="company-modal--container--input-area">
                  <div className="company-modal--container--input-area--label">
                    Hedef Adı *
                  </div>
                  <input
                    type="text"
                    placeholder="Hedef adı girin"
                    className="company-modal--container--input-area--input"
                    defaultValue={detail?.name}
                    name="name"
                    onChange={handleNewRowChange}
                    value={newRow.name}
                  />
                  {errors.name && (
                    <span className="company-modal--container--error">
                      {errors.name}
                    </span>
                  )}
                </div>
              </div>

             


              <div className="company-modal--container--body">
                <div className="company-modal--container--body--half-width">
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                      Hedef Açıklaması
                    </div>
                    <input
                      type="text"
                      placeholder="Hedef açıklaması girin"
                      className="company-modal--container--input-area--input"
                      defaultValue={detail?.description}
                      name="description"
                      onChange={handleNewRowChange}
                      value={newRow.description}
                    />
                    {errors.description && (
                      <span className="company-modal--container--error">
                        {errors.description}
                      </span>
                    )}
                  </div>
                </div>

                <div className="company-modal--container--body--half-width">
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                      Hedef Tipi *
                    </div>
                    <div className="react-dropdown-div">
                      <Select
                        onChange={handleChangeDropdown}
                        name="type"
                        placeholder={"Seçiniz"}
                        options={defaultOptions2}
                      />
                    </div>
                    {errors.type && (
                      <span className="company-modal--container--error">
                        {errors.type}
                      </span>
                    )}
                  </div>

                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                      Şirket Girişi Mümkün Mü? *
                    </div>
                    <div className="react-dropdown-div">
                      <Select
                        onChange={handleChangeDropdown}
                        placeholder={"Seçiniz"}
                        name="isPossibleCompany"
                        options={defaultOptions}
                      />
                    </div>
                    {errors.isPossibleCompany && (
                      <span className="company-modal--container--error">
                        {errors.isPossibleCompany}
                      </span>
                    )}
                  </div>
                </div>
                {newRow.type == 1 && (
                <div className="company-modal--container--body--half-width">
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                      Hyperion Alanı *
                    </div>
                    <input
                      type="text"
                      placeholder="Hyperion alanı girin"
                      className="company-modal--container--input-area--input"
                      defaultValue={detail?.hyperionField}
                      name="hyperionField"
                      onChange={handleNewRowChange}
                      value={newRow.hyperionField}
                    />
                    {errors.hyperionField && (
                      <span className="company-modal--container--error">
                        {errors.hyperionField}
                      </span>
                    )}
                  </div>
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                        Kur Çevrimi Gerekli Mi? *
                    </div>
                    <div className="react-dropdown-div">
                      <Select
                        onChange={handleChangeDropdown}
                        placeholder={"Seçiniz"}
                        name="isCurrencyCycleRequired"
                        defaultValue={{ value: false, label: 'Hayır',id:"isCurrencyCycleRequired" }}
                        options={defaultOptions3}
                      />
                    </div>
                    {errors.isPossibleCompany && (
                      <span className="company-modal--container--error">
                        {errors.isPossibleCompany}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {newRow.isCurrencyCycleRequired && (
                <div className="company-modal--container--body--half-width">
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                        Kaynak Kur *
                    </div>
                    <input
                      type="text"
                      placeholder="Hyperion alanı girin"
                      className="company-modal--container--input-area--input"
                      defaultValue={detail?.sourceCurrency}
                      name="sourceCurrency"
                      onChange={handleNewRowChange}
                      value={newRow.sourceCurrency}
                    />
                  </div>
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                        Hedef Kur *
                    </div>
                    <input
                      type="text"
                      placeholder="Hyperion alanı girin"
                      className="company-modal--container--input-area--input"
                      defaultValue={detail?.targetCurrency}
                      name="targetCurrency"
                      onChange={handleNewRowChange}
                      value={newRow.targetCurrency}
                    />
                  </div>
                </div>
              )}
              {
                newRow.isCurrencyCycleRequired && (
                    <div className="company-modal--container--head-100">
                <div className="company-modal--container--input-area">
                  <div className="company-modal--container--input-area--label">
                    Şirketler
                  </div>
                  <div className="react-dropdown-div w-100">
                  <Select isMulti onChange={handleChangeCompanies} options={companies}  placeholder='Seçiniz' />
                  </div>
                </div>
              </div>
                )
              }
              </div>
              <div className="company-modal--container--footer">
                <IconButton
                  buttonClick={createRowBtn}
                  imgRight="OK"
                  title="OLUŞTUR"
                  image={images.rightArrow}
                  maxWidth="130px"
                  background="#1392E5"
                  color="white"
                  borderColor="#1392E5"
                />
              </div>
              <div
                className="company-modal--container--close-btn"
                onClick={closeModal}
              >
                <img src={images.close} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </>
  );
}

export default AimCreateModal