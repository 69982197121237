
import React from 'react'
import {initialState, alertReducer} from './alertReducer';
 
const AlertStateContext = React.createContext()
const AlertDispatchContext = React.createContext()

const AlertProvider = ({children}) => {
    const [state,dispatch] = React.useReducer(alertReducer,initialState)

    return (
        <AlertStateContext.Provider value={state}>
              <AlertDispatchContext.Provider value={dispatch}>
                  {children}
              </AlertDispatchContext.Provider>
        </AlertStateContext.Provider>
    )
}

const useAlertState = () => {
    const context = React.useContext(AlertStateContext)
    if(context === undefined) {
        throw new Error('useAlertState must be under AlertProvider!')
    }
    return context
}

const useAlertDispatch = () => {
    const context = React.useContext(AlertDispatchContext)
    if(context === undefined) {
        throw new Error('useAlertDispatch must be under AlertProvider!')
    }
    return context
}

export {AlertProvider, useAlertDispatch, useAlertState}