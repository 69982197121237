import React, { useState } from 'react'
import images from '../../images/images'
import IconButton from '../button/IconButton'
import TextSquare from '../textSquare/TextSquare'
import * as api from '../../api/index'
import { useCompanyDispatch, useCompanyState } from '../../context/company/companyContext'
import { COMPANY_FETCH_DATA_DETAIL } from '../../context/company/actions'
import CeoGmCompanyApproveModal from '../modal/ceoGmCompanyApproveModal/CeoGmCompanyApproveModal'
import { useEffect } from 'react'
import currencyFormatter from '../../utils/InputFormatter'
import {getUserInfo} from '../../utils/Auth'
import roles from '../../constants/Roles'
import HtmlExport from '../../utils/HtmlExport'
import TargetApprovalModal from '../modal/targetApprovalModal/TargetApprovalModal'
import { toast } from 'react-toastify'

const CompanyCreateTarget = ({companyDetail,setCompanyDetail,id}) => {
  // const companyDispatch = useCompanyDispatch()
  const [modal, setModal] = useState({open : false, workFunc: {}})
  const [approvalModal, setApprovalModal] = useState({open:false,detail:[]})
  const {dataFetchCompanyCardDetail,dataFetchCompanyCardBonusDetail,takeMessage} = useCompanyState()
  const companyDispatch = useCompanyDispatch()
  const userInfo = getUserInfo()
 
    useEffect(()=>{
      const companyCreateCardDetail = async () => {
       try {
         const detailData = await api.fetchDataCardCompanyDetail(id) 
         companyDispatch({type:COMPANY_FETCH_DATA_DETAIL, payload: detailData?.data?.result})

       } catch (error) {

       }
      }
      const companyCreateBonusCardDetail = async () => {
        try {
          const detailData = await api.fetchDataCardCompanyBonusDetail(id) 
          companyDispatch({type:"COMPANYCARDFETCHDATABONUSDETAIL", payload: detailData?.data?.result}) 
     
        } catch (error) {

        }
       }
       
      companyCreateBonusCardDetail()
      companyCreateCardDetail()
     },[])

     const [sendExcelData,setSendExcelData] = useState()
     const [sendExcelDataBonus,setSendExcelDataBonus] = useState()
     const [excelHeader,setExcelHeader] = useState([])

     useEffect(()=>{
      setExcelHeader([companyDetail?.companies?.name, companyDetail?.period?.name])
      var talk= Number(companyDetail?.periods?.name) - 1 + " Gerçekleşen"
       setSendExcelData(
       
        dataFetchCompanyCardDetail?.map(data => (
          companyDetail?.permission?.isPeriodBegin ?
          {"Hedef Adı": data?.targetKpi?.name,
           "Birim": data?.unit?.name,
           "Ağırlık":currencyFormatter(data?.weight),
            [talk]: currencyFormatter(data?.lastYearTarget),
           "Alt Limit Çarpanı": currencyFormatter(data?.underLimitMultiplier),
           "Üst Limit Çarpanı": currencyFormatter(data?.upperLimitMultiplier),
           "Hedef": currencyFormatter(data?.target),
           "0 Puan": currencyFormatter(data?.underLimitValue),
           "150 Puan": currencyFormatter(data?.upperLimitValue),
           //"Gerçekleşen": data?.occured,
           //"Puan": data?.score,
           //"Ağırlıklı Puan": data?.weightedScore,
           //"Sirket Adı":companyDetail?.companies?.name,
           //"Toplam Puan":companyDetail?.totalScore,
           //"Ara Puan Toplamı":companyDetail?.subTotalScore,
         } : 
         {"Hedef Adı": data?.targetKpi?.name,
         "Birim": data?.unit?.name,
         "Ağırlık": currencyFormatter(data?.weight),
         [talk]: currencyFormatter(data?.lastYearTarget),
         "Alt Limit Çarpanı": currencyFormatter(data?.underLimitMultiplier),
         "Üst Limit Çarpanı": currencyFormatter(data?.upperLimitMultiplier),
         "Hedef": currencyFormatter(data?.target),
         "0 Puan": currencyFormatter(data?.underLimitValue),
         "150 Puan": currencyFormatter(data?.upperLimitValue),
         "Gerçekleşen": currencyFormatter(data?.occured),
         "Puan": currencyFormatter(data?.score),
         "Ağırlıklı Puan": currencyFormatter(data?.weightedScore),
         //"Sirket Adı":companyDetail?.companies?.name,
         //--//"Toplam Puan":companyDetail?.totalScore,
         //"Ara Puan Toplamı":companyDetail?.subTotalScore,
       }
           )) 
      )    
      setSendExcelDataBonus(
        dataFetchCompanyCardBonusDetail?.map(data => (
          companyDetail?.permission?.isPeriodBegin ?
          {//"Hedef Adı": data?.targetKpi?.name,
           //"Birim": data?.unit?.name,
           //"Ağırlık": data?.weight,
           "Hedef Adı": data?.targetKpi?.name,
           //"Sirket Adı":companyDetail?.companies?.name,
           "Puan Değeri":currencyFormatter(data?.scoreValue),
           //"Ara Puan Toplamı":companyDetail?.subTotalScore,
           "": "",
           " ": "",
           "  ": "",
           "   ": "",
           "    ": "",
           "     ": ""
           }  :
           {"Hedef Adı": data?.targetKpi?.name,
           "Puan Değeri": currencyFormatter(data?.scoreValue),
           //"Sirket Adı":companyDetail?.companies?.name,
           //--//"Toplam Puan":companyDetail?.totalScore,
           //"Ara Puan Toplamı":companyDetail?.subTotalScore,
           "": "",
           " ": "",
           "  ": "",
           "   ": "",
           "    ": "",
           "     ": "",
           "      ": "",
           "       ": "",
           "        ": "",
           "         ": "",
           }
            ))
         ) 

     },[dataFetchCompanyCardDetail,dataFetchCompanyCardBonusDetail])

     const [takingMsg, setTakingMsg] = useState([])

     useEffect(()=>{
      setTakingMsg(takeMessage?.map(data => (
        companyDetail?.permission?.isPeriodBegin ?
        {
          "Dipnot": data.tag,
          "Acıklama":data.message,
          "":"", 
          " ":"", 
          "  ":"", 
          "   ":"", 
          "    ":"", 
          "     ":"", 
          "      ":""
        } :
        {
          "Dipnot": data.tag,
          "Acıklama":data.message,
          "":"", 
          " ":"", 
          "  ":"", 
          "   ":"", 
          "    ":"", 
          "     ":"", 
          "      ":"", 
          "       ":"", 
          "        ":"", 
          "         ":"",
          "          ":"",
          "           ":"",
        }
       )))
     },[takeMessage])

     const dialogSend = async() => {
      let data = {
          id: companyDetail?.id,
          companyId: companyDetail?.companyId,
          periodId: companyDetail?.periodId
      }

      // toast.error("Kayıt bulunmamaktadır.", {
      //   autoClose: false 
      // })

     

      try {
        const datas = await api.dialogSendEnd(data);

        if(datas.status == 200){
          toast.success("Diyalog Servisi Başarıyla Tamamlandı")
        } 
        } catch (error) {
          toast.error("Diyalog Servisi Tamamlanamadı", {autoClose: false})
        }    
     }


  return (
    <div className='company-create-target'>
        <div className='company-create-target-header'>
            <div className='company-create-target-header-name'>Şirket Adı:</div>
            <div className='company-create-target-header-year'>Yılı:</div>
            <div className='company-create-target-header-statu'>Durumu:</div>
        </div>
        <div className='company-create-target-body'>
              <TextSquare title={companyDetail?.companies?.name} maxWidth="230px" image={companyDetail?.companies?.logo}/>
              <TextSquare title={companyDetail?.periods?.name} maxWidth="80px"/> 
              <TextSquare title={companyDetail?.displayName} maxWidth="200px"/> 
              {/* <IconButton buttonClick={excelDownload} title="Excel" image={images.excelPrint} maxWidth="100px" color='#4DCE69' borderColor='#4DCE69'/> */}
              {/* {companyDetail?.permission?.isExcelExport && <ExcelExportDownload excelHeader={excelHeader} occuredBonusScore={companyDetail?.occuredBonusScore} takeMessage={takingMsg} totalScore={companyDetail?.totalScore} name={companyDetail?.companies?.name} company={companyDetail?.permission?.isPeriodBegin}  csvData={sendExcelData} csvData2={sendExcelDataBonus} fileName={companyDetail?.companies?.name+'_'+ companyDetail?.periods?.name + '_ŞirketKartı' }/>} */}
              {companyDetail?.permission?.isExcelExport && <HtmlExport companyDetail={companyDetail} dataFetchCompanyCardDetail={dataFetchCompanyCardDetail} dataFetchCompanyCardBonusDetail={dataFetchCompanyCardBonusDetail} isBegin={companyDetail?.permission?.isPeriodBegin} takeMessage={takeMessage} fileName={companyDetail?.companies?.name+'_'+ companyDetail?.periods?.name + '_ŞirketKartı' }/>}
              <div className='company-create-target-body-right'>
              {/* buttonClick={()=>approveSend(1) } */}
              {getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID  && <IconButton rotate={'180deg'} buttonClick={()=>setModal({open:true,workFunc:{name:"checked",prevStatu:companyDetail?.permission?.forwardPreviousAuthorityStatus}})} title="Önceki Statüye Dön" image={images.rightArrow} maxWidth="150px" background="#f9423a" color='white' borderColor='#f9423a'/>}
             {companyDetail?.permission?.isCeoForWaitingForMinisterApporval  && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"approveSend", status:'1'}}) } title="CEO Onayına Gönder" image={images.iconSend} maxWidth="170px" color='#F4B62D' borderColor='#F4B62D'/>}
               {companyDetail?.permission?.isWaitingForMinisterApproval && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"approveSend", status:'2'}})} title="Başkana Gönder" image={images.iconSend} maxWidth="150px" color='#F4B62D' borderColor='#F4B62D'/>}
              { companyDetail?.permission?.isWaitingForGmApproval && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"approveSend", status:'3'}})} title="Şirkete Gönder" image={images.iconSend} maxWidth="145px" color='#F4B62D' borderColor='#F4B62D'/>}
              {(userInfo?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || userInfo?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) && companyDetail?.permission?.isApprvalBeginPeriod && <IconButton buttonClick={()=>setApprovalModal({open:true})} title="Dönem Sonu Sürecini Başlat" maxWidth="245px" color='#F4B62D' borderColor='#F4B62D'/>}
               &nbsp; &nbsp; &nbsp; &nbsp;
               { companyDetail?.permission?.isApprval && companyDetail?.processStatus != "ActualGmDataEntry" && companyDetail?.processStatus != "WaitingForGmApproval"&& <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"checked"}})} title="Onayla" image={images.whiteTick} background="#4DCE69" maxWidth="110px" color='white' borderColor='#4DCE69'/>}
               { companyDetail?.permission?.isApprval && companyDetail?.processStatus == "WaitingForGmApproval" && (getUserInfo()?.RoleId != roles.COMPANY_OFFICIALS_ROLE_ID && getUserInfo()?.RoleId != roles.CFO_ROLE_ID  )  && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"checked"}})} title="Onayla" image={images.whiteTick} background="#4DCE69" maxWidth="110px" color='white' borderColor='#4DCE69'/>}
               { companyDetail?.permission?.isApprval && companyDetail?.processStatus == "ActualGmDataEntry" && getUserInfo()?.RoleId != roles.COMPANY_OFFICIALS_ROLE_ID  && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"checked",nextStatu:16}})} title="Onayla" image={images.whiteTick} background="#4DCE69" maxWidth="110px" color='white' borderColor='#4DCE69'/>}
               { companyDetail?.permission?.isApprval && companyDetail?.processStatus == "WaitingForGmApproval" && (getUserInfo()?.RoleId == roles.COMPANY_OFFICIALS_ROLE_ID || getUserInfo()?.RoleId == roles.CFO_ROLE_ID) && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"checked"}})} title="Veri Girişini Bitir" image={images.whiteTick} maxWidth="150px" background="#4DCE69" color='white' borderColor='#4DCE69'/>}
               { companyDetail?.permission?.isApprval && companyDetail?.processStatus == "ActualGmDataEntry" && getUserInfo()?.RoleId == roles.COMPANY_OFFICIALS_ROLE_ID  && <IconButton buttonClick={()=>setModal({open:true,workFunc:{name:"checked"}})} title="Veri Girişini Bitir" image={images.whiteTick} maxWidth="150px" background="#4DCE69" color='white' borderColor='#4DCE69'/>}
               </div>
        </div>
        <div style={{display:'flex', justifyContent:'flex-end', marginRight:'20px'}} className=''>
       {(companyDetail?.permission?.isCeoForWaitingForMinisterApporval || companyDetail?.permission?.isWaitingForMinisterApproval || companyDetail?.permission?.isWaitingForGmApproval) && <IconButton buttonClick={dialogSend} title="Diyaloga Aktar" image={""} maxWidth="146px" background="white" color='black' borderColor='black'/>}
        </div>
        {
          modal.open && <CeoGmCompanyApproveModal setCompanyDetail={setCompanyDetail} companyDetail={companyDetail}  modal={modal} setModal={setModal}/>
        }
        {
           approvalModal.open && <TargetApprovalModal setCompanyDetail={setCompanyDetail} companyDetail={companyDetail} setApprovalModal={setApprovalModal}  approvalModal={approvalModal} />
        }
    </div>
  )
}

export default CompanyCreateTarget