import React from 'react'

const PagesBox = ({children}) => {
 // sayfa yapısına göre dogru bir container ayarlanacak... 
 const styles = {
    backgroundColor:'#f8fbff',
    //width:'1230px',
    width:'100%',
    //margin:'auto',
    height:'100%',
    overflowX: "auto",
    overflowY:'none'
  }

 return (
    <div /*className='pagesBox-container'*/ style={{...styles}}>{children}</div>
  )
}

export default PagesBox