import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from "../../api/index";
import Button from "../button/Button";
import {
  useAlertDispatch,
  useAlertState,
} from "../../context/alert/alertContext";
import {
  useCompanyDispatch,
  useCompanyState,
} from "../../context/company/companyContext";
import CompanyCardDeleteUpdateModal from "./../modal/companyCardPageModals/CompanyCardDeleteUpdateModal";
import {
  COMPANY_CARD_DELETE_DATA,
  COMPANY_CARD_FETCH_DATA,
  FILTER_OPTIONS,
} from "../../context/company/actions";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CREATE_COMPANY_MODAL, DELETE_COMPANY_CARD_MODAL } from "../../context/alert/actions";
import CompanyCreateModal from "../modal/createCompanyCard/CompanyCreateModal";
import { getUserInfo } from "../../utils/Auth";
import roles from "../../constants/Roles";
import DeleteCompanyCardModal from "../modal/deleteCompanyCard/DeleteCompanyCardModal";
import useToastify from "../../hooks/useToastify";
import TableLoader from "../loader/TableLoader";

const DataTableComp = () => {
  const { detailModal, deletePost,createCompanyModal, deleteCompanyCardModal } = useAlertState();
  const { dataFetchCompanyCard, filterOptions } = useCompanyState();
  const companyDispatch = useCompanyDispatch();
  const alertDispatch = useAlertDispatch();
  const {errorToast} = useToastify()
  const navigate = useNavigate();
  const [groupOptions, setGroupOptions] = useState([ ]);
  const [yearOptions, setYearOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [pag, setPag] = useState()
  const [btnVisible, setBtnVisible] = useState(false)
  const [loading,setLoading] = useState(false)
  const handleonChange = (e) => {    
    companyDispatch({
      type: FILTER_OPTIONS,
      payload: {...filterOptions,[e.type]:{...e}},
    });
  };

  //delete post
  useEffect(() => {
    if (deletePost?.id) {
      companyDispatch({
        type: COMPANY_CARD_DELETE_DATA,
        payload: deletePost.id,
      });
    }
  }, [detailModal.open]);
  useEffect(() => {
            
    const fetchData = async () => {
      setLoading(true)
      try {        
        const data = await api.fetchDataCardCompany(filterOptions?.group?.id,filterOptions?.year?.id,filterOptions?.periodOption?.periodFilterStatu == 1 ? '' : filterOptions?.status?.id);
        if(data?.data?.result?.length < 1 )
        {
          companyDispatch({
            type: COMPANY_CARD_FETCH_DATA,
            payload: data.data?.result
          });
          errorToast("Seçtiğiniz filtreye uygun kayıt bulunmamaktadır.")
          setLoading(false)
        }
        else {
          if (filterOptions?.periodOption.periodFilterStatu == 1) {
            companyDispatch({
              type: COMPANY_CARD_FETCH_DATA,
              payload: data.data?.result
            });
            setLoading(false)
          }
          else if (filterOptions?.periodOption.periodFilterStatu == 2){
            if (data.data?.result.filter(r => r.permission.isPeriodBegin == true).length == 0) {
              errorToast("Seçtiğiniz filtreye uygun kayıt bulunmamaktadır.")
            }
            companyDispatch({
              type: COMPANY_CARD_FETCH_DATA,
              payload: data.data?.result.filter(r => r.permission.isPeriodBegin == true)
            });
            setLoading(false)
          }
          else if (filterOptions?.periodOption.periodFilterStatu == 3){
            if (data.data?.result.filter(r => r.permission.isPeriodBegin == false).length == 0) {
              errorToast("Seçtiğiniz filtreye uygun kayıt bulunmamaktadır.")

            }
            companyDispatch({
              type: COMPANY_CARD_FETCH_DATA,
              payload: data.data?.result.filter(r => r.permission.isPeriodBegin == false)
            });
            setLoading(false)
          }
        }
        

      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    };

    fetchData();
  }, [filterOptions]);


  const columns = [
    {
      name: "Şirket ",
      selector: row => row.year,
      sortable: true,
    },
    {
      name: "Grup",
      selector: row => row.companyName,
      sortable: true,
    },
    {
      name: "Yıl",
      selector: row => row.periods,
      sortable: true,
    },
    {
      name: "Puan",
      selector: row => row.score,
      sortable: true,
    },
    {
      name: "Durum",
      selector: row => row.displayName,
      sortable: true,
    },
    {
      name: "",
      selector: row => row.genres,
         cell: (d) => (
        <>
        <a
          href={`/company-card/${d.id}`}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            textDecoration: 'none'
          }}
        >
          <Button title="Detaylar" maxWidth="130px" height="35px" />
        </a>
        {
          (getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) ? <div
          onClick={() => {
            alertDispatch({
              type: DELETE_COMPANY_CARD_MODAL,
              payload: {open:true,detail:d},
            });
          }}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button btnClass='data-table-delete-btn ms-1' title="Sil" maxWidth="130px" height="35px" />
        </div> : <></>
        }
        </>
      ),
    },
  ];

  let data = [];
  dataFetchCompanyCard?.map((res) =>
    data.push({
      id: res?.id,
      year: res?.companies?.name,
      title: res?.periods?.name,
      periods: res?.periods?.name,
      displayName: res?.displayName,
      companyGroupId: res?.companies?.companyGroupId,
      score: res?.displayName === "Tüm süreç tamamlandı" && (res?.ceoTotalScore ? res?.ceoTotalScore : res?.totalScore),
     companyName:res?.companies?.companyGroup?.name
    })
  );
  var tableData = {    
    columns,
    data,
  };
  useEffect(() => {
    api.fetchDataGroup().then((res) => {
       setGroupOptions([{name: 'Tümü', id: '' ,label:"Tümü",value:"Tümü",type:"group"},...res.data.result]);
    });
    api.fetchDataTerm().then((res) => {            
      setYearOptions([{name: 'Tümü', id: '' ,label:"Tümü",value:"Tümü",type:"year"},...res.data.result]);
    });
    api.fetchProcessStatus().then((res) => {
      setProcessOptions(arraymove([...res.data],10,6));
    });
  }, []);


  const paginationFunc = (page) => {
    localStorage.setItem('pagination', page)
  }
// localStorage.setItem('pagination', page)
   useEffect(()=>{
    // if(localStorage.getItem('pagination')){
    //    setPag(localStorage.getItem('pagination'))
    // }
    setPag(localStorage.getItem('pagination'))
    paginationFunc(localStorage.getItem('pagination'))
  },[pag])

  useEffect(()=>{
    let val = JSON.parse(localStorage.getItem('userInfo'))?.CompanyCard;
    setBtnVisible(val?.includes('2'))
    if (!filterOptions) {
      companyDispatch({
        type: FILTER_OPTIONS,
        payload: {group:'',year:'',status:'',periodOption:{periodFilterStatu:1}},
      });
    }
  },[])

  function arraymove(arr, fromIndex, toIndex) {
    let arrToMove = [...arr]
    var element = arrToMove[fromIndex];
    arrToMove.splice(fromIndex, 1);
    arrToMove.splice(toIndex, 0, element);
    return arrToMove
}

  // custom select style
  const customSelectStyles = {
    control:() => ({
      backgroundColor: "hsl(0, 0%, 100%)",
      borderColor: "hsl(0, 0%, 80%)",
      borderRadius: "4px",
      borderStyle: "solid",
      borderWidth: "1px",
      width:'220px',
      display:'flex'
    })
  }


  return (
    <div>
      <div className="SelectContainer" >
      { (getUserInfo()?.RoleId !=  roles.COMPANY_OFFICIALS_ROLE_ID && getUserInfo()?.RoleId != roles.GENERAL_MANAGER_ROLE_ID && getUserInfo()?.RoleId != roles.MINISTER_ROLE_ID) &&
        <div className="SelectStyle" >
            <Select
              onChange={handleonChange}
              options={
                groupOptions &&
                groupOptions.map((o) => ({
                  name: o.name,
                  label: o.name,
                  value: o.name,
                  id: o.id,
                  type:"group"
                }))
              }
              value={filterOptions?.group}
              placeholder="Grup Seçiniz"
              name="group"
            />
          </div>
          }
        <div className="SelectStyle">
          <Select
            onChange={handleonChange}
            options={
              [
                { label:'Tüm Dönemler',value:'Tüm Dönemler',periodFilterStatu:1,type:'periodOption'},
                { label:'Dönem Başı',value:'Dönem Başı',sliceStart:0,sliceEnd:12,periodFilterStatu:2,type:'periodOption'},
                { label:'Dönem Sonu',value:'Dönem Sonu',sliceStart:12,periodFilterStatu:3,sliceEnd: processOptions.length,type:'periodOption'}
              ]
            }
            value={filterOptions?.periodOption.value && filterOptions?.periodOption}
            placeholder="Dönem Seçiniz"
            name="periodOption"
            styles={{control:() => ({
              backgroundColor: "hsl(0, 0%, 100%)",
              borderColor: "hsl(0, 0%, 80%)",
              borderRadius: "4px",
              borderStyle: "solid",
              borderWidth: "1px",
              width:'140px',
              display:'flex'
            })}}
          />
        </div>
        <div className="SelectStyle">
          <Select
            onChange={handleonChange}
            options={
              processOptions &&
              [{displayName: 'Tümü',label:'Tümü',value:"",type:"status"},...processOptions.slice(filterOptions?.periodOption?.sliceStart,filterOptions?.periodOption?.sliceEnd).map((o) => ({
                name: o.displayName,
                label: o.displayName,
                value: o.value,
                id: o.value,
                type:"status"

              }))]
            }
            isDisabled={filterOptions?.periodOption.periodFilterStatu == 1}
            value={filterOptions?.status}
            placeholder="Statü Seçiniz"
            name="status"
          />
        </div>

        <div className="SelectStyle">
          <Select
            onChange={handleonChange}
            options={
              yearOptions &&
              yearOptions.map((o) => ({
                name: o.name,
                label: o.name,
                value: o.name,
                id: o.id,
                type:"year"
              }))
            }
            value={filterOptions?.year}
            placeholder="Yıl Seçiniz"
            name="year"
          />
        </div>
        {
         btnVisible && <div onClick={()=>alertDispatch({type: CREATE_COMPANY_MODAL, payload: {open:true}})} className="SelectContainer-cardCreate"> <Button title="Şirket Kartı Oluştur" width="200px" height="38px" /></div>
        }
        </div>

      <DataTableExtensions {...tableData} >
      <DataTable
        columns={columns}
        data={data}
        noHeader
        // paginationDefaultPage={paginationInfo?.page}
        //paginationDefaultPage={localStorage.getItem('pagination') >= 1 ? localStorage.getItem('pagination') : 1}
        defaultSortField="id"
        // onChangePage={(page) => {companyDispatch({type:PAGINATION_INFO, payload:{...paginationInfo,page:page}})}}
        //onChangePage={(page) => paginationFunc(page) }
        // onChangeRowsPerPage={(currentRowsPerPage) => {companyDispatch({type:PAGINATION_INFO, payload:{...paginationInfo,rowsPerPage:currentRowsPerPage}})}}
        pagination
        highlightOnHover
        fixedHeader={true}
        noDataComponent={ loading ? <TableLoader/> : "Veri Bulunamadi" }
      />
      </DataTableExtensions>

      {detailModal.open && <CompanyCardDeleteUpdateModal />}
      {createCompanyModal.open && <CompanyCreateModal/>} 
      {deleteCompanyCardModal.open && <DeleteCompanyCardModal/>}
    </div>
  );
};

export default DataTableComp;
