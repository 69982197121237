import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const DefaultProgress = ({isLoading=false,size}) => {
    const styles={
        display:!isLoading ? 'none':'flex'
    } 
  return (
    <div style={styles} className='default-progress'>
        <CircularProgress size={size && size} />
    </div>
  )
}

export default DefaultProgress