import React from 'react'
// import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import images from '../images/images';
import {useAlertDispatch} from '../context/alert/alertContext';
import {EXCEL_IMPORT_MODAL} from '../context/alert/actions';

export const ExportExcel = ({mouseLeave,index,name,inModal, className,tag}) => {
    const alertDispatch = useAlertDispatch()
    // css özellikleri diger butonlara hitap ettigi icin ileride props gecip öyle devam ettirecegiz.
    const handleClick = () => {
        alertDispatch({type:EXCEL_IMPORT_MODAL, payload: {open: true,index:index,name:name,tag:tag}})
    }
    const options = inModal ? {
        cursor:'pointer',
        height: "38px",
        outline: "none",
        border: "1px solid lightgray",
        borderRadius: "4px",
        display:'flex',
        alignItems:'center',
        backgroundColor:"#fff",
        justifyContent:'center',
        margin:'5px'
    }
    :{
        cursor:'pointer',
        backgroundColor:'#E9EEF4',
        margin:'auto',
        height:"38px",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'5px'
    }


    return (
        //<Button variant="primary" onClick={(e) => exportToCSV(csvData,fileName)}>Export</Button>
        <div className={className} onMouseLeave={mouseLeave} style={{...options}} onClick={handleClick} >
            <img style={inModal ? {marginRight:'3px',width:"12px"}:{marginRight:'5px',width:'14px'}} src={images.excelPrint} alt=""/>
            <span style={inModal ? {color:'#44cc62',textDecoration:'underline',fontSize:'12px'}:{color:'#44cc62',textDecoration:'underline'}}>Excel Yükle</span>
        </div>
    )
}