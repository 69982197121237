import React from "react";
import Backdrop from "@mui/material/Backdrop";
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import IconButton from "./../../button/IconButton";
import images from "../../../images/images";
import { DELETE_TARGET_MODAL } from "../../../context/alert/actions";
import { RiDeleteBinLine } from "react-icons/ri";
import { useLoader } from "../../../hooks/useLoader";
import {
  useCompanyDispatch,
  useCompanyState,
} from "../../../context/company/companyContext";
import * as api from "../../../api/index";

const TargetDeleteModal = (props) => {
  const { deleteTargetModal } = useAlertState();
  const alertDispatch = useAlertDispatch();
  const setLoader = useLoader();
  const companyDispatch = useCompanyDispatch();

  const closeModal = () => {
    alertDispatch({ type: DELETE_TARGET_MODAL, payload: false });
  };

  const deleteCompanyDetailFunc = () => {
    debugger
    setLoader(true);
 
      const detailData = api.detailDeleteDataCardCompanyDetail(props.id);
      companyDispatch({
        type: "COMPANYCARDDELETEDATADETAIL",
        payload: props.id,
      });
    

    setLoader(false);
    closeModal();
  };

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(3px)",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={deleteTargetModal.open}
      >
        <div className="modalDeleteTarget">
          <div className="modalDeleteTarget-box">
            <div className="modalDeleteTarget-box-close">
              <div className="modalDeleteTarget-box-close-h1">Silme</div>
            </div>
            <h3
              className="modalDeleteTarget-box-kpi-sub"
              style={{ textAlign: "center" }}
            >
              Seçtiğiniz kayıt silinecektir, onaylıyor musunuz?
            </h3>
          </div>

          <div className="modalDeleteTarget-box-btn">
            <IconButton
              imgRight="OK"
              title="Sil"
              icon={
                <RiDeleteBinLine
                  style={{ width: "18px" }}
                  color="#fff"
                  fill="#fff"
                />
              }
              buttonClick={deleteCompanyDetailFunc}
              background="#F9554E"
              maxWidth="130px"
              color="white"
              borderColor="#F9554E"
            />
          </div>
          <div className="modalDeleteTarget-closeBtn" onClick={closeModal}>
            <img src={images.close} alt="" />
          </div>
        </div>
      </Backdrop>
    </>
  );
};

export default TargetDeleteModal;
