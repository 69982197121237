import React from 'react'

const TextSquare = ({title,maxWidth,image}) => {
    const option = {
        backgroundColor:'#fff',
        border:'1px solid transparent',
        padding:'10px',
        maxWidth: maxWidth,  // width dısarıdan parametre olarak geldi.
        width:'100%',
        margin:'10px',
        textAlign:'center',
        fontSize:'14px',
        color:' #343248',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }
    return (
    <div style={{...option}}>
      {image && <img style={{width:'15px', marginRight:'5px'}} src={image} alt=""/>}
      {title}
    </div>
  )
}

export default TextSquare