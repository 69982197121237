import React from 'react'
import DataTableComp from '../components/companyManagementPage/DataTableComp'

const CompanyManagementPage = () => {
  return (
    <div className='company-management-page' >
        <DataTableComp/>    
    </div>
  )
}

export default CompanyManagementPage