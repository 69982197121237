import { Buffer } from "buffer"; 
export function parseToken(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const buffer = Buffer(base64,'base64')
    return buffer.toString();
    
}

export const setUserToStorage = (token) => {
    localStorage.setItem('userInfo',parseToken(token))
}

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem('userInfo'))
}