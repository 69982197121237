
 import logo from './logo.png'
 import logoNew from './logo-new.jpg'
 import searchIcon from './icons-search.png'
 import iconsBell from './icons-bell.png'
 import profile from './icons-profile.png'
 import logout from './icons-exit.png'
 import home from './icons-home.png'
 import chart from './icons-chart.png'
 import activeHome from './icons-home-blue.png'
 import activeChart from './icons-chart-blue.png'
 import footerlogo from './footerLogo.png'
 import excelPrint from './excel-print.png'
 import rightArrow from './icons-right.png'
 import close from './icons-close.png'
 import blueTick from './blue-icons-tick.png'
 import whiteTick from './white-icons-tick.png'
 import greenTick from './green-icons-tick.png'
 import bluePlus from './blue-icons-plus.png'
 import whiteplus from './white-icons-plus.png'
 import iconSend from './icons-send.png'
 import iconForward from './icons-forward.png'
 import iconAttach from './icons-attach.png'
 import plus from './icons-plus.png'
 import plusBlue from './icons-plus-blue.png'
 import upload from './icons-upload.png'
 import network from './network.png'
 import kocimage from './MicrosoftTeams-image.png'
 import folderIcon from './folder.png'

 export default {
     logo,
     searchIcon,
     iconsBell,
     profile,
     logout,
     home,
     chart,
     activeHome,
     activeChart,
     footerlogo,
     excelPrint,
     rightArrow,
     close,
     blueTick,
     whiteTick,
     bluePlus,
     whiteplus,
     greenTick,
     iconSend,
     iconForward,
     iconAttach,
     plus,
     plusBlue,
     upload,
     network,
     logoNew,
     kocimage,
     folderIcon
    }
