import "react-quill/dist/quill.snow.css";
import MailManagementDataTable from "../components/userManagementPage/MailManagementDataTable";
const MailManagementPage = () => {

  return (
    <div className="company-card-management-page">
      <MailManagementDataTable/>
    </div>
  );
};

export default MailManagementPage;
