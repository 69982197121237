import React,{ useState,useCallback } from 'react'
import CreatableSelect from 'react-select/creatable';


const AutocompleteSelect = ({maxWidth,disabled, readValue, options,setValue, value}) => {


    const [editingValue, setEditingValue] = useState();

  const handleChange = useCallback(
    (newValue) => {
        setValue(newValue);
    },
    [setValue],
  );

  const handleEditChange = useCallback(
    (inputValue, data) => {
      const idx = value.findIndex((v) => v.value === data.value);
      const newValue = [...value];

      if (inputValue.length === 0) {
        newValue.splice(idx, 1);
      } else {
        newValue[idx] = {
          label: inputValue,
          value: inputValue,
        };
      }

      setValue(newValue);

      setEditingValue(undefined);
    },
    [value, setValue],
  );

  const MultiValueLabel = useCallback(
    ({ data }) => {
      if (editingValue && editingValue === data.value) {
        return (
          <input
            type="text"
            defaultValue={data.value}
            onKeyDown={(ev) => {
              ev.stopPropagation();
              if (ev.key === 'Enter') {
                handleEditChange(ev.currentTarget.value, data);
              }
            }}
            onBlur={(ev) => {
              handleEditChange(ev.currentTarget.value, data);
            }}
            autoFocus
          />
        );
      } 
      return (
        <button
          onClick={() => {
            setEditingValue(data.value);
          }}>
          {data.value}
        </button>
      );
    },
    [handleEditChange, editingValue],
  );

  return (
    <div style={{minWidth:`${maxWidth}`}} className='autocomplete'>
    <CreatableSelect
      placeholder={readValue}
      value={value}
      onChange={handleChange}
      options={options}
      isDisabled={disabled ? disabled : false}
      components={{
        MultiValueLabel,
      }}
    />
    </div>
  )
}

export default AutocompleteSelect