import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import * as api from '../api/index'
import moment from 'moment'


const Login = () => {
    const location = useLocation();
    let urlKey = location.pathname.split('=')[1];
    //console.log(urlKey)
     

    useEffect(()=>{
      const sessionIdSSO = async () => {
        let SSOvalue = await api.loginSSOSessionId(urlKey)
        if(SSOvalue?.data){
          let date = new Date();
          date.setDate(date.getDate() + 1);
          //console.log("DATE", moment(date).format())

            localStorage.setItem('auth', JSON.stringify(SSOvalue?.data))
            localStorage.setItem('expireTime', JSON.stringify(moment(date).format()))
            //localStorage.setItem('expireTime', JSON.stringify("2022-07-20T01:49:29+01:00"))
            window.location.assign("/")
           //return window.location.href = "/"
          }
      }
      sessionIdSSO()
    },[])

  return (
    <div>Yönlendiriliyorsunuz...</div>
  )
}

export default Login