import React from 'react'
import images from '../../images/images'

const footer = () => {
  return (
    <div className='footer' >
        <img src={images.footerlogo} alt=""/>
    </div>
  )
}

export default footer