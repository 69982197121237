import { useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import Select from "react-select";
import { RiEditBoxLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import IconButton from "../../button/IconButton";
import * as api from "../../../api/index";
import images from "../../../images/images";
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import { MAIL_MANAGEMENT_MODAL } from "../../../context/alert/actions";
import { toast } from "react-toastify";
import { useLoader } from "../../../hooks/useLoader";
import Input from "../../input/Input";

const MailManagementModal = ({ roles,setMailsData }) => {
  const { mailManagementModal } = useAlertState();
  const alertDispatch = useAlertDispatch();
  const setLoader = useLoader();
  const [selectedCC, setSelectedCC] = useState([]);
  const [mailOptions, setMailOptions] = useState({
    mailType: "",
    cc: "",
    to: "",
    subject:""
  });
  useState(() => {
    const getData = async () => {
      try {
        const data = await api.getMailTemplateById(mailManagementModal.id);
        setMailOptions(data.data.result);
        setSelectedCC(getRolesById(data.data.result.cc.toLowerCase()));
      } catch (error) {
        console.log(error);
      }
    };
    if (mailManagementModal.id) {
      getData();
    }
  }, [mailManagementModal]);
  const handleChangeEditor = (editor) => {
    setMailOptions((prev) => ({ ...prev, message: editor }));
  };
  const handleChangeDropdown = (e) => {
    setMailOptions((prev) => ({ ...prev, [e.name]: e.value }));
  };
  const handleChnageCCDropdown = (e) => {
    setSelectedCC(e);
  };
  const handleChangeInput = (e) => {
    setMailOptions((prev) => ({ ...prev, subject: e.target.value }));
  }
  const closeModal = () => {
    alertDispatch({
      type: MAIL_MANAGEMENT_MODAL,
      payload: { open: false },
    });
  };
  const getRoleById = (to) => {
    const role = roles?.find((r) => r.id == to.toLowerCase());
    return { label: role?.name, value: role?.id, name: "to" };
  };
  const getRolesById = (roleId) => {
    const splittedId = roleId.split(',')
    let rolesMap = []
    for (let index = 0; index < splittedId.length; index++) {
      const element = splittedId[index];
      const role = roles?.find(r => r.id == element)
      rolesMap.push(role)
    }
    return rolesMap?.map((el) => ({ value: el.id, label: el.name, name: "ccs" }));
  };
  const handleSubmit = async () => {
    setLoader(true);
    try {
      const data = {
        ...mailOptions,
        cc: selectedCC.map((cc) => cc.value)?.join(","),
      };
      const res = await api.updateMailTemplate(data).then(res => res);
        if (res.data.result) {
          setMailsData(prev => [...prev.map(m => {
            if (m.id == res.data.result.id) {
              return {...res.data.result}
            }else {
              return m
            }
          })])
          toast.success("Mail Şablonu Güncelleme İşlemi Başarılı");
        }
        setLoader(false);
        closeModal();
    } catch (error) {
      console.log(error)
      setLoader(false);
    }
  };
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(1px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={mailManagementModal.open}
    >
      <div className="modalCreateTarget">
        <div className="mail-card">
          <div className="mail-card--content">
            <div onClick={closeModal} className="mail-card--content--close-btn">
              <img src={images.close} alt="" />
            </div>
            <div className="mail-card--content--select-area">
              <div className="flex-1">
              <label className="sirket-kartlari--inputbox--label">
              TO
            </label>
              <Select
                value={getRoleById(mailOptions.to)}
                styles={{
                  container:() => ({
                    position:'relative',
                    width:'100%'
                  })
                }}
                options={
                  roles &&
                  roles.map((role) => ({
                    label: role.name,
                    value: role.id,
                    name: "to",
                  }))
                }
                onChange={handleChangeDropdown}
                placeholder="To Seçiniz"
              />
              </div>
             <div className="flex-1">
             <label className="sirket-kartlari--inputbox--label">
              CC
            </label>
              <Select
                styles={{
                  container:() => ({
                    position:'relative',
                    width:'100%'
                  })
                }}
                isMulti
                value={selectedCC}
                options={
                  roles &&
                  roles.map((role) => ({
                    label: role.name,
                    value: role.id,
                    name: "cc",
                  }))
                }
                onChange={handleChnageCCDropdown}
                placeholder="CC Seçiniz"
              />
             </div>
            </div>
            <div className="mail-card--content--select-area">
            <Input handleChange={handleChangeInput} value={mailOptions.subject} label='Konu' placeholder='Konuyu Giriniz'/>
            </div>
            <div className="mail-card--content--select-area h-400 flex-column">
              <label className="sirket-kartlari--inputbox--label">
                İçerik
              </label>
              <ReactQuill
                theme="snow"
                value={mailOptions.message}
                onChange={handleChangeEditor}
                modules={{
                  toolbar: {
                    container: [
                      [
                        { header: "1" },
                        { header: "2" },
                        { header: [3, 4, 5, 6] },
                        { font: [] },
                      ],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image"],
                      ["clean"],
                      ["code-block"],
                    ],
                  },
                }}
              />
            </div>
            <div className="mail-card--content--button">
              <IconButton
                icon={
                  <RiEditBoxLine
                    style={{ width: "20px", border: "none" }}
                    color="#fff"
                    fill="#fff"
                  />
                }
                imgRight="OK"
                title="Güncelle"
                maxWidth="130px"
                background="#1392E5"
                color="white"
                borderColor="#1392E5"
                buttonClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default MailManagementModal;
