
import React from 'react'
import {initialState, companyReducer} from './companyReducer';
 
const CompanyStateContext = React.createContext()
const CompanyDispatchContext = React.createContext()

const CompanyProvider = ({children}) => {
    const [state,dispatch] = React.useReducer(companyReducer,initialState)

    return (
        <CompanyStateContext.Provider value={state}>
              <CompanyDispatchContext.Provider value={dispatch}>
                  {children}
              </CompanyDispatchContext.Provider>
        </CompanyStateContext.Provider>
    )
}

const useCompanyState = () => {
    const context = React.useContext(CompanyStateContext)
    if(context === undefined) {
        throw new Error('useCompanyState must be under CompanyProvider!')
    }
    return context
}

const useCompanyDispatch = () => {
    const context = React.useContext(CompanyDispatchContext)
    if(context === undefined) {
        throw new Error('useCompanyDispatch must be under CompanyProvider!')
    }
    return context
}

export {CompanyProvider, useCompanyDispatch, useCompanyState}