import React from 'react'

const ContainerBox = ({children}) => {
    const option = {
        display:'flex',
        height:'83.3vh',
        //width:'100%'
        //overflowY:'scroll'
        //height:'100%'  //degerler update edilecek
    }
  return (
    <div style={{...option}}>{children}</div>
  )
}

export default ContainerBox