export const initialState = {
     // company Reducer
     fetchData : [],
     allUserData:[],

     dataFetchCompanyCard:[],
     dataFetchGroup:[],
     dataFetchTerm:[],
     dataFetchUnit:[],
     dataFetchAim:[],
     dataFetch:[],
     dataFetchCompanyCardBonusDetail:[],
     dataFetchCompanyCardDetail:[],
     paginationINfo:{},
     takeMessage:[],
     //alert Reducer
     cfoApprovalModal:{open:false},
     userManagementModal:{},
     userDetailManagementModal:{},
     roleManagementModal:{},
     roleDetailManagementModal:{},
     permissionTableModal:{},
     excelImportModal:{},
     detailModal:{},
     createTargetModal:{},
     createTargetBonusModal:{},
     deleteTargetModal:{},
     deleteTargetBonusModal:{},
     createModal:false,
     createCompanyModal:{},
     errorStatus:null,
     notification: [],
     deletePost:{id:null}, // modaldan silme işlemi yapılınca bu olayın anlık FE kısmında da olmasını saglamak amaclı eklenildi buraya.
     filterOptions:{group:'',year:'',status:'',periodOption:{periodFilterStatu:1}},
     deleteCompanyCardModal:{open:false,detail:''},
     mailManagementModal:{open:false,detail:''},
}