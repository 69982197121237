import Backdrop from "@mui/material/Backdrop";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import * as api from "../../../api/index";
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import {useCompanyDispatch, useCompanyState} from "../../../context/company/companyContext";
import images from "../../../images/images";
import IconButton from "../../button/IconButton";
import { ALL_USER_DATA } from "../../../context/company/actions";
import {USER_DETAIL_MANAGEMENT_MODAL} from "../../../context/alert/actions";
import {RiDeleteBinLine, RiEditBoxLine} from "react-icons/ri";
import { useLoader } from "../../../hooks/useLoader";
import moment from "moment";
const UpdateUserModal = () => {
  const { userDetailManagementModal } = useAlertState();
  const [user,setUser] = useState(userDetailManagementModal.user)
  const [roleOptions, setRoleOptions] = useState([]);
  const alertDispatch = useAlertDispatch();
  const {allUsersData} = useCompanyState()
  const companyDispatch = useCompanyDispatch()
  const setLoader = useLoader()
  useEffect(() => {
    api.getAllRoles().then((res) => {
      setRoleOptions(res.data.result);
    });
  }, []);
  const handleSelectChange = (values) => {
    setUser({ ...user, ...values });
  };
  const handleSubmit = () => {
    const jsonData = {
      id:user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
      ePosta: user.ePosta,
      company: user.company,
      satatus: user.status,
      roles: user.roles.map(e => e),
    }
    setLoader(true);
    api
      .updateUser(jsonData)
      .then((res) => {
        if (res.status == 200) {
          companyDispatch({type:ALL_USER_DATA,payload:[...allUsersData.map(u => {
            if (u.id == user.id) {
              return res.data?.result
            }else {
              return u
            }
          })]})
          toast.success("Kullanıcı Güncelleme İşlemi Başarılı");
          setLoader(false);
          closeModal();
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  const handleDelete = () => {
    setLoader(true)
    api.deleteUser(user.id).then(res=>{
        if (res.status == 200) {
            companyDispatch({type:ALL_USER_DATA,payload:[...allUsersData.filter(u => u.id != user.id)]})
            toast.success('Kullanıcı Silme İşlemi Başarılı')
          }
          closeModal()
          setLoader(false)
    }).catch(error=> {
        setLoader(false)
    })
  }
  const closeModal = () => {
    alertDispatch({
      type: USER_DETAIL_MANAGEMENT_MODAL,
      payload: { open: false , isChaneged:true},
    });
    setUser();
    setRoleOptions([]);
  };
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(1px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={userDetailManagementModal.open}
    >
      <div className="modalCreateTarget">
        {user ? (
          <div className="user-modal">
            <div className="user-modal--container">
              <div
                className="user-modal--container--close-btn"
                onClick={closeModal}
              >
                <img src={images.close} alt="" />
              </div>
              <div className="react-dropdown-div">
              <Select
                defaultValue={{...user,label:`${user.firstName} ${user.lastName} (${user.companyName ? user.companyName:'Şirket Bulunamadı'})`}}
                isDisabled
                styles={{ minWidth: "200px" }}
                placeholder="Kullanıcı Seçiniz"
                name="user"
              />
              </div>
              <div className="react-dropdown-div">
                <Select
                  defaultValue={{label:`Son Giriş Tarihi:${user.lastLoginDateAt == '0001-01-01T00:00:00' ? '-' : moment(user.lastLoginDateAt).format('LLLL')}`}}
                  isDisabled
                  styles={{ minWidth: "200px" }}
                  name="lastLoginDateAt"
                />
              </div>
              <div className="react-dropdown-div">
              <Select
                onChange={handleSelectChange}
                defaultValue={{roles:[{roleId:user.roles[0].roleId}],label:user.roles[0].role?.name,value:user.roles[0].role?.name,status:user.roles[0].role?.status}}
                isDisabled={!user?.id}
                styles={{ minWidth: "200px" }}
                options={
                  roleOptions &&
                  roleOptions.map((o) => ({
                    roles: [{roleId:o.id}],
                    label: o.name,
                    value: o.name,
                    status: o.status,
                  }))
                }
                placeholder="Rol Seçiniz"
                name="role"
              />
              </div>
             <div className="user-modal--container--buttons">
             <IconButton
                icon ={ <RiEditBoxLine style={{width:"20px",border:"none"}} color="#fff" fill="#fff" /> } 
                disabled={!user?.roles}
                buttonClick={handleSubmit}
                imgRight="OK"
                title="Güncelle"
                maxWidth="130px"
                background="#1392E5"
                color="white"
                borderColor="#1392E5"
              />
             <IconButton
                disabled={!user?.roles}
                buttonClick={handleDelete}
                title="Sil"
                imgRight="OK"
                maxWidth="130px"
                background="#f9423a"
                color="white"
                borderColor="#f9423a"
                icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> }
              />
             </div>
            </div>
          </div>
        ) : (
          <div>Kullanıcı Bulunamadı</div>
        )}
      </div>
    </Backdrop>
  );
};

export default UpdateUserModal;
