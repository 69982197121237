import axios from "axios"
import {useCallback} from "react";
import {toast} from "react-toastify";
import {ERROR_STATUS} from "../context/alert/actions";
import {useAlertDispatch} from "../context/alert/alertContext";

export function useRequest() {
    const alertDispatch = useAlertDispatch()
     axios.interceptors.response.use(response => {
         return response;
       }, error => {
        if (error.response.status === 401) {
           alertDispatch({ type: ERROR_STATUS, payload: {navigate:'/unauthorized',status:error.response.status}});
        }
        if (error.response.status === 404) {
             console.log('error',error)
             alertDispatch({ type: ERROR_STATUS, payload: {navigate:'/not-found',status:error.response.status}});
         }
         if (error.response.status === 500) {
             console.log('error',error)
             alertDispatch({ type: ERROR_STATUS, payload: {navigate:'/server-error',status:error.response.status}});
         }
        else{
            if (typeof error.response.data.errors == 'string') {
                toast.error(error.response.data.errors,{
                    autoClose: false 
                  })
                  return error
            }
           else {
            Object.values(error.response.data.errors).forEach(val =>{
                toast.error(val[0])
            })
            return error
        }
        }
       });
    const isDevelopment = process.env.NODE_ENV !== 'production'
    const apiUrl = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PILOT_URL
    axios.defaults.baseURL = apiUrl
    let token = JSON.parse(localStorage.getItem('auth'));

    const request = useCallback(async (url,options) => {
        options.headers = {
            'Authorization': `Bearer ${token}`,
             'Content-Type': 'application/json',
             ...options.headers,
           };
            try {
                const response =  await axios(url,options)
                return response
            } catch (error) {
                return error
            }
    },[])
    return request
}