import React, {useEffect,useState,useRef} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import { useCompanyDispatch } from '../../../context/company/companyContext';
import IconButton from './../../button/IconButton';
import images from '../../../images/images';
import { UNIT_UPDATE_DATA } from '../../../context/company/actions';
import {DELETE_POST, DETAIL_MODAL} from '../../../context/alert/actions';
import {RiDeleteBinLine , RiEditBoxLine} from 'react-icons/ri';
import { useLoader } from '../../../hooks/useLoader';
import {useRequest} from '../../../hooks/useRequest';

const UnitDeleteUpdateModal = ({title,whichModal}) => {
    const {detailModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const companyDispatch = useCompanyDispatch()
    const setLoader = useLoader()
    const request = useRequest()
    const [detail, setDetail] = useState(null)
    const [errors,setErrors] = useState({name:"",description:""})
    const [newRow, setNewRow] = useState({
        name:'', description:'',hyperionField:'-', dialogField:''
      })

    useEffect(()=> {
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetDataUnit(detailModal.id)
                setDetail(data?.data?.result)
                setNewRow(data?.data?.result)
             }
         } catch (error) {
             console.log(error);
         }   
     }
     getDetail()
    },[detailModal])
   

    const deleteFunc = async() => {
        setLoader(true)
     try {
           await api.detailDeleteDataUnit(detailModal.id)
           alertDispatch({type: DETAIL_MODAL, payload: false})
           alertDispatch({type:DELETE_POST, payload: {id:detailModal.id}})
           toast.success('Silme İşlemi Başarılı')
            setLoader(false)
     } catch (error) {
        setLoader(false)   
     }
    }

    const updateFunc = async() => {
        if(validateForm()){
            setLoader(true)
            await request('/Unit/Update',{method:'POST',data:detail}).then(res => {
                if (res) {
                    companyDispatch({type: UNIT_UPDATE_DATA, payload: res.data.result})
                    alertDispatch({type: DETAIL_MODAL, payload: false})
                    toast.success('Güncelleme İşlemi Başarılı')
                }
            }).finally(()=> {
                setLoader(false)
            })
            
        }
    }
   

    const closeModal = () => {
      alertDispatch({type: DETAIL_MODAL, payload: false})
    }
    const handleNewRowChange = (e) => {
        setNewRow({...newRow, [e.target.name]:e.target.value})
        setDetail({...detail , [e.target.name]: e.target.value})
        setErrors(prev => ({...prev,[e.target.name]:""}))
      }
      const validateForm = () => {
        let isValid = true
        if (!newRow.name) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.factor) {
            setErrors(prev=> ({...prev,factor:"Bu alan zorunludur"}))
            isValid =false
        }
        // if (!newRow.hyperionField) {
        //     setErrors(prev=> ({...prev,hyperionField:"Bu alan zorunludur"}))
        //     isValid =false
        // }
        return isValid
    }
  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={detailModal.open}  
        >
            <div className='modalCreateTarget'>
            <div className='company-modal'>
                <div className="company-modal--container">
                {/* <div className="company-modal--container--head">
                    <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Hyperion Alanı *
                            </div>
                            <input type="text" placeholder='Hyperion Alanı girin' className='company-modal--container--input-area--input' defaultValue={detail?.hyperionField} name="hyperionField" onChange={handleNewRowChange} value={newRow.hyperionField} />
                            {errors.hyperionField && <span className='company-modal--container--error'>{errors.hyperionField}</span>}
                        </div>
                    </div> */}
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Birim Adı *
                            </div>
                            <input type="text" placeholder='Birim adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.name} name="name" onChange={handleNewRowChange} value={newRow.name} />
                            {errors.name && <span className='company-modal--container--error'>{errors.name}</span>}
                        </div>

                    </div>
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Birim Çarpanı *
                            </div>
                            <input type="number" placeholder='Birim adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.factor} name="factor" onChange={handleNewRowChange} value={newRow.factor} />
                            {errors.factor && <span className='company-modal--container--error'>{errors.factor}</span>}
                        </div>
                    </div>
                    <div className="company-modal--container--body">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                               Birim Açıklaması
                            </div>
                            <input type="text" placeholder='Şirket adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.description} name="description" onChange={handleNewRowChange} value={newRow.description} />
                            {errors.description && <span className='company-modal--container--error'>{errors.description}</span>}
                        </div>
                    </div>
                      
                    </div>
                    <div className="company-modal--container--body">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                              Diyalog Alanı
                            </div>
                            <input type="text" placeholder='Diyalog Alanı girin' className='company-modal--container--input-area--input' defaultValue={detail?.dialogField} name="dialogField" onChange={handleNewRowChange} value={newRow.dialogField} />
                            {errors.description && <span className='company-modal--container--error'>{errors.description}</span>}
                        </div>
                    </div>
                      
                    </div>
                    <div className='company-modal--container--footer'>
                            <IconButton icon ={ <RiEditBoxLine style={{width:"20px"}} color="#fff" fill="#fff" /> }  buttonClick={updateFunc} imgRight="OK" title="Güncelle"  maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                            <IconButton icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> } buttonClick={deleteFunc} imgRight="OK" title="Sil" maxWidth="130px" background="#f9423a" color='white' borderColor='#f9423a'/>
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default UnitDeleteUpdateModal