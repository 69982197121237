import React, {useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import { useCompanyDispatch } from '../../../context/company/companyContext';
import images from '../../../images/images';
import IconButton from './../../button/IconButton';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {TERM_UPDATE_DATA} from '../../../context/company/actions';
import {DELETE_POST, DETAIL_MODAL} from '../../../context/alert/actions';
import {RiDeleteBinLine, RiEditBoxLine} from 'react-icons/ri';
import {useLoader} from '../../../hooks/useLoader';
import {useRequest} from '../../../hooks/useRequest';
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr)

const TermDeleteUpdateModal = () => {
    const {detailModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const companyDispatch = useCompanyDispatch()
    const setLoader = useLoader()
    const request = useRequest()
    const [detail, setDetail] = useState(null)
    const [errors,setErrors] = useState({ name:'', startDateAt:'', endDateAt: ''})
    const [newRow, setNewRow] = useState({
        name:'', startDateAt:'', endDateAt: '' ,hyperionField:'', dialogField:''
      })


    useEffect(()=> {
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetDataTerm(detailModal.id)
                setDetail(data?.data?.result)
                setNewRow(data?.data?.result)
             }
         } catch (error) {
             console.log(error);
         }   
     }
     getDetail()
    },[detailModal])
   

    const deleteFunc = async() => {
        setLoader(true)
     try {
           await request('/Period/Delete',{method:'DELETE',params:detailModal.id}).then(res => {
            if (res.response.status == 200) {
                alertDispatch({type: DETAIL_MODAL, payload: false})
                alertDispatch({type: DELETE_POST, payload: {id:detailModal.id}})
                setLoader(false)
            }
            else {
                setLoader(false)
                alertDispatch({type: DETAIL_MODAL, payload: false})
            }
           })
           
     } catch (error) {
        setLoader(false)
     }
    }

    const updateFunc = async() => {
        setLoader(true)
       try {
        await request('/Period/Update',{method:'POST',data:detail}).then(res => {
            if (res) {
                companyDispatch({type: TERM_UPDATE_DATA, payload: res.data.result})
                alertDispatch({type: DETAIL_MODAL, payload: false})
                toast.success('Update İşlemi Başarılı')
                setLoader(false)
            }
        })
       } catch (error) {
        setLoader(false)
       }
    }
   

    const closeModal = () => {
      alertDispatch({type: DETAIL_MODAL, payload: false})
    }
   
    const handleNewRowChange = (e) => {
        setNewRow({...newRow, [e.target.name]:e.target.value})
        setDetail({...detail , [e.target.name]: e.target.value})
        setErrors(prev => ({...prev,[e.target.name]:""}))
      }
    const validateForm = () => {
        let isValid = true
        if (!newRow.name) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.startDateAt) {
            setErrors(prev => ({...prev,type:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        if (!newRow.endDateAt) {
            setErrors(prev => ({...prev,isPossibleCompany:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        return isValid
    }

    
    const dateOnChange = (date,cal) => {
        var newDate = moment(date).format("L")
        switch (cal) {
            case "start":
                 setNewRow({...newRow, startDateAt:newDate})
                 setDetail({...detail, startDateAt:newDate})
                break;
            case "end":
                setNewRow({...newRow, endDateAt:newDate})
                setDetail({...detail, endDateAt:newDate})
                 break;
            default:
                break;
        }
    }

  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={detailModal.open}  
        >
          
        <div className='modalCreateTarget'>
            <div className='company-modal'>
                <div className="company-modal--container">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Dönem Adı *
                            </div>
                            <input type="text" placeholder='Hedef adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.name} name="name" onChange={handleNewRowChange} value={newRow.name} />
                            {errors.name && <span className='company-modal--container--error'>{errors.name}</span>}
                        </div>
                    </div>

                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Diyalog Alanı
                            </div>
                            <input type="text" placeholder='Diyalog alanı girin' className='company-modal--container--input-area--input' defaultValue={detail?.dialogField} name="dialogField" onChange={handleNewRowChange} value={newRow.dialogField} />
                            {/* {errors.name && <span className='company-modal--container--error'>{errors.name}</span>} */}
                        </div>
                    </div>

                    <div className="company-modal--container--body">
                       
                        <div className="company-modal--container--body--half-width">
                            
                        <div className='company-modal--container--input-area'>
                                    <div className="company-modal--container--input-area--label">
                                    Dönem Başlangıç Tarihi *
                                    </div>
                                    <div className='react-dropdown-div'>
                                        <DatePicker
                                            locale="tr"
                                            //selected={moment(newRow?.startDate).toDate}
                                            onChange={date => dateOnChange(date,"start")}
                                            name="startDateAt"
                                            defaultValue={newRow?.startDateAt.split('T')[0]}
                                            placeholderText={newRow?.startDateAt ? newRow?.startDateAt.split('T')[0] : "yyyy-mm-dd"}
                                            filterDate={date => date.getDay() !== 6 && date.getDay() !== 0} // weekends cancel
                                            showYearDropdown // year show and scrolldown alos
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            dateFormat="yyyy-MM-dd"
                                            dateFormatCalendar='yyyy-MM-dd'
                                        />
                                    </div>
                                    {errors.startDateAt && <span className='company-modal--container--error'>{errors.startDateAt}</span>}
                                </div>


                            <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                Dönem Bitiş Tarihi *
                                </div>
                                <div className='react-dropdown-div'>
                                        <DatePicker
                                            locale="tr"
                                            // selected={newRow?.endDate}
                                            onChange={date => dateOnChange(date,"end")}
                                            name="endDateAt"
                                            defaultValue={newRow?.endDateAt.split('T')[0]}
                                            placeholderText={newRow?.endDateAt ? newRow?.endDateAt.split('T')[0]  : "yyyy-mm-dd"}
                                            filterDate={date => date.getDay() !== 6 && date.getDay() !== 0} // weekends cancel
                                            showYearDropdown // year show and scrolldown alos
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            dateFormat="yyyy-MM-dd"
                                            dateFormatCalendar='yyyy-MM-dd'
                                        />
                                </div>
                                {errors.endDateAt && <span className='company-modal--container--error'>{errors.endDateAt}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='company-modal--container--footer'>
                    <IconButton icon ={ <RiEditBoxLine style={{width:"20px"}} color="#fff" fill="#fff" /> }  buttonClick={updateFunc} imgRight="OK" title="Güncelle" maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                    <IconButton icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> } buttonClick={deleteFunc} imgRight="OK" title="Sil" maxWidth="130px" background="#f9423a" color='white' borderColor='#f9423a'/>
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default TermDeleteUpdateModal