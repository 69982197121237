import React from 'react'
import { CircularProgress } from '@mui/material';

const TableLoader = () => {
  return (
    <div className='table-loading'>
       <div>Veriler Yükleniyor...</div>
       <CircularProgress size={30} />
    </div>
  )
}

export default TableLoader

