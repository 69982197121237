const roles = {
    GENERAL_MANAGER_ROLE_ID:"3555739d-384e-4ab7-9e37-836f9a9133c2",
    COMPANY_OFFICIALS_ROLE_ID:"2002950f-a2c2-4a44-8e3b-c285d8c020eb",
    CFO_ROLE_ID:"7af6adda-909b-4244-9bf1-d6ee4b61527b",
    MINISTER_ROLE_ID:"91dd0358-5527-4301-976e-245aa78c8cf1",
    STRATEGY_TEAM_ROLE_ID:"a8289f51-9f04-44ef-9191-44366342f7c1",
    STRATEGY_TEAM_DIRECTOR_ROLE_ID:"0c618b1c-34b3-43fd-94b1-95469950dc99",
    VICE_PRESIDENT_ROLE_ID:'46c99da3-77fe-4e0e-bc18-a184cdabaa85'
}

export default roles