import React, { useState } from "react";
import images from "../../../images/images";
import moment from "moment";
import { MdCheck, MdDeleteOutline, MdModeEdit, MdOutlineAdd, MdOutlineClose } from "react-icons/md";
import * as api from "../../../api/index";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { useEffect } from "react";
import useToastify from "../../../hooks/useToastify";
import Select from "react-select";

const MessageCard = ({
  message,
  setReply,
  messageContent,
  setMessageContent,
  companyDetail,
  defaultCategoryOptions
}) => {
  const { errorToast } = useToastify()
  const [hover, setHover] = useState(false);
  const [sendDropdown, setSendDropdown] = useState(false);
  const [uploadedMesage, setUploadedMessage] = useState(message?.message)
  const [files, setFiles] = useState(message?.opinionDocument);
  const [editMode, setEditMode] = useState(false);
  const [selectedTags, setSelectedTags] = useState([])
  useEffect(() => {
    setSelectedTags([{label:'Geçmiş Kayıtlar',value:'Geçmiş Kayıtlar',id:'aim'},...defaultCategoryOptions].filter(o => message.tag.toLowerCase().includes(o.label.toLowerCase()) && {...o}))
  },[defaultCategoryOptions])
  const replyMessage = () => {
    setReply(message.id);
  };
  const isDeletable = companyDetail?.permission?.isOpinionsDelete?.find(
    (o) => o.opinionId == message?.id
  )?.isOpinionDelete;
  const deleteMessage = async () => {
      await api.opinionDelete(message.id).then(res => {
        setMessageContent(messageContent.filter((msg) => msg.id != message.id));
        toast.success("Silme İşlemi Başarılı");
      }).catch(err => {
        console.log(err)
        errorToast('Mesaj Silinemiyor')
      })
      
  };
  const filesUpload = async (e) => {
    const formData = new FormData();
    try {
      let filesList = e.target.files;
      let fileArray = [];
      for (let i = 0; i < filesList.length; i++) {
        fileArray.push(filesList[i]);
        formData.append("document", filesList[i]);
      }
      //setFilesUploaded(fileArray)
      var data = await api.opinionCreateDocuments(formData);
      if (data?.data[0]) {
        setFiles([...files, ...data.data.map(d => ({id:d.id,url:d.url}))]);
      }
    } catch (error) {
      errorToast("Dosya yükenirken bir hata oluştu, Lütfen dosyalarınızın 40mb'dan küçük ve doğru dosya tiplerinden oluştuğuna emin olunuz.")

      console.log(error);
    }
  };
  const updateMessage = async() => {
    try {
    let updateMessage = await api.opinionUpdate(JSON.stringify({...message,id:message.id, answerMessageId:message.answerMessageId,companyCardId:message.companyCardId,message:uploadedMesage,opinionDocumentId:files.map(f => f.id),tag:selectedTags.length>0 ? selectedTags?.map(e => `@${e.value}`).join(''):'@Genel'}))
    if (updateMessage.data.result) {
      setMessageContent(prev => prev.map(m => {
        if (m.id == message.id) {
          return updateMessage.data.result
        }else {
          return m
        }
      }))
      toast.success('Mesajınız Güncellendi')
    }
    setEditMode(false)
    } catch (error) {
      console.log(error)
      errorToast('Bir Hata Oluştu')
    }
    
  }
  const sendFileDropdown = () => {
    setSendDropdown(!sendDropdown);
  };
  const deleteAttachFile = async (id) => {
    await api.opinionFileDelete(id);
    setFiles(files?.filter((op) => op.id != id));
  };
  return (
    <div
      className="messageCard"
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
    >
      <div className="messageCard-header">
        <div className="messageCard-header-left">
          {!editMode ? !message?.tag ? (
            <span className="messageCard-header-btn">Genel</span>
          ) : (
            message?.tag
              ?.split("@")
              .filter(Boolean)
              .map((tag) => (
                <span className="messageCard-header-btn">{tag}</span>
              ))
          ) :(
              <Select placeholder={'Genel'}  isMulti value={[...selectedTags]} onChange={(e) => setSelectedTags(e)} options={defaultCategoryOptions} />
          )
        }
        </div>
        <div className="messageCard-header-right">
          {hover && (
            <div>
              {isDeletable && !editMode && (
                <span onClick={() => setEditMode(true)}>
                  <MdModeEdit size={17} />
                </span>
              )}
              {isDeletable && editMode && (
                <span onClick={updateMessage}>
                  <MdCheck size={17} />
                </span>
              )}
              {isDeletable && editMode && (
                <span onClick={() => setEditMode(false)}>
                  <MdOutlineClose size={17} />
                </span>
              )}
              &nbsp;&nbsp;
              {isDeletable && (
                <span onClick={deleteMessage}>
                  <MdDeleteOutline size={17} />
                </span>
              )}
              &nbsp;&nbsp;
            </div>
          )}
          <span onClick={replyMessage} className="messageCard-header-answer">
            Yanıtla &nbsp; <img src={images.iconForward} alt="" />
          </span>
        </div>
      </div>

      <div className="messageCard-body" >
        {message?.upOpinion && (
          <div className="messageCard-reply-message">
            <div className="messageCard-reply-message-top">
              <span>
                {" "}
                {`${message?.upOpinion?.sendMessageUser?.firstName} ${message?.upOpinion?.sendMessageUser?.lastName}`}{" "}
              </span>
              <span>
                {" "}
                {moment(message?.upOpinion?.sendMesssageDateAt).format(
                  "LL"
                )}{" "}
              </span>
            </div>
            <div className="messageCard-reply-message-bottom">
              <strong>
                {!message?.upOpinion?.tag ? (
                  <>Genel</>
                ) : (
                  <>{message?.upOpinion?.tag}</>
                )}
              </strong>{" "}
              <span dangerouslySetInnerHTML={ {__html: message.upOpinion.message}}></span>
            </div>
          </div>
        )}
        {
          editMode && <textarea onChange={(e) => setUploadedMessage(e.target.value) } value={uploadedMesage} style={{width:'100%'}}></textarea>
        }
        <span dangerouslySetInnerHTML={ {__html:!editMode ? message.message : ''}}></span>
      </div>

      <div className="messageCard-date">
        {moment(message?.sendMesssageDateAt).format("LL")}
      </div>

      <div className="messageCard-footer">
        <span className="messageCard-footer-name">
          {message?.sendMessageUser?.firstName}{" "}
          {message.sendMessageUser?.lastName}
        </span>
        <span>
          {isDeletable && editMode && (
            <div>
              <label
                style={{ cursor: "pointer", margin: "auto 5px" }}
                htmlFor={`addFileInput${message.id}`}
              >
                <MdOutlineAdd size={17} />
              </label>
              <input
                onChange={filesUpload}
                accept=".png, .jpg, .jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                id={`addFileInput${message.id}`}
                type="file"
                multiple={true}
                style={{ display: "none" }}
              />
            </div>
          )}
          <span onClick={sendFileDropdown}>
            <img src={images.iconAttach} alt="" />
            &nbsp;Ekli Dosya&nbsp;( {files?.length} )
          </span>
        </span>
      </div>

      {sendDropdown && (
        <div className="messageCard-click-iconAttach">
          {files &&
            files?.map((doc) => (
              <div
                className="messageCard-click-iconAttach-preview"
                key={doc.id}
              >
                <a href={doc.url} target="_blank" rel="noopener noreferrer">
                  {doc.url}
                </a>
                {
                  editMode && <AiOutlineDelete
                  onClick={() => deleteAttachFile(doc.id)}
                  size={17}
                />
                }
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MessageCard;
