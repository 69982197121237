import React, { useEffect, useRef, useState } from 'react'
import SidebarButton from './SidebarButton'
import images from '../../images/images'
import { useNavigate } from 'react-router-dom';
import * as api from '../../api/index'

const Sidebar = () => {
    const token = JSON.parse(localStorage.getItem('auth'));
    const [homeBool, setHomeBool] = useState(false)
    const [chartBool, setChartBool] = useState(false)
    const [resourcesBool, setResourcesBool] = useState(false)
    const [managementBool,setManagementBool] = useState(false)
    const [side, setSide] = useState([])
    const btnRef = useRef(null)
    const navigate = useNavigate()
    const openHome = [
        {icon:images.iconsBell, name:'Birim Yönetimi', redirect:'unit'},
        {icon:images.iconsBell, name:'Dönem Yönetimi', redirect:'term'},
        {icon:images.iconsBell, name:'Hedef Yönetimi', redirect:'aim'},
        {icon:images.iconsBell, name:'Şirket Yönetimi', redirect:'company'},
        {icon:images.iconsBell, name:'Şirket Grup Yönetimi', redirect:'group'},
    ]

    const openChart = [
        {icon:images.iconsBell, name:'Şirket Kartı Oluştur', redirect:'/create-company-card'},
        {icon:images.iconsBell, name:'Şirket Kartlarını Listele', redirect:'/company-card'}
    ]
    const openManagement = [
      {icon:images.iconsBell, name:'Kullanıcı Yönetimi', redirect:'management/user'},
      {icon:images.iconsBell, name:'Rol Yönetimi', redirect:'management/role'},
      {icon:images.iconsBell, name:'Mail Tarihleri Yönetimi', redirect:'management/mail'},
    ]
      useEffect(() => {

        function handleClickOutside(event) {
          if (btnRef.current && !btnRef.current.contains(event.target)) {
            if ((!openHome.find(e => e.redirect == event.target.id)) && (!openChart.find(e => e.redirect == event.target.id)) && (!openManagement.find(e => e.redirect == event.target.id))) {
              setChartBool(false)
              setHomeBool(false)
              setManagementBool(false)
              setResourcesBool(false)
            }
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [btnRef]);

    const mainClick = () => {
      navigate('/')
    }
    const resourcesClick = () => {
      setResourcesBool(!resourcesBool)
    }
    const companyCardClick = () => {
      navigate('/company-card')
    }

    const homeClick = () => {
       setHomeBool(!homeBool)
    }
    const chartClick = () => {
       setChartBool(!chartBool)
    }
    const managementClick = () => {
      setManagementBool(!managementBool)
    }

    useEffect(()=>{
      const getSidebar = async () => {
        const data = await api.sidebarFetch()
        setSide(data.data.result)
      } 
      if (token) {
        getSidebar()
      }
    },[])

  return (
    <div className='sidebar'>

      {
        side && side.map((sd,i) => { 
          switch (sd?.displayName) {
            case 'Anasayfa':
              return <SidebarButton   icon={images.home} text="Ana Sayfa" ref={btnRef} btnClick={mainClick} /> 
            case 'Şirket Kartları':
              return <SidebarButton  btnClick={companyCardClick} icon={images.chart} ref={btnRef} text="Şirket Kartları" /> 
            case 'Tanımlar':
              return <SidebarButton  btnClick={homeClick} icon={images.iconsBell} ref={btnRef} text="Tanımlar" open={ homeBool && !chartBool && sd.parents} /> 
            case 'Yönetim':
              return <SidebarButton  icon={images.network} text="Yönetim" ref={btnRef}   btnClick={managementClick} open={managementBool && !chartBool && sd.parents}/>  
            case 'Kaynaklar':
              return <SidebarButton icon={images.folderIcon} text="Kaynaklar" ref={btnRef} btnClick={resourcesClick} open={resourcesBool && !chartBool && sd.parents}/>
            default:
              break;
          }
        })
      }
    </div>
  )
}

export default Sidebar