import React from 'react'

const CheckBox = ({handleChange,checked,name,label,module,className,disabled}) => {

  return (
    <label className={`custom-checkbox ${disabled ? 'disabled' : ''} ${className ? className : ''}`}>
        <input disabled={disabled} onChange={handleChange} value={module} type="checkbox" name={name} checked={checked}/>
        <svg className={`custom-checkbox--checkbox ${checked ? 'checked' : ''}`} fill='none' viewBox='0 0 15 11' area-hidden='true'>
            <path d="M1 4.5L5 9L14 1" strokeWidth='2' stroke={checked ? "#fff" : "none"} />
        </svg>
        {label && label}

    </label>
  )
}

export default CheckBox