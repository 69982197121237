import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import * as api from '../api/index'
import { toast } from 'react-toastify';
import IconButton from './../components/button/IconButton';
import images from '../images/images';
import { useCompanyDispatch } from '../context/company/companyContext';
import { useNavigate } from 'react-router-dom';
import {COMPANY_CARD_POST_DATA} from '../context/company/actions';
import {useLoader} from '../hooks/useLoader';
import {useRequest} from '../hooks/useRequest';
import useToastify from '../hooks/useToastify';

const CreateCompanyCard = () => {
    const companyDispatch = useCompanyDispatch()
    const navigate = useNavigate()
    const setLoader = useLoader()
    const request = useRequest()
    const { errorToast } = useToastify()
    const [dataFetchTerm, setDataFetchTerm] = useState([])
    const [companyCard, setCompanyCard] = useState([])
    const [dataFetch, setFetchTerm] = useState([])
    const [datas, setDatas] = useState([])
    const [filter, setFilter] = useState([])

    
    
    const [newCard, setNewCard] = useState({
       processStatus:0,status:0,companyId:'',periodId:''
      })
      const [newCardPlaceholder, setNewCardPlaceholder] = useState({
        companyId:'', periodId:''
       })


    useEffect(()=>{ 
        const fetchDataCompany = async () => {
          try { 
          const dataCompany = await api.fetchData();
          setFetchTerm(dataCompany.data?.result)
          } catch (error) {   
          //console.log("error", error.message); 
          }    
          }    

          const fetchDataTerm = async () => {
             try { 
               const dataTerm = await api.fetchDataTerm();    
               setDataFetchTerm(dataTerm.data?.result)
             } catch (error) {   
                //console.log("error", error.message); 
                }    
            } 

            const fetchDataCompanyCard = async () => {
              try { 
                const dataTerm = await api.fetchDataCardCompany();    
                setCompanyCard(dataTerm.data?.result)
              } catch (error) {   
   
                 }    
             } 


            fetchDataTerm()   
            fetchDataCompany() 
            fetchDataCompanyCard()
            },[])

              let defaultOptions =  []
              let defaultOptions2 =  []
              let defaultOptions3 =  []

             dataFetch?.forEach(data => {                            
               return defaultOptions.push({label : data?.name, value: data?.id, id:"companyId"})        
             })
              
            dataFetchTerm?.forEach(data => {                           
              return defaultOptions2.push({label : data?.name, value: data?.id, id:"periodId"})        
            })

            companyCard?.forEach(data => {   
              
              return defaultOptions3.push({label : data?.periods.name, value: data?.companyId, id:data?.periodId})        
            })
                      
  useEffect(() => {
    if (datas.length > 0 ) {

      const result = defaultOptions2.filter(ad => 
        datas.every(fd => fd.name !== ad.label));

        setFilter(result);

        if(result.length< 1)
        { 
          errorToast("Tüm dönemler için şirket kaydı mevcut!")
        }  

    }
  }, [datas]);

            const  handleChangeDropdown = (e) => {
              let value = [];         
             
              defaultOptions3.filter(data =>data.value == e.value).map(
          (res) =>
            value.push({
              periodId: res?.id,
              companyId: res?.value,
              name:res?.label
            }),
            );
          setDatas(value);
     
                setNewCardPlaceholder(prev => ({...prev,[e.id]:e.label}))
                setNewCard(prev => ({...prev,[e.id]:e.value}))
            };

            const createCardBtn = async() => {
              setLoader(true)
              await request('/CompanyCard/Create',{method:'POST',data:newCard}).then(res => {
                if (res) {
                  companyDispatch({type: COMPANY_CARD_POST_DATA, payload: res?.data?.result})
                    navigate('/company-card')
                    toast.success("Ekleme İşleminiz Başarılı !!!")
                }
              })
              setLoader(false)
            }


  return (
    <div className='create-company-card'>
    <div className='create-company-card-header'>
        Şirket Kartı Oluştur
    </div>
    <div className='create-company-card-select'>    
    <div id='react-dropdown-div-id' className='react-dropdown-div'>
         <Select  value={newCard.companyId} onChange={handleChangeDropdown} placeholder={newCardPlaceholder.companyId ? newCardPlaceholder.companyId :"Şirket Seçiniz"} name="nameCompany" options={defaultOptions} />
    </div>
    <div style={{marginBottom:'60px'}} id='react-dropdown-div-id' className='react-dropdown-div'>
         <Select  value={newCard.periodId} onChange={handleChangeDropdown} placeholder={newCardPlaceholder.periodId ? newCardPlaceholder.periodId : "Dönem Seçiniz"} name="nameTerm" options={filter} />
    </div>
    <IconButton buttonClick={createCardBtn} imgRight="OK"  title="KART OLUŞTUR" image={images.plus} maxWidth="210px" background="#1392E5" color='white' borderColor='#1392E5'/>
    </div>
    </div>
  )
}

export default CreateCompanyCard