import React from 'react'
import Select from 'react-select'


const SmDropdown = ({title, options,isMulti=false, handleChange}) => {
  
  const defaultOptions =  [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  return (
    <div className='react-dropdown-div'>
    <Select onChange={handleChange} placeholder={title ? title : "Hedef Seçiniz"} isMulti={isMulti} options={options ? options : defaultOptions} />
    </div>
  )
}

export default SmDropdown