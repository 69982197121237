import {Backdrop} from '@mui/material';
import React from 'react'
import {DELETE_COMPANY_CARD_MODAL} from '../../../context/alert/actions';
import {useAlertDispatch, useAlertState} from '../../../context/alert/alertContext';
import * as api from '../../../api/index'
import {useCompanyDispatch} from '../../../context/company/companyContext';
import {COMPANY_CARD_DELETE_DATA} from '../../../context/company/actions';
import {useLoader} from '../../../hooks/useLoader';
import {useRequest} from '../../../hooks/useRequest';
import {toast} from 'react-toastify';

const DeleteCompanyCardModal = () => {
  const { deleteCompanyCardModal } = useAlertState();
  const alertDispatch = useAlertDispatch()
  const companyDispatch = useCompanyDispatch()
  const setLoader = useLoader()
  const request = useRequest()

  const closeModal = () => {
    alertDispatch({
      type:DELETE_COMPANY_CARD_MODAL,
      payload:{open:false}
    })
  }
  const deleteCompanyCard = async() => {
    setLoader(true)
    try {
          await request('/CompanyCard/Delete',{method:'DELETE',params:{id:deleteCompanyCardModal.detail.id}}).then(res => {
           if (res) {
              companyDispatch({
                type:COMPANY_CARD_DELETE_DATA,
                payload:deleteCompanyCardModal.detail.id
              })
               closeModal()
               setLoader(false)
               toast.success('Şirket Kartı Silme İşlemi Başarılı')
           }
           else {
               setLoader(false)
               closeModal()
           }
          })
          
    } catch (error) {
       setLoader(false)
    }
  }
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(3px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={deleteCompanyCardModal.open}
    >
     <div className='ceoPagesModal'>
         <h1>Şirket Kartını İstediğinize Emin misiniz?</h1>
         <div className='ceoPagesModal-yes-no'>
            <div onClick={deleteCompanyCard}>Evet</div>
            <div onClick={closeModal}>Hayır</div>
         </div>
     </div>
    </Backdrop>
  )
}

export default DeleteCompanyCardModal