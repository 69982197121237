import React from 'react'
import { Link } from "react-router-dom";
import Button from '../components/button/Button';
import images from '../images/images';

const ServerError = () => {
  return (
    <div className='not-found-container'>
        <h1 className="not-found-container--big-title">
            500
        </h1>
        <h2 className="not-found-container--text">
            Üzgünüz... Beklenmedik Bir Hata Oluştu.
        </h2>
        <Link className="not-found-container--link" to="/">
            <Button maxWidth='253px' className="not-found-container--link--img" title="Anasayfaya Dön" image={images.rightArrow} />
        </Link>
    </div>
  )
}

export default ServerError