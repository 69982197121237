import React, { useEffect, useState } from 'react'

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from '../../api/index'
import { toast } from 'react-toastify';
import Button from '../button/Button';
import Modal from './../modal/Modal';
import { useAlertDispatch, useAlertState } from '../../context/alert/alertContext';
import IconButton from '../button/IconButton';
import images from '../../images/images';
import { useCompanyDispatch, useCompanyState } from '../../context/company/companyContext';
import { COMPANY_FETCH_DATA, COMPANY_DELETE_DATA } from '../../context/company/actions';
import {CREATE_COMPANY_MODAL, DETAIL_MODAL} from '../../context/alert/actions';
import {getUserInfo} from '../../utils/Auth';

      const DataTableComp = () => { 
      //const alertDispatch = useAlertDispatch();
      const {detailModal,createCompanyModal,deletePost} = useAlertState()
      const alertDispatch = useAlertDispatch();
      const {dataFetch} = useCompanyState()
      const companyDispatch = useCompanyDispatch()
      const isGm = getUserInfo()?.Company.includes('2')

      //delete post
      useEffect(()=>{
        if(deletePost?.id){
          companyDispatch({type:COMPANY_DELETE_DATA, payload: deletePost.id})
          //setDataFetch(arr => arr.filter(arr => arr.id !== deletePost.id))
        }
      },[detailModal.open])

      useEffect(()=>{ 
        const fetchData = async () => {

          try {
          
          const datas = await api.fetchData();
          
          companyDispatch({type:COMPANY_FETCH_DATA, payload: datas.data?.result})
          
          //setDataFetch(datas.data?.result);
          
          if(datas.data.result.length < 1) {            
            toast.error("Kayıt bulunmamaktadır.", {
              autoClose: false 
            })
          }

          } catch (error) {
          
          //console.log("error", error.message);
      
          }
          
          }
          
          fetchData()
          
          },[])

        const columns = [
          {
            name: "Şirket Logosu",
            selector: row => row.posterUrl,
            sortable: true,
            cell: d => (
                <img
                  src={d.posterUrl}
                  width={55}
                  alt='Player'
                />
              )
          },
          {
            name: "Şirket Kodu",
            selector: row => row.code,
            sortable: true,
          },
          {
            name: "Şirket Adı",
            selector: row => row.name,
            sortable: true
          },
         
        
          {
            name: "Şirket Genel Müdürü",
            selector: row => row.generalManager,
            sortable: true,
            //cell: d => <span>{d.genres.join(", ")}</span>
          },
          {
            name: "Şirket Yetkili Kullanıcıları",
            selector: row => row.companyOfCompanyUsers,
            sortable: true,
            cell: d => <>{d?.companyOfCompanyUsers?.map((u,i) => (<span key={u.id}>{u.companyUser}{i != d.companyOfCompanyUsers.length -1 ? ',' : ''}</span>))}</>
          },
          {
            name: "",
            selector: "genres",
            //sortable: true,
            cell: d => <div onClick={()=> alertDispatch({type: DETAIL_MODAL, payload: {open: true, id : d.id} })} style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'end'}}><Button title="Detaylar" maxWidth='130px' height='35px'/></div>
          }
      ]

      // const data = [
      //   {
      //     id: dataFetch.result?.map(res => res.id),
      //     title: dataFetch.result?.map(res => res.name),
      //     year: dataFetch.result?.map(res => res.code),
      //     runtime: "92",
      //     //genres: ["Comedy", "Fantasy"],
      //     genres:  dataFetch.result?.map(res => res.generalManagerId),
      //     // director: "Tim Burton",
      //     // actors: "Alec Baldwin, Geena Davis, Annie McEnroe, Maurice Page",
      //     // plot:
      //     //   'A couple of recently deceased ghosts contract the services of a "bio-exorcist" in order to remove the obnoxious new owners of their house.',
      //     posterUrl:
      //     dataFetch.result?.map(res => res.logo.url)
      //   },
      // ]
      
      
      
        let data = [];
        dataFetch?.map(res => (
          data.push(
            {
              id: res.id,
              name:res.name,
              code:res.code,
              runtime:"92",
              companyOfCompanyUsers:res.companyOfCompanyUsers,
              generalManager:res.generalManager,
              posterUrl:res.logo
            }
            )
        ))
      

      const tableData = {
        columns,
        data
      };

     // console.log('data',dataFetch)
      //console.log('newRow',newRow);
    
  
  return (
    <div>
        <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          //defaultSortAsc={false}
          pagination
          highlightOnHover
          fixedHeader={true}
          noDataComponent= {""}
          //fixedHeaderScrollHeight={'388px'}
        />
      </DataTableExtensions>

      {/* deneme işlemleri sonrası alttaki yapılar aktif hale getirilecek */}
       <div 
      onClick={()=>alertDispatch({type: CREATE_COMPANY_MODAL, payload: {open:true}})}
      className='createRowBtn'>
      {
        isGm && <IconButton maxWidth='253px' title="YENİ ŞİRKET OLUŞTUR" image={images.plusBlue} color='#1392E5' borderColor='#1392E5'/>
      }
      </div> 
       {detailModal.open && <Modal whichModal={2}/>} 
      {createCompanyModal.open && <Modal title="Şirket Ekle" whichModal={1}/>} 
    </div>
  )
}

export default DataTableComp