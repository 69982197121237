import Backdrop from '@mui/material/Backdrop';
import React,{useState, useEffect} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify';
import * as api from '../../../api/index'
import {USER_MANAGEMENT_MODAL} from '../../../context/alert/actions';
import {useAlertDispatch, useAlertState} from '../../../context/alert/alertContext'
import {ALL_USER_DATA} from '../../../context/company/actions';
import {useCompanyDispatch, useCompanyState} from '../../../context/company/companyContext';
import images from '../../../images/images';
import IconButton from '../../button/IconButton';
import { useLoader } from '../../../hooks/useLoader';
import {useRequest} from '../../../hooks/useRequest';

const CreateUserModal = ({companies}) => {
  const {userManagementModal} = useAlertState()
  const [noOptionsMessage,setNoOptionsMessage] = useState('En az 3 karakter girmeniz gerekiyor')
  const [options,setOptions] = useState([])
  const [roleOptions,setRoleOptions] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [data,setData] = useState()
  const alertDispatch = useAlertDispatch()
  const {allUsersData} = useCompanyState()
  const companyDispatch = useCompanyDispatch()
  const setLoader = useLoader()
  const request = useRequest()

  useEffect(() => {
    api.getAllRoles().then(res => {
      setRoleOptions(res.data.result)
    })
  },[])

const getCompanyName= (companyCode) => {
  const company = companies?.find(c => c.code == companyCode)
  if (company) {
    return company.name
  }else {
    return companyCode
  }
}
const handleSearchInputChange = (e) => {
  if (e.length > 2) {
    setIsLoading(true)
    setNoOptionsMessage('Yükleniyor...')
    api.searchUsers(e).then(res => {
      if (res.status == 204 || res.data.result.pidUsers.length < 1) {
        setNoOptionsMessage('Sonuç Bulunamadı')
        setOptions([])
      }else if (res.status == 200) {
        setOptions(res.data?.result.pidUsers)
      }
      setIsLoading(false)
    })
  }else {
    setNoOptionsMessage('En az 3 karakter girmeniz gerekiyor')
    setOptions([])
  }
}
const handleSelectChange = (values) => {
  setData({...data,...values})
}
const handleSubmit = async () => {
  const jsonData = JSON.stringify({
    firstName: data.firstName,
    lastName: data.lastName,
    userName: data.userName,
    ePosta:data.ePosta,
    company:data.company,
    satatus:data.status,
    roles:[
      { roleId: data.roleId }
    ]
  })
  setLoader(true)
  await request('/User/Create',{method:'POST',data:jsonData}).then(res => {
    if (res) {
        companyDispatch({type: ALL_USER_DATA,payload:[...allUsersData,res.data?.result]})
        toast.success('Kullanıcı Ekleme Başarılı')
        closeModal()
    }
  })
  setLoader(false)
}
  const closeModal = () => {
    alertDispatch({type: USER_MANAGEMENT_MODAL, payload: {open:false,isCreate:true}})
    setOptions([])
    setData()
    setRoleOptions([])
}
const handleFilter = () => {
  return true
};
  return (
    <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={userManagementModal.open}  
        >
            <div className='modalCreateTarget'>
            <div className='user-modal react-dropdown-div'>
              <div className="user-modal--container">
              <div className='user-modal--container--close-btn' onClick={closeModal}>
                  <img src={images.close} alt="" />
              </div>
              <Select filterOption={handleFilter} isLoading={isLoading} onChange={handleSelectChange} onInputChange={handleSearchInputChange} options={options && options.map(o => ({userId:o.id,label:`${o.firstName} ${o.lastName} (${getCompanyName(o.companyCode)})`,value:o.id,firstName:o.firstName,lastName:o.lastName,userName:o.userName,company:o.companyCode,ePosta:o.eMail,roles:o.roles}))} noOptionsMessage={() => noOptionsMessage} loadingMessage={() => noOptionsMessage} styles={{minWidth:"200px"}} placeholder="Kullanıcı Seçiniz"  name='user'/>
              <Select onChange={handleSelectChange} isDisabled={!data?.userId} styles={{minWidth:"200px"}} options={roleOptions && roleOptions.map(o => ({roleId:o.id,label:o.name,value:o.name,status:o.status}))} placeholder="Rol Seçiniz"  name='role'/>
              <IconButton disabled={!data?.roleId} buttonClick={handleSubmit} imgRight="OK" title="KAYDET" image={images.rightArrow} maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
              </div>
            </div>
        </div>
        </Backdrop>
  )
}

export default CreateUserModal