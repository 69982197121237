import React from 'react'
import {toast} from 'react-toastify';

const useToastify = () => {
    const toastId = React.useRef(null);
    const errorToast = (msg) => {
        toast.dismiss(toastId.current)
        toastId.current = toast.error(msg, {
            autoClose:false
          })
    }
  return {errorToast}
}

export default useToastify