import React, { useState,useEffect } from 'react'
import { useAlertDispatch, useAlertState } from '../../context/alert/alertContext'
import images from '../../images/images'
import ChangedButton from '../button/ChangedButton'
import IconButton from '../button/IconButton'
import {RiDeleteBinLine} from 'react-icons/ri'
import Button from 'react-bootstrap/Button'
import TargetModal from './../modal/targetPageModals/TargetModal';
import TargetDeleteModal from '../modal/targetPageModals/TargetDeleteModal'
import {CREATE_TARGET_MODAL, DELETE_TARGET_MODAL} from '../../context/alert/actions'
import { toast } from 'react-toastify';
import * as api from '../../api/index'
import { useCompanyDispatch, useCompanyState } from '../../context/company/companyContext'
import { useLoader } from '../../hooks/useLoader'
import moment from 'moment'
import { AIM_FETCH_DATA, COMPANY_FETCH_DATA_DETAIL, UNIT_FETCH_DATA } from '../../context/company/actions'
import  CreatableSelect  from 'react-select/creatable';
import TargetChangeButton from '../button/TargetChangeButton'
import ExcelImportModal from '../modal/ExcelImportModal'
import currencyFormatter from '../../utils/InputFormatter' 
import {Tooltip} from '@mui/material'
import { FcInfo } from 'react-icons/fc';
import {getUserInfo} from '../../utils/Auth'
import roles from '../../constants/Roles'
import SelectStyle from '../../constants/SelectStyle'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Target = ({id,companyDetail, setUpdatedTotal}) => {
   const {createTargetModal,deleteTargetModal} = useAlertState()
   const setLoader = useLoader()
   const alertDispatch = useAlertDispatch();
   const companyDispatch = useCompanyDispatch()
   const {dataFetchCompanyCardDetail} = useCompanyState()
   const [random,setRandom] = useState(0)
   const [dataFetchAim,setDataFetchAim] = useState([])
   const [dataFetchUnit,setDataFetchUnit] = useState([])
   const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
   const [detailId, setDetailId] = useState();
   const [firstVal,setFirstVal]= useState();
   const [value, setValue] = useState('')
   const [newRowUnit, setNewRowUnit] = useState({
      name: "",
      description: "deneme",
      hyperionField: "deneme",
    });
    const [updatedRow, setUpdatedRow] = useState(null)

    const [rowIndex, setRowIndex] = useState(null)
    const [subTotal,setSubTotal] = useState()
    const [datas, setDatas] = useState()
    

   const buttonClick = () => {
      alertDispatch({type: CREATE_TARGET_MODAL, payload: {open: true} })
   }
   const openDeleteDialog = (id) => {
      alertDispatch({type: DELETE_TARGET_MODAL, payload: {open: true} })
      setDetailId(id);
   }


     const IsShown = (index) =>{     
      setSelectedItemIndex(index)
    }


   
     useEffect(()=>{
      const companyCreateCardDetail = async () => {
       try {
         setLoader(true)
         const detailData = await api.fetchDataCardCompanyDetail(id) 
         companyDispatch({type:COMPANY_FETCH_DATA_DETAIL, payload: detailData?.data?.result})
        setLoader(false)

       } catch (error) {
         setLoader(false)
       }
      }
      companyCreateCardDetail()
     },[companyDetail, random])

     useEffect(()=>{ 
      const fetchDataAim = async () => {
        try {   
        const datas = await api.fetchDataAim(); 
        companyDispatch({type: AIM_FETCH_DATA, payload: datas.data?.result});
        setDataFetchAim(datas.data?.result)
        } catch (error) {   
   
        } 
        }

        const fetchDataUnit = async () => {
          try {   
          const datas = await api.fetchDataUnit(); 
        companyDispatch({type: UNIT_FETCH_DATA, payload: datas.data?.result});

          setDataFetchUnit(datas.data?.result)
          } catch (error) {   
           
          } 
          }
        
          fetchDataUnit()
          fetchDataAim()
        
        },[random])

  let defaultOptions =  []
  let defaultOptions2 =  []
  let defaultOptions3 = [
   {value:8,label:"8. Ay",id:'actualPeriod'},
   {value:9,label:"9. Ay",id:'actualPeriod'},
   {value:10,label:"10. Ay",id:'actualPeriod'},
  ]

  useEffect(()=>{
   dataFetchAim?.forEach(data => {
      return defaultOptions.push(
         {value:data?.id, label:data?.name, id:'targerKPIId' }
     )
   })
  },[defaultOptions])

  useEffect(()=>{
   dataFetchUnit?.forEach(data => {
     return defaultOptions2.push(
         {value:data?.id, label:data?.name, id:'unitId' }
     )
   })
  },[defaultOptions2])

   const onChange = (index, e, text) => {
      e.target.value = text ? e.target.value : e.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1')
    
         if(!updatedRow) return
         setRowIndex(index)
         setUpdatedRow(prev => ({
            ...prev,
            [index]: {
               ...prev[index],
               [e.target.name]:  e.target.value == '' ? null : text ? e.target.value : Number(e.target.value)
            }
         }))
      }
       
   
    useEffect(() => {
      if(dataFetchCompanyCardDetail?.length > 0){    
         if(rowIndex !== undefined && rowIndex !== null)
         {
         setSubTotal(dataFetchCompanyCardDetail[rowIndex]?.companyCard ? dataFetchCompanyCardDetail[rowIndex]?.companyCard?.subTotalScore?.toFixed(2) : "Sonuçlanmadı" );
         setDatas(dataFetchCompanyCardDetail[rowIndex]?.companyCard);
         }else{  
             setSubTotal(dataFetchCompanyCardDetail[0]?.companyCard.subTotalScore !== 0  && dataFetchCompanyCardDetail[0]?.companyCard.subTotalScore !== null ? dataFetchCompanyCardDetail[0].companyCard.subTotalScore?.toFixed(2) : "Sonuçlanmadı" );
           }
         setUpdatedRow(dataFetchCompanyCardDetail);   
      }
   }, [dataFetchCompanyCardDetail])
   
   
   const focusUpdate = (index, e, str) => {
      // setRowIndex(prev => ({...prev, ...dataFetchCompanyCardDetail[index]}))
      setFirstVal(e.target.value)
      if(!updatedRow) return
      setRowIndex(index)
      setUpdatedRow(prev => ({
         ...prev,
         [index]: {
            ...prev[index],
            [e.target.name]: str ? e.target.value : Number(e.target.value)
         }
      })) 
   }
 

   const onBlur = async(index, e,str) => {
     try {
      if(str){
         const updatePost = await api.updatePostDataCardCompanyDetail(JSON.stringify(updatedRow[rowIndex])) 
         setUpdatedTotal(prev => ({...prev,ceoTotalScore: updatePost.data.result.companyCard.ceoTotalScore,occuredBonusScore: updatePost.data.result.companyCard.occuredBonusScore,totalScore:updatePost.data.result.companyCard.totalScore}))

         companyDispatch({type: "COMPANYCARDUPDATEDATADETAIL", payload: updatePost.data.result})
         toast.success('Update İşlemi Başarılı')
      }else {
         if(firstVal !== e?.target.value){
            const updatePost = await api.updatePostDataCardCompanyDetail(JSON.stringify(updatedRow[rowIndex])) 
            setUpdatedTotal(prev => ({...prev,ceoTotalScore: updatePost.data.result.companyCard.ceoTotalScore,occuredBonusScore: updatePost.data.result.companyCard.occuredBonusScore,totalScore:updatePost.data.result.companyCard.totalScore}))
            companyDispatch({type: "COMPANYCARDUPDATEDATADETAIL", payload: updatePost.data.result})
               toast.success('Update İşlemi Başarılı')  
               if (e.target.name == 'underLimitValue' || e.target.name == 'upperLimitValue' || e.target.name == 'score' || e.target.name == 'weightedScore') {
                  e.target.value = currencyFormatter(updatePost.data.result[e.target.name])
               }
            }
      }   
     } catch (error) {
         Object.keys(dataFetchCompanyCardDetail[rowIndex]).forEach(key => {
            if (key == e.target.name) {
            e.target.value = dataFetchCompanyCardDetail[rowIndex][key]
            }
         })
     }
   }

 

   
   // ------------------------------------------------------ //

   const handleChangeDropdown = (index,e) => {
      // if (e.__isNew__) {
      //   let newValue = dataFetchUnitt?.filter((data) => data.name == e?.value);
      //   //  let newValue = {value:e?.value, label:e?.label, id:'unitId' }
      //   console.log("newValuenewValuenewValue", newValue);
      //   setCreateTargetCard({ ...createTargetCard, ...newValue });
      // } else {
        // setUpdatedRow({ ...updatedRow, [e.id]: e.value });
     // setNewRowUnit({ ...newRowUnit, name: e })
         if(!updatedRow) return;
      setRowIndex(index)
      setUpdatedRow(prev => ({
         ...prev,
         [index]: {
            ...prev[index],
            [e.id]: e.value
         }
      }))
      //}
    };
   let newUnitFunc = async (newUnitName) => {
      try {
        
        let newData = await api.createPostDataUnit(JSON.stringify(newUnitName ? {...newRowUnit,name:newUnitName}:newRowUnit));
        let insertedUnit = {...newData?.data?.result};
        setDataFetchUnit([...dataFetchUnit, insertedUnit]);
        //dataFetchUnit.push({ ...insertedUnit  })
     
        let normalizedUnit = {id: insertedUnit?.id, name: insertedUnit?.name, hyperionField: insertedUnit?.hyperionField, description: insertedUnit?.description}
        let unitsExceptNew = dataFetchUnit.filter(
          (f) => f.name !== newRowUnit?.name
        );
        unitsExceptNew.push(normalizedUnit)
        setUpdatedRow({ ...updatedRow, unitId: normalizedUnit.id });
      } catch (error) {
        console.log(error.message);
      }
    };
  
  
    const newUnitId = (e) => {
      if (e.key === "Enter") {
        newUnitFunc();
        
      }
    };

    const onDragEnd = async(result) => {
      if (!result.destination) {
        return;
      }
      console.log(result, "result");
      // Sürüklenen öğeyi hedefe taşı
      const items = Array.from(dataFetchCompanyCardDetail);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem); 
      console.log(items, "items");
      const newData = {
         companyCardId: id,
         cards: items.map((item,i) => ({id: item?.id, order: i}))
      }
      const updatePost = await api.updatePostDataCardCompanyDetailOrder(JSON.stringify(newData));
      setRandom(Math.floor(Math.random() * 1000)) 
      // Sıralama verilerini güncelle
      // setState veya başka bir yöntem kullanarak verileri güncelle
    };

  return (
    <div className='target'>
     
  
       <div className='target-header'>
           <div className='h1'>Hedefler</div>
           <div className='target-header-right'>
               {
                 companyDetail?.permission?.isPeriodBegin && companyDetail?.permission?.isEdit ?
                 <IconButton buttonClick = {buttonClick}  title="HEDEF OLUŞTUR" image={images.bluePlus} maxWidth="210px" color='#1392E5' borderColor='#1392E5'/> : ''
               }
           </div>
       </div> 
       {dataFetchCompanyCardDetail?.length > 0 ? <div> 
         <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
       <table ref={provided.innerRef} style={{width:'100%'}}>
       {deleteTargetModal?.open && <TargetDeleteModal id={detailId} which="TargetDetail"/>}
            <tr>
               <th></th>
               <th>Hedef Adı</th>
               <th>Birim</th>
               <th style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>Ağırlık</th>
               <th style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>{Number(companyDetail?.periods?.name) - 1} Gerçekleşen</th>
               <th style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>Alt Limit Çarpanı</th>
               <th style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>Üst Limit Çarpanı</th>
               <th>Hedef</th>
               <th style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>0 Puan</th>
               <th style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>150 Puan</th>
               <th style={companyDetail?.permission?.isPeriodBegin ? {display:'none'} : {}}>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'Yıl Sonu Tahmini':'Gerçekleşen'}</th>
               <th style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Değer':'En Güncel Değer'}</th>
               <th style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Dönem':'En Güncel Dönem (Ay)'}</th>
               <th style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>Açıklama</th>
               <th style={companyDetail?.permission?.isPeriodBegin || !companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>Puan</th>
               <th style={companyDetail?.permission?.isPeriodBegin || !companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>Ağırlıklı Puan</th>
               {(getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) && <th>Sil</th>}
            </tr>

   {
      dataFetchCompanyCardDetail?.map((detail,index) => {
         const isOccuredEdit = companyDetail?.permission?.isOccureds?.find(o => o.targetId == detail?.targerKPIId)?.isOccured
         const isTargetEdit = companyDetail?.permission?.isOccureds?.find(o => o.targetId == detail?.targerKPIId)?.isTarget
         return <Draggable key={index} draggableId={index.toString()} index={index}>
         {(provided) => (
         <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
         >
            <td>
               {
                  detail.isSubPeriod && (getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) &&  <Tooltip title={<>
                     Şirket Tarafından Girilen Güncel Değer: {detail.actualValue} <br /> <br />
                     Şirket Tarafından Girilen Güncel Dönem: {detail.actualPeriod} <br /> <br />
                     Şirket Tarafından Girilen Açıklama: {detail.actualDefinition} <br />
                  </>} placement='top-start'>
                     <span><FcInfo size={23} /></span>
                </Tooltip>
               }
            </td>
         <Tooltip disableHoverListener={detail.targetKpi.name.length < 48} placement="top" title={detail.targetKpi.name}>
         <td >
            <CreatableSelect
               formatCreateLabel={(inputText) => `"${inputText}" enter'a basınız...`}
               className='target-targetKPI-area'
               placeholder={detail?.targetKpi?.name ? detail?.targetKpi?.name :"Seçiniz"}
               //value={newRowUnit.name}
               styles={SelectStyle}
               name="name"
               onBlur={(e)=>onBlur(index,e,"1")}
               defaultValue={detail?.targetKpi?.name}
               isDisabled={!companyDetail?.permission?.isEdit }
               onFocus={(e)=>focusUpdate(index,e,"1")}
               //isValidNewOption
               // onCreateOption={handleChangeDropdown}
               //value={newRowUnit?.name}
               onChange={e=>handleChangeDropdown(index,e)}
               //onKeyDown={newUnitId}
               options={defaultOptions}
               //isDisabled={disabled ? disabled : false}
            />
         </td>
         </Tooltip>
         <Tooltip disableHoverListener={detail.unit.name.length < 7} placement="top" title={detail.unit.name}>
         <td>
         <CreatableSelect
                      formatCreateLabel={(inputText) => `"${inputText}" enter'a basınız...`}
                      className='target-unit-area'
                      placeholder={detail?.unit?.name ? detail?.unit?.name :"Seçiniz"}
                      styles={SelectStyle}
                      //value={newRowUnit.name}
                      name="unit"
                      onBlur={(e)=>onBlur(index,e,"1")}
                      isSearchable={true}
                      isDisabled={!companyDetail?.permission?.isEdit }
                      onFocus={(e)=>focusUpdate(index,e,"1")}
                      defaultValue={detail?.unit?.name}
                       onCreateOption={e => newUnitFunc(e)}
                      //isValidNewOption
                      // onCreateOption={handleChangeDropdown}
                      //value={newRowUnit?.name}
                      onChange={e=>handleChangeDropdown(index,e)}
                      onKeyDown={e => {setNewRowUnit({ ...newRowUnit, name: e.target.value }); newUnitId(e)}}
                      options={defaultOptions2}
                      //isDisabled={disabled ? disabled : false}
                    />
                    </td>
         </Tooltip>
         <td style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit || companyDetail?.permission?.isDisabled} name="weight" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.weight)} /></td>
                     <td style={!companyDetail?.permission?.isMidtermOutFiledShow ? { display: 'none' } : {}}> <TargetChangeButton setRowIndex={setRowIndex} setUpdatedTotal={setUpdatedTotal} updatedRow={updatedRow} setUpdatedRow={setUpdatedRow} index={index} isExcel={false} targetKpi={{ companyId: detail?.companyCard?.companyId, oldPeriod: true, scenario:"Gecen_Yil_EnfDuz", periodId:detail?.companyCard?.periodId,kpi:detail?.targetKpi,unitId:detail?.unitId}} disabled={!isOccuredEdit || companyDetail?.permission?.isDisabled} name="lastYearTarget" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.lastYearTarget)} /></td>
         <td style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit || companyDetail?.permission?.isDisabled} name="underLimitMultiplier" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.underLimitMultiplier)} /></td>
         <td style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit || companyDetail?.permission?.isDisabled} name="upperLimitMultiplier" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.upperLimitMultiplier)} /></td>
         <td> <TargetChangeButton isExcel={false}  setRowIndex={setRowIndex} setUpdatedTotal={setUpdatedTotal} disabled={!isTargetEdit || companyDetail?.permission?.isDisabled} updatedRow={updatedRow} setUpdatedRow={setUpdatedRow} index={index} targetKpi={{companyId:detail?.companyCard?.companyId, oldPeriod:false,scenario:"Butce_EnfDuz", periodId:detail?.companyCard?.periodId,kpi:detail?.targetKpi,unitId:detail?.unitId}} name="target" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdateForData={focusUpdate} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.target)} readValue={currencyFormatter(detail?.target)}/></td>
         <td style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit || companyDetail?.permission?.isDisabled} name="underLimitValue" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} value={currencyFormatter(detail?.underLimitValue)} /></td>
         <td style={!companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit || companyDetail?.permission?.isDisabled} name="upperLimitValue" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.upperLimitValue)} /></td>
         <td style={companyDetail?.permission?.isPeriodBegin ? {display:'none'} : {}}> <TargetChangeButton setUpdatedTotal={setUpdatedTotal} setRowIndex={setRowIndex} isExcel={true} disabled={!isOccuredEdit} updatedRow={updatedRow} setUpdatedRow={setUpdatedRow} index={index} targetKpi={{companyId:detail?.companyCard?.companyId, oldPeriod:false,scenario:"Tahmin_Prim", periodId:detail?.companyCard?.periodId,kpi:detail?.targetKpi,unitId:detail?.unitId}} name="occured" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdateForData={focusUpdate} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.occured)} readValue={currencyFormatter(detail?.occured)}/></td>
         

         <td style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isMidtermUpdate} name="actualValue" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.actualValue)} /></td>
         <td style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>
         <CreatableSelect
                      //formatCreateLabel={(inputText) => `"${inputText}" enter'a basınız...`}
                      className='target-targetKPI-area'
                      placeholder={detail?.actualPeriod ? `${detail?.actualPeriod}. Ay` :"Seçiniz"}
                      styles={SelectStyle}
                      //value={newRowUnit.name}
                      name="actualPeriod"
                      onBlur={(e)=>onBlur(index,e,"1")}
                      defaultValue={detail?.actualPeriod}
                      isDisabled={!companyDetail?.permission?.isMidtermUpdate || !companyDetail?.permission?.isMidtermShow }
                      onFocus={(e)=>focusUpdate(index,e,"1")}
                      //isValidNewOption
                      // onCreateOption={handleChangeDropdown}
                      //value={newRowUnit?.name}
                      onChange={e=>handleChangeDropdown(index,e)}
                      //onKeyDown={newUnitId}
                      options={defaultOptions3}
                      //isDisabled={disabled ? disabled : false}
                    />
         </td>
         <Tooltip disableHoverListener={(!detail.actualDefinition || detail.actualDefinition?.length < 18) && companyDetail?.permission?.isMidtermShow} placement="top" title={detail.actualDefinition}>
         <td style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isMidtermUpdate} name="actualDefinition" onChange={(e)=>onChange(index,e,"text")} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={detail?.actualDefinition} /></td>
         </Tooltip>
           


         <td style={companyDetail?.permission?.isPeriodBegin || !companyDetail?.permission?.isMidtermOutFiledShow ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit} name="score" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(detail?.score)} /></td>
         <td style={companyDetail?.permission?.isPeriodBegin || !companyDetail?.permission?.isMidtermOutFiledShow ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isEdit} name="weightedScore" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index, e)} setValue={setValue} value={currencyFormatter(detail?.weightedScore)} /></td>
         {
            (getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) && <td><div className='block'   onMouseLeave={() => setSelectedItemIndex(-1)}>
            <div className='normal'> <RiDeleteBinLine onMouseOver={() => IsShown(index)} />        
               </div>
                  {selectedItemIndex == index ? (            
                  <div className='hover' >
                  <Button  className="buttonStyle" onClick={() =>openDeleteDialog(detail.id)}>İlgili Kaydı Sil</Button> 
                  </div>): null}
               </div>
            </td>
         }
        </tr>  
         )}
         </Draggable>
      })     
   }

       </table> 
        )}
        </Droppable>
      </DragDropContext>
       </div>:  <div className='target-bonus-noRow'>-</div>}
       {
         (!companyDetail?.permission?.isPeriodBegin && companyDetail?.permission?.isTotalPuansFields) &&
         <div className='total'> 
       
       <p className='pStyle'>Şirket Puanı Ara Toplamı: 
         </p>      
       
         <div className='totalButton'>

         <div className='changed-button'>  
            <input disabled={true} name="subTotal" setValue={setSubTotal} value={currencyFormatter(subTotal)} readValue={currencyFormatter(subTotal)} placeholder={subTotal ? subTotal : "Sonuçlanmadı"}/>
         </div>
         </div>
         </div>
       }
       
         {createTargetModal?.open && <TargetModal companyDetail={companyDetail} id={id}/>}
         {<ExcelImportModal companyCardId ={ id }/>}
         </div>
         )
         }



         export default Target