import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Button from '../components/button/Button';
import {useAlertState} from '../context/alert/alertContext';
import images from '../images/images';
const NotFound = () => {

    const {errorStatus} = useAlertState()

    const getContent = () => {
        switch (errorStatus?.status) {
            case 404:
                return {title:"404",text:"Üzgünüz... Aradığınız Sayfaya Ulaşılamıyor."}
            case 401:
                return {title:"401",text:"Üzgünüz... Bu Sayfaya Erişim Yetkiniz Yok."}
            case 500:
                return {title:"500",text:"Üzgünüz... Beklenmedik Bir Hata Oluştu."}
            default:
                return {title:"404",text:"Üzgünüz... Aradığınız Sayfaya Ulaşılamıyor."}
        }
    }

    let content = getContent()

    useEffect(()=>{
      getContent()
    },[errorStatus?.status])


  return (
    <div className='not-found-container'>
        <h1 className="not-found-container--big-title">
           {content.title} 
        </h1>
        <h2 className="not-found-container--text">
           {content.text}
        </h2>
        <Link className="not-found-container--link" to="/">
            <Button maxWidth='253px' className="not-found-container--link--img" title="Anasayfaya Dön" image={images.rightArrow} />
        </Link>
    </div>
  )
}

export default NotFound