import './scss/styles.css'


import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import Footer from './components/footer/Footer'
import ContainerBox from './containers/ContainerBox';
import PagesBox from './containers/PagesBox';
import Home from './pages/Home';
import { AlertProvider, useAlertState } from './context/alert/alertContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import CompanyManagementPage from './pages/CompanyManagementPage';
import { CompanyProvider } from './context/company/companyContext';
import CreateTarget from './pages/CreateTarget';
import { useEffect, useState } from 'react';
import { getFirebaseToken } from './api/firebase';
import NotFound from './pages/NotFound';
import {RequestProvider} from './context/request/requestContext';
import Relocator from './components/Relocator/Relocator';
import * as api from './api/index'
import Login from './pages/Login';
import AimManagementPage from './pages/AimManagementPage';
import TermManagementPage from './pages/TermManagementPage';
import UnitManagementPage from './pages/UnitManagementPage';
import Loader from './components/loader/Loader';
import GroupManagementPage from './pages/GroupManagementPage';
import Management from './pages/Management';
import RoleManagement from './containers/userManagementPage/RoleManagement';
import UserManagement from './containers/userManagementPage/UserManagement';
import CompanyCardManagementPage from './pages/CompanyCardManagementPage';
import CreateCompanyCard from './pages/CreateCompanyCard';
import TestPage from './pages/TestPage';
import moment from 'moment';
import 'moment/locale/tr';
import NoAuth from './pages/NoAuth';
import {setUserToStorage} from './utils/Auth';
import ScreenSize from './utils/ScreenSize';
import ServerError from './pages/ServerError';
import MailManagementPage from './pages/MailManagementPage';
import ResourcesPage from './pages/ResourcesPage';
// moment locale
moment.locale('tr',{
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss', 
    L : 'yyyy-MM-DD',
    LD : 'yyyy-MM-DD',
    LL : 'DD.MM.YYYY - HH:mm',
    LLL : 'DD MM YYYY HH:mm:ss',
    LLLL : 'D MMMM YYYY dddd HH:mm'
},
})

function App() {
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    getFirebaseToken(setTokenFound);
    if (JSON.parse(localStorage.getItem('isFirstLogin')) != false) {
      localStorage.setItem('isFirstLogin',true)
    }
  },[])
  //zoom kontrol kısmı ...
  // Notification.requestPermission().then(permission => {
  //   if (permission === 'granted') {
  //     console.log('Notification permission granted.');
  //   }
  // })

  const [zoomError, setZoomError] = useState(false)
  window.addEventListener('resize', () => {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    console.log("browserZoomLevel",browserZoomLevel)
   if(browserZoomLevel >= 125) {
    setZoomError(true)
   }else{
    setZoomError(false)
   }
  })


    useEffect(()=> {
       const token = JSON.parse(localStorage.getItem('auth'))
       const expireTime = JSON.parse(localStorage.getItem('expireTime'))
        const getSSOLink = async () => {
          const ssoLink = await api.loginSSOpage();
          window.location.href = ssoLink.data
          //console.log("ssoLink",ssoLink.data)
        }  
 
        if(expireTime && expireTime < moment(new Date()).format()){
          localStorage.clear(); 
          getSSOLink()
        }
        if((!token || !expireTime) && window.location.pathname != '/noauth'){
         localStorage.clear(); 
         getSSOLink()
       } 
  
        if (token) {
         setUserToStorage(token)
        }    
    },[window.location])

  

  return (
      // zoomError ? 
      // <div>Lütfen tarayıcı ayarlarınızı kontrol ediniz. Uygulama %125 ve altındaki zoom seviyelerinde çalışmaktadır.</div>
      //  :
   <div className="app">
    
    <BrowserRouter>
    <CompanyProvider>
      <AlertProvider>     
         <RequestProvider> 
          <Relocator/>
          <Loader/>
          <Navbar/>
          <ContainerBox>
            <Sidebar/>
              <PagesBox>
                <Routes>
                  <Route path="/management" element={<Management />}>
                    <Route path='role' element={<RoleManagement/>}/>
                    <Route path='user' element={<UserManagement/>}/>
                    <Route path='mail' element={<MailManagementPage/>}/>
                  </Route>
                  <Route path="/create-company-card" element={<CreateCompanyCard />} />
                  <Route path="/company-card" element={<CompanyCardManagementPage />} />
                  <Route path="/company" element={<CompanyManagementPage />} />
                  <Route path="/aim" element={<AimManagementPage />} />
                  <Route path="/unit" element={<UnitManagementPage />} />
                  <Route path="/term" element={<TermManagementPage />} />
                  <Route path="/noauth" element={<NoAuth />} />
                  <Route path="/server-error" element={<ServerError />} />
                  <Route path="/group" element={<GroupManagementPage />} />
                  <Route path="/company-card/:id" element={<CreateTarget />} />
                  <Route path="/" element={<Home/>} />  {/* update edilecek */}
                  <Route path="/test" element={<TestPage />} />  {/* update edilecek */}
                  <Route path="/resources" >
                    <Route path='videos' element={<ResourcesPage/>}/>  
                  </Route> 
                  <Route path="/successLogin/:key" element={<Login />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </PagesBox>
          </ContainerBox>
        <Footer/>
        <ToastContainer position="bottom-right"/>   
      </RequestProvider> 
    </AlertProvider>
    </CompanyProvider>
  </BrowserRouter>

         
   </div>
  );
}

export default App;
