import React, {useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import { useCompanyDispatch } from '../../../context/company/companyContext';
import images from '../../../images/images';
import IconButton from './../../button/IconButton';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from 'moment';
import {TERM_POST_DATA} from '../../../context/company/actions';
import {CREATE_COMPANY_MODAL} from '../../../context/alert/actions';
import { useLoader } from '../../../hooks/useLoader'
import {useRequest} from '../../../hooks/useRequest';
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr)
const TermCreateModal = () => {
    const {detailModal,createCompanyModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const companyDispatch = useCompanyDispatch()
    const setLoader = useLoader()
    const request = useRequest()
    const [detail, setDetail] = useState(null)
    const [errors,setErrors] = useState({name:"",description:"",isPossibleCompany:"",type:""})
    const [newRow, setNewRow] = useState({
         name:'', startDateAt:'', endDateAt: '', dialogField :'' 
      })
    useEffect(()=> {
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetDataTerm(detailModal.id)
                setDetail(data?.data?.result)
                setNewRow(data?.data?.result)
             }
         } catch (error) {
             console.log(error);
         }   
     }
     getDetail()
    },[detailModal])
   
    const closeModal = () => {
        alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
    }

    const handleNewRowChange = (e) => {
        setNewRow({...newRow, [e.target.name]:e.target.value})
        setErrors(prev => ({...prev,[e.target.name]:""}))
      }
    const validateForm = () => {
        let isValid = true
        if (!newRow.name) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.startDateAt) {
            setErrors(prev => ({...prev,type:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        if (!newRow.endDateAt) {
            setErrors(prev => ({...prev,isPossibleCompany:"Lütfen bir seçim yapın"}))
            isValid = false
        }
        return isValid
    }
    const createRowBtn = async() => {
    setLoader(true)
    try {
        await request('/Period/Create',{method:'POST',data:newRow}).then(res => {
            if (res) {
                companyDispatch({type: TERM_POST_DATA, payload: res.data.result})
                alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
                toast.success("Ekleme İşleminiz Başarılı !!!")
                setLoader(false)
            }
        })
    } catch (error) {
        setLoader(false)
    }
    }

    const dateOnChange = (date,cal) => {
        var newDate = moment(date).format("L")
        if(cal == "start"){
            setNewRow({...newRow, startDateAt:newDate})
        }else {
            setNewRow({...newRow, endDateAt:newDate})
        }
    }
  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createCompanyModal.open}  
        >
            <div className='modalCreateTarget'>
            <div className='company-modal'>
                <div className="company-modal--container">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Dönem Adı *
                            </div>
                            <input type="text" placeholder='Dönem adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.name} name="name" onChange={handleNewRowChange} value={newRow.name} />
                            {errors.name && <span className='company-modal--container--error'>{errors.name}</span>}
                        </div>
                    </div>
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Diyalog Alanı
                            </div>
                            <input type="text" placeholder='Diyalog alanı girin' className='company-modal--container--input-area--input' defaultValue={detail?.dialogField} name="dialogField" onChange={handleNewRowChange} value={newRow.dialogField} />
                            {/* {errors.name && <span className='company-modal--container--error'>{errors.name}</span>} */}
                        </div>
                    </div>
                    <div className="company-modal--container--body">
            

                        <div className="company-modal--container--body--half-width">
                            
                        <div className='company-modal--container--input-area'>
                                    <div className="company-modal--container--input-area--label">
                                       Dönem Başlangıç Tarihi *
                                    </div>
                                    <div className='react-dropdown-div'>
                                    <DatePicker
                                            locale="tr"
                                            //selected={moment(newRow?.startDate).toDate}
                                            onChange={date => dateOnChange(date,"start")}
                                            placeholderText={newRow?.startDateAt ? newRow?.startDateAt : "yyyy-mm-dd"}
                                            filterDate={date => date.getDay() !== 6 && date.getDay() !== 0} // weekends cancel
                                            showYearDropdown // year show and scrolldown alos
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            dateFormat="yyyy-MM-dd"
                                            dateFormatCalendar='yyyy-MM-dd'
                                        />
                                    </div>
                                    {errors.type && <span className='company-modal--container--error'>{errors.type}</span>}
                                </div>


                            <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                Dönem Bitiş Tarihi *
                                </div>
                                <div className='react-dropdown-div'>
                                    <DatePicker
                                            locale="tr"
                                            // selected={newRow?.endDate}
                                            onChange={date => dateOnChange(date,"end")}
                                            placeholderText={newRow?.endDateAt ? newRow?.endDateAt : "yyyy-mm-dd"}
                                            filterDate={date => date.getDay() !== 6 && date.getDay() !== 0} // weekends cancel
                                            showYearDropdown // year show and scrolldown alos
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            dateFormat="yyyy-MM-dd"
                                            dateFormatCalendar='yyyy-MM-dd'
                                        />
                                </div>
                                {errors.isPossibleCompany && <span className='company-modal--container--error'>{errors.isPossibleCompany}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='company-modal--container--footer'>
                       <IconButton buttonClick={createRowBtn} imgRight="OK"  title="OLUŞTUR" image={images.rightArrow} maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default TermCreateModal