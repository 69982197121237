import React, { useEffect, useState } from 'react'

const ScreenSize = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      //console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 

  }, []);

  //console.log('width', window.innerWidth)
  return width
}

export default ScreenSize