import React from 'react'

const Button = ({title, maxWidth,height,image,className,handleClick, btnClass ,width}) => {
  const options = {
    backgroundColor:!btnClass ? '#1392e5':'',
    maxWidth: maxWidth ? maxWidth :'182px',
    width:width ? width : '100%',
    borderRadius:'4px',
    color:'white',
    textAlign:'center',
    height: height ? height :'48px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:'14px',
    cursor:'pointer'
  }

  return (
    <div onClick={handleClick} className={btnClass && btnClass} style={{...options}}> {image && <img className={className} src={image}/>} {title}</div>
  )
}

export default Button