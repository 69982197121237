import {useCallback} from "react"
import {LOADER} from "../context/alert/actions"
import {useAlertDispatch} from "../context/alert/alertContext"

export function useLoader() {
    const alertDispatch = useAlertDispatch()
    const setLoader = useCallback((isLoading) => {
        alertDispatch({type:LOADER,payload:{open:isLoading}})
    },[])
    return setLoader
}