import React from "react";

const Input = ({ handleChange,value,defaultValue,label,placeholder }) => {
  return (
    <div className="sirket-kartlari--inputbox">
      <label className="sirket-kartlari--inputbox--label">
        {label}
      </label>
      <input
        type="text"
        className="sirket-kartlari--inputbox--input"
        defaultValue={defaultValue}
        name="name"
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
