import React from 'react'
import DataTableComp from '../components/termManagementPage/DataTableComp'

const TermManagementPage = () => {
  return (
    <div className='term-management-page' >
        <DataTableComp/>    
    </div>
  )
}

export default TermManagementPage