
//  let initialState = {
//    detailModal:{},
//    createModal:false,
//    errorStatus:null,
//    createTargetModal: {}
//    createTargetBonusModal: {}
//    notification: [],
//    deletePost:{id:null} // modaldan silme işlemi yapılınca bu olayın anlık FE kısmında da olmasını saglamak amaclı eklenildi buraya.

   import { initialState } from "../initialState"
   import {
     DETAIL_MODAL,
     DELETE_POST,
     CREATE_COMPANY_MODAL,
     CREATE_TARGET_MODAL,
     CREATE_TARGET_BONUS_MODAL,
     DELETE_TARGET_MODAL,
     DELETE_TARGET_BONUS_MODAL,
     USER_MANAGEMENT_MODAL,
     USER_DETAIL_MANAGEMENT_MODAL,
     ROLE_MANAGEMENT_MODAL,
     ROLE_DETAIL_MANAGEMENT_MODAL,
     PERMISION_TABLE_MODAL,
     EXCEL_IMPORT_MODAL,
     ERROR_STATUS,
     NOTIFICATION,
     LOADER,
     DELETE_COMPANY_CARD_MODAL,
     MAIL_MANAGEMENT_MODAL,
     CFO_APPROVAL_MODAL
   } from "./actions";

  const alertReducer = (state = initialState, action) => {
      switch (action.type) {
          case DETAIL_MODAL:
              return {
                ...state,
                detailModal : {
                  open : action.payload.open,
                  id : action.payload.id
                } 
              }
            case DELETE_POST:
                return {
                  ...state,
                  deletePost : {
                    id : action.payload.id
                  } 
                }  
            case MAIL_MANAGEMENT_MODAL:
                  return {
                    ...state,
                    mailManagementModal: {
                      open:action.payload.open,
                      id:action.payload.id
                    }
                  }
            case CREATE_TARGET_MODAL:
                  return {
                    ...state,
                    createTargetModal : {
                      open : action.payload.open,
                      id : action.payload.id
                    } 
                  }  
              case CREATE_TARGET_BONUS_MODAL:
                    return {
                      ...state,
                      createTargetBonusModal : {
                        open : action.payload.open,
                        id : action.payload.id
                      } 
                    }       
              case DELETE_TARGET_MODAL:
                    return {
                      ...state,
                      deleteTargetModal : {
                        open : action.payload.open,
                        id : action.payload.id
                      } 
                    }  
              case DELETE_TARGET_BONUS_MODAL:
                      return {
                        ...state,
                        deleteTargetBonusModal : {
                          open : action.payload.open,
                          id : action.payload.id
                        } 
                      }  
            case CREATE_COMPANY_MODAL:
              return {
                ...state,
                createCompanyModal :  {
                  open : action.payload.open
                } 
              }
              case USER_MANAGEMENT_MODAL:
              return {
                ...state,
                userManagementModal :  {
                  open : action.payload.open
                } 
              }
              case USER_DETAIL_MANAGEMENT_MODAL:
                return {
                  ...state,
                  userDetailManagementModal :  {
                    open : action.payload.open,
                    user: action.payload.user
                  } 
                }
            case ROLE_MANAGEMENT_MODAL:
              return {
                ...state,
                roleManagementModal :  {
                  open : action.payload.open
                } 
              }
              case ROLE_DETAIL_MANAGEMENT_MODAL:
              return {
                ...state,
                roleDetailManagementModal :  {
                  open : action.payload.open,
                  role: action.payload.role
                } 
              }
              case PERMISION_TABLE_MODAL:
              return {
                ...state,
                permissionTableModal :  {
                  open : action.payload.open,
                  role: action.payload.role
                } 
              }
            case EXCEL_IMPORT_MODAL:
              return {
                ...state,
                excelImportModal:{
                  open:action.payload.open ,
                  index:action.payload.index,
                  name:action.payload.name,
                  data:action.payload.data,
                  tag:action.payload.tag,
                  dataCreateTarget:action.payload.dataCreateTarget
                }
              }
            case CFO_APPROVAL_MODAL: 
            return {
              ...state,
              cfoApprovalModal:{
                open:action.payload.open
              }
            }
           case ERROR_STATUS:   
               return {
                ...state,
                errorStatus: action.payload
               } 
            case NOTIFICATION:   
               return {
                ...state,
                notification: action.payload
               }   
            case LOADER:
              return {
                ...state,
                loader:{
                  open:action.payload.open ,
                  ...action.payload
                }
              }
              case DELETE_COMPANY_CARD_MODAL:
                return {
                  ...state,
                  deleteCompanyCardModal:{
                    open:action.payload.open,
                    detail:action.payload.detail
                  }
                }
          default:
              return state
      }
  }

  export {initialState, alertReducer}