import React, { useState, useEffect, useRef } from "react";
import MessageCard from "./MessageCard";
import { FiSend } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import images from "../../../images/images";
import * as api from "../../../api/index";
import { GrFormClose } from "react-icons/gr";
import { HiCheckCircle } from "react-icons/hi";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useCompanyDispatch } from "../../../context/company/companyContext";
import Select from "react-select";
import useToastify from "../../../hooks/useToastify";

const ReplyMessageComponent = ({
  replyMsg,
  setReply,
  setReplyMsg,
  scrollToTopMessage,
}) => {
  const deleteReply = () => {
    setReplyMsg(null);
    setReply(null);
  };

  const goMessageWithRef = () => {
    scrollToTopMessage.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div onClick={goMessageWithRef} className="reply-message-component">
      <div className="reply-message-component-header">
        <span>{`${replyMsg.sendMessageUser.firstName} ${replyMsg.sendMessageUser.lastName}`}</span>
        <span>{moment(replyMsg.sendMesssageDateAt).format("LLL")}</span>
        <span
          onClick={deleteReply}
          className="reply-message-component-header-icon"
        >
          <GrClose />
        </span>
      </div>
      <div className="reply-message-component-body">
        <span>
          {" "}
          <strong>
            {!replyMsg?.tag ? <>Genel</> : <>{replyMsg?.tag}</>}
          </strong>{" "}
          <span dangerouslySetInnerHTML={{__html:replyMsg?.message}}></span>
        </span>
      </div>
    </div>
  );
};

const Opinions = ({
  id,
  dataFetchCompanyCardBonusDetail,
  dataFetchCompanyCardDetail,
  companyDetail,
  setCompanyDetail,
}) => {
  const scrollEndRef = useRef(null);
  const scrollToTopMessage = useRef(null);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [filesUploadedID, setFilesUploadedID] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [tags, setTags] = useState("");
  const [filterTag, setFilterTag] = useState("");
  const [defaultCategoryOptions, setDefaultCategoryOptions] = useState([]);
  const {errorToast} = useToastify()
  const [defaultFilterMessageOptions, setDefaultFilterMessageOptions] =
    useState([]);
  const companyDispatch = useCompanyDispatch();
  const [sendMsgLoader, setSendMsgLoader] = useState(false);
  useEffect(() => {
    if (
      Array.isArray(dataFetchCompanyCardBonusDetail) &&
      Array.isArray(dataFetchCompanyCardDetail)
    ) {
      const filterMessageOptions = [
        { label: "Dipnot", value: "Dipnot", name: "aim" },
        ...dataFetchCompanyCardDetail?.map((aim) => ({
          label: aim?.targetKpi?.name,
          value: aim?.targetKpi?.id,
          name: "aim",
        })),
        ...dataFetchCompanyCardBonusDetail?.map((aim) => ({
          label: aim?.targetKpi?.name,
          value: aim?.targetKpi?.id,
          name: "aim",
        })),
      ];
      const allData = companyDetail?.permission?.isOpinionDipnot
        ? [
            { label: "Dipnot", value: "Dipnot", name: "aim" },
            ...dataFetchCompanyCardDetail?.map((aim) => ({
              label: aim?.targetKpi?.name,
              value: aim?.targetKpi?.id,
              name: "aim",
            })),
            ...dataFetchCompanyCardBonusDetail?.map((aim) => ({
              label: aim?.targetKpi?.name,
              value: aim?.targetKpi?.id,
              name: "aim",
            })),
          ]
        : [
            ...dataFetchCompanyCardDetail?.map((aim) => ({
              label: aim?.targetKpi?.name,
              value: aim?.targetKpi?.id,
              name: "aim",
            })),
            ...dataFetchCompanyCardBonusDetail?.map((aim) => ({
              label: aim?.targetKpi?.name,
              value: aim?.targetKpi?.id,
              name: "aim",
            })),
          ];
      setDefaultCategoryOptions(allData);
      setDefaultFilterMessageOptions(filterMessageOptions);
    }
  }, [dataFetchCompanyCardBonusDetail, dataFetchCompanyCardDetail]);

  const [messageContent, setMessageContent] = useState([]);
  const [reply, setReply] = useState(null);
  const [replyMsg, setReplyMsg] = useState(null);

  useEffect(() => {
    const getAllMsg = async () => {
      const getMsg = await api.opinionGetAll(filterTag && `${filterTag}`, id);
      setMessageContent(getMsg?.data?.result);
      await companyDispatch({
        type: "MESSAGE_TAKE",
        payload: [
          ...getMsg?.data?.result.filter((msg) =>
            msg.tag.toUpperCase().includes("DIPNOT" || "@DIPNOT")
          ),
        ],
      });
    };

    getAllMsg();
  }, [reply, filterTag, dataFetchCompanyCardDetail]);

  useEffect(() => {
    if (reply) {
      setReplyMsg(messageContent?.find((msgContent) => msgContent.id == reply));
      scrollEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [reply]);

  const filesUpload = async (e) => {
    const formData = new FormData();
    try {
      let files = e.target.files;
      let fileArray = [];
      for (let i = 0; i < files.length; i++) {
        fileArray.push(files[i]);
        formData.append("document", files[i]);
      }
      //setFilesUploaded(fileArray)
      setLoading(true);
      var data = await api.opinionCreateDocuments(formData);
      if (data) {
        setFilesUploadedID(data.data.map((dt) => dt?.id));
        setFilesUploaded(data.data);
      }
    } catch (error) {
      errorToast("Dosya yükenirken bir hata oluştu, Lütfen dosyalarınızın 40mb'dan küçük ve doğru dosya tiplerinden oluştuğuna emin olunuz.")
    } finally {
      setLoading(false)
    }
  };
  const filterAims = (e) => {
    setFilterTag(e.map((e) => `@${e.value}`).join(""));
  };
  const handleTagsChanged = (e) => {
    setTags(e);
  };
  const deleteSelectedFile = async (id) => {
    await api.opinionFileDelete(id);
    setFilesUploaded(filesUploaded.filter((file) => file.id !== id));
  };

  const sendMessage = async () => {
    setSendMsgLoader(true);
    try {
      //createFormData.append("data", {message:value,opinionDocumentId:filesUploadedID})
      let createMessage = await api.opinionCreate(
        JSON.stringify({
          answerMessageId: reply,
          companyCardId: id,
          message: value,
          opinionDocumentId: filesUploadedID,
          tag:
            tags.length > 0
              ? tags?.map((e) => `@${e.value}`).join("")
              : "@Genel",
        })
      );
      setMessageContent([...messageContent, createMessage?.data?.result]);
      setTags([]);
      setReplyMsg(null);
      setReply(null);
      setValue("");

      filesUploaded.forEach(async (files) => {
        //await api.opinionDeleteDocuments(files?.id)
        setFilesUploaded(
          filesUploaded.filter(
            (file) => file.id === files.id && file.id !== files.id
          )
        );
        setFilesUploadedID(
          filesUploaded.filter((file) => file === files.id && file !== files.id)
        );
      });
      if (companyDetail?.permission?.opinionNextStatus) {
        await api
          .updatePostDataCardCompany(
            JSON.stringify({
              id: companyDetail.id,
              companyId: companyDetail.companyId,
              periodId: companyDetail.periodId,
              status: companyDetail.status,
              processStatus: companyDetail?.permission?.opinionNextStatus,
              subTotalScore: companyDetail.subTotalScore,
              totalScore: companyDetail.totalScore,
              ceoTotalScore: companyDetail?.ceoTotalScore,
              occuredBonusScore: companyDetail?.occuredBonusScore,
            })
          )
          .then((res) => {
            if (res.data.result) {
              setCompanyDetail(res.data?.result);
            }
            setSendMsgLoader(false);
          });
      }
      toast.success("Mesajınız İletildi");
    } catch (error) {
      setSendMsgLoader(false);
    }
  };

  return (
    <div className="opinions">
      <div className="opinions-header">
        <div className="h1">Görüşler/Ekler</div>
        <div className="opinions-multidropdown">
          <div className="react-dropdown-div">
            <Select
              placeholder="Tümü"
              isMulti
              options={[
                { label: "Genel", value: "Genel", name: "aim" },
                {
                  label: "Geçmiş Kayıtlar",
                  value: "Geçmiş Kayıtlar",
                  name: "aim",
                },
                ...defaultFilterMessageOptions,
              ]}
              onChange={filterAims}
            />
          </div>
        </div>
      </div>

      <div ref={scrollToTopMessage} className="opinions-container">
        {messageContent && messageContent.length > 0 ? (
          messageContent
            ?.sort(
              (a, b) =>
                moment(a.sendMesssageDateAt) - moment(b.sendMesssageDateAt)
            )
            .map((message) => (
              <MessageCard
                companyDetail={companyDetail}
                defaultCategoryOptions={defaultCategoryOptions}
                setMessageContent={setMessageContent}
                messageContent={messageContent}
                key={message.id}
                message={message}
                setReply={setReply}
              />
            ))
        ) : (
          <div className="opinions-container-nomessage">
            Seçili Kategoriye Ait Mesaj Bulunmamaktadır
          </div>
        )}
      </div>
      {companyDetail?.permission?.isOpinion && (
        <div className="opinions-sender">
          <div className="opinions-multidropdown">
            <div className="react-dropdown-div">
              <Select
                placeholder={"Genel"}
                value={tags}
                isMulti
                options={defaultCategoryOptions}
                onChange={handleTagsChanged}
              />
            </div>
          </div>

          <div ref={scrollEndRef} className="opinions-sender-input">
            {replyMsg && (
              <ReplyMessageComponent
                scrollToTopMessage={scrollToTopMessage}
                replyMsg={replyMsg}
                setReply={setReply}
                setReplyMsg={setReplyMsg}
              />
            )}
            <textarea
              style={{ paddingTop: replyMsg ? "90px" : "" }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Mesajınızı Buraya Yazınız"
              name=""
              id=""
              rows="10"
            ></textarea>
            <button
              disabled={sendMsgLoader}
              onClick={sendMessage}
              className="opinions-sender-btn"
            >
              {sendMsgLoader ? <CircularProgress size={15} /> : <FiSend />}{" "}
              &nbsp;Gönder
            </button>
          </div>

          <div className="opinions-sender-fileupload">
            <img src={images.iconAttach} alt="" />
            <label htmlFor="formInput">Dosya Ekle</label>
            <input
              onChange={filesUpload}
              id="formInput"
              accept=".png, .jpg, .jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              type="file"
              multiple={true}
              style={{ display: "none" }}
            />
          </div>

          {loading ? (
            <div className="opinions-loading-file" style={{ width: "200px" }}>
              <CircularProgress size={18} color="secondary" />
              <div>Dosyalar yükleniyor...</div>
            </div>
          ) : (
            filesUploaded.map((files, index) => (
              <div className="opinions-map-file" key={index}>
                <div>
                  <strong>
                    <HiCheckCircle size={30} color={"green"} />
                    <span>{files.name}</span>
                  </strong>
                  <GrFormClose
                    onClick={() => deleteSelectedFile(files.id)}
                    className="opinions-map-file-close"
                    size={25}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Opinions;
