import React from 'react'

const IconButton = ({imgRight,title,icon,image,borderColor,color,maxWidth, background, buttonClick,disabled=false,rotate}) => {
    const options = {
        backgroundColor: disabled ? '#e1e1e1' : background ? background : 'white', 
        maxWidth:maxWidth,
        width:'100%',
        borderRadius:'4px',
        color:color,
        textAlign:'center',
        height:'38px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        fontSize:'14px',
        cursor:'pointer',
        margin:'6px',
        border:disabled ? 'none' : `1px solid ${borderColor}` 
      }
  return (
    <button onClick={buttonClick} disabled={disabled} className='iconBtn' style={{...options}}>
         {/* şimdilik denemek maksatı ile resimleri kaldırdık.Daha sonra tekrardan eklenecek */}
        {
          imgRight ? 
          <>
           {title}
          {image && <img style={{width:'15px',marginLeft:'5px',rotate:rotate?rotate:0}} src={image} alt="" /> }
          {icon && icon}
          </>
          :
          <>
          {icon && icon}
          {image && <img style={{width:'15px',marginRight:'5px',rotate:rotate?rotate:0}} src={image} alt="" /> }
          {title}
          </>
        }
        </button>
  )
}

export default IconButton