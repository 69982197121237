import React, {useEffect, useState} from 'react'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from '../../api/index'
import {useAlertDispatch, useAlertState} from '../../context/alert/alertContext';
import {useCompanyDispatch, useCompanyState} from '../../context/company/companyContext';
import IconButton from '../button/IconButton';
import images from '../../images/images';
import Button from '../button/Button';
import CreateRoleModal from '../modal/userManagementPageModals/CreateRoleModal';
import UpdateRoleModal from '../modal/userManagementPageModals/UpdateRoleModal';
import PermissionsTableModal from '../modal/userManagementPageModals/PermissionsTableModal';
import { ALL_ROLES_DATA } from '../../context/company/actions';
import {PERMISION_TABLE_MODAL, ROLE_DETAIL_MANAGEMENT_MODAL, ROLE_MANAGEMENT_MODAL} from '../../context/alert/actions';
import TableLoader from '../loader/TableLoader';

const RoleManagementDataTable = () => {
    const {roleManagementModal,roleDetailManagementModal,permissionTableModal} = useAlertState()
      const alertDispatch = useAlertDispatch();
      const {allRolesData} = useCompanyState()
      const companyDispatch = useCompanyDispatch()
      //const [dataFetch, setDataFetch] = useState([]);
      
      //delete post


      useEffect(()=>{ 

        const fetchData = async () => {
          try {
          
          const datas = await api.getAllRoles();
          companyDispatch({type:ALL_ROLES_DATA,payload:datas.data?.result})
          //setDataFetch(datas.data?.result);
          
          } catch (error) {
          
          //console.log("error", error.message);
          
          }
          
          }
          
          fetchData()
          
          },[])

        const columns = [
          {
            name: "Rol Adı",
            selector: row => row.name,
            sortable: true
          },
          {
            name: "Rol Statüsü",
            selector: row => row.status,
            sortable: true
          },
          {
            name: "",
            selector: row => row.genres,
            //sortable: true,
            cell: d => <div  style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
                <Button handleClick={()=> alertDispatch({type:ROLE_DETAIL_MANAGEMENT_MODAL, payload: {open: true, role:{...d}} })} title="Detaylar" maxWidth='130px' height='35px'/>
                <Button handleClick={()=> alertDispatch({type: PERMISION_TABLE_MODAL, payload: {open: true, role:{...d}} })} title="Yetkileri Düzenle" btnClass='data-table-delete-btn' maxWidth='130px' height='35px'/>
            </div>
          }
      ]
      
        let data = [];
        allRolesData?.map(res => (
          data.push(
            {
              id: res.id,
              name:res.name,
              status:res.status,
            }
            )
        ))
      

      const tableData = {
        columns,
        data
      };
  return (
    <div>
        <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          //defaultSortAsc={false}
          pagination
          highlightOnHover
          fixedHeader={true}
          noDataComponent= {<TableLoader/>} 
          //fixedHeaderScrollHeight={'388px'}
        />
      </DataTableExtensions> 

       <div 
      onClick={()=>alertDispatch({type: ROLE_MANAGEMENT_MODAL, payload: {open:true}})}
      className='createRowBtn'>
      <IconButton maxWidth='253px' title="YENİ ROL OLUŞTUR" image={images.plusBlue} color='#1392E5' borderColor='#1392E5'/>
      </div> 
      {roleManagementModal.open && <CreateRoleModal title="Rol Ekle"/>} 
      {roleDetailManagementModal.open && <UpdateRoleModal title="Rol Düzenle"/>} 
      {permissionTableModal.open && <PermissionsTableModal title="Yetkilendirme Düzenle"/>} 

    </div>
  )
}

export default RoleManagementDataTable