import Backdrop from "@mui/material/Backdrop";
import React, { useEffect, useState } from "react";
import * as api from '../../../api/index'
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import images from "../../../images/images";
import IconButton from "../../button/IconButton";
import Input from "../../input/Input";
import {toast} from "react-toastify";
import CheckBox from "../../checkbox/Checkbox";
import DefaultProgress from "../../CircleProgress/DefaultProgress";
import {useCompanyDispatch, useCompanyState} from "../../../context/company/companyContext";
import { ALL_ROLES_DATA } from "../../../context/company/actions";
import {ROLE_DETAIL_MANAGEMENT_MODAL} from "../../../context/alert/actions";
import { RiDeleteBinLine, RiEditBoxLine } from "react-icons/ri";
import { useLoader } from "../../../hooks/useLoader";
import {useRequest} from "../../../hooks/useRequest";
const UpdateRoleModal = () => {
    const { roleDetailManagementModal } = useAlertState();
    const [role,setRole] = useState(roleDetailManagementModal.role)
    const [isChecked,setIsChecked] = useState(roleDetailManagementModal.role.status)
    const companyDispatch = useCompanyDispatch()
    const {allRolesData} = useCompanyState()
  const alertDispatch = useAlertDispatch();
  const setLoader = useLoader()
  const request = useRequest()

  const handleChange = (e) => {
    setRole(prev => ({...prev,name:e.target.value}))
  }
  const closeModal = () => {
    alertDispatch({
      type: ROLE_DETAIL_MANAGEMENT_MODAL,
      payload: { open: false , isChaneged:true},
    });
    setRole()
  };
  const handleSubmit = async() => {
    setRole({...role,status:isChecked ? 0:1})
    setLoader(true)
    await request('/Role/Update',{method:'POST',data:role}).then(res => {
      if (res) {
          companyDispatch({type:ALL_ROLES_DATA ,payload:[...allRolesData.map(e => {
            if (e.id == role.id) {
                return res.data?.result
            }else {
                return e
            }
          })]})
          toast.success('Rol Güncelleme Başarılı')
          closeModal()
      }
    })
    setLoader(false)
  }
  const handleDelete = () => {
    setLoader(true)
    api.deleteRole(role.id).then(res=>{
        if(res.status == 200){
          companyDispatch({type: ALL_ROLES_DATA,payload:[...allRolesData.filter(e => e.id != role.id)]})
            toast.success('Rol Silindi')
        }
        closeModal()
        setLoader(false)
    }).catch(error => {
        setLoader(false)
    })
  }
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(1px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={roleDetailManagementModal.open}
    >
      <div className="modalCreateTarget">
          <div className="user-modal react-dropdown-div">
            <div className="user-modal--container">
              <div
                className="user-modal--container--close-btn"
                onClick={closeModal}
              >
                <img src={images.close} alt="" />
              </div>
            <Input defaultValue={role?.name} handleChange={handleChange} label='Rol Adı' placeholder='Rol Adını Giriniz'/>
            <div className="user-modal--container--buttons">
            <IconButton
                icon ={ <RiEditBoxLine style={{width:"20px",border:'none'}} color="#fff" fill="#fff" /> } 
                imgRight="OK"
                title="Güncelle"
                maxWidth="130px"
                background="#1392E5"
                color="white"
                borderColor="#1392E5"
                buttonClick={handleSubmit}
              />
            <IconButton
                buttonClick={handleDelete}
                title="Sil"
                maxWidth="130px"
                background="#f9423a"
                color="white"
                borderColor="#f9423a"
                imgRight={true}
                icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> }
              />
            </div>
            </div>
          </div>
      </div>
    </Backdrop>
  );
};

export default UpdateRoleModal;
