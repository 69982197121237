import React, {useEffect,useState,useRef} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import { useCompanyDispatch } from '../../../context/company/companyContext';
import IconButton from './../../button/IconButton';
import images from '../../../images/images';
import { useLoader } from '../../../hooks/useLoader'
import {useRequest} from '../../../hooks/useRequest';
import { COMPANY_CARD_POST_DATA } from '../../../context/company/actions';
import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import  Select  from 'react-select';
import { CREATE_COMPANY_MODAL } from '../../../context/alert/actions';
import useToastify from '../../../hooks/useToastify';

const CompanyCreateModal = ({title}) => {
    const companyDispatch = useCompanyDispatch()
    const {createCompanyModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const setLoader = useLoader()
    const request = useRequest()
    const { errorToast } = useToastify()
    const [dataFetchTerm, setDataFetchTerm] = useState([])
    const [dataGroup,setDataGroup] = useState([])
    const [companyGroup,setCompanyGroup ] = useState()
    const [companyCard, setCompanyCard] = useState([])
    const [dataFetch, setDataFetch] = useState([])
    const [defaultOptions, setDefaultOptions] = useState([])
    const [datas, setDatas] = useState([])
    const [filter, setFilter] = useState([])

    
    
    const [newCard, setNewCard] = useState({
       processStatus:0,status:0,companyId:'',periodId:''
      })
      const [newCardPlaceholder, setNewCardPlaceholder] = useState({
        companyId:'', periodId:''
       })

    useEffect(()=>{ 
        const fetchDataCompany = async () => {
          try { 
          const dataCompany = await api.fetchData();
          setDataFetch(dataCompany.data?.result)
          } catch (error) {   
          //console.log("error", error.message); 
             
          }    
          }    
          const fetchDataGroup = async () => {
            try {
              const groupData = await api.fetchDataGroup()
              setDataGroup(groupData?.data?.result)
            } catch (error) {
           
            }
          }
          const fetchDataTerm = async () => {
             try { 
               const dataTerm = await api.fetchDataTerm();    
               setDataFetchTerm(dataTerm.data?.result)
             } catch (error) {   
                //console.log("error", error.message); 
                
                }    
            } 

            const fetchDataCompanyCard = async () => {
              try { 
                const dataTerm = await api.fetchDataCardCompany();    
                setCompanyCard(dataTerm.data?.result)
              } catch (error) {   
                
                 }    
             } 


            fetchDataTerm()   
            fetchDataCompany() 
            fetchDataCompanyCard()
            fetchDataGroup()
            },[])

            useEffect(()=>{
              if (companyGroup?.value) {
                setDefaultOptions(dataFetch?.filter(i => i.companyGroupId == companyGroup.value)?.map(data => {                            
                  return {label : data?.name, value: data?.id, id:"companyId", ids:data?.companyGroup?.id}       
                }))
              }
            },[companyGroup])
              let defaultOptions2 =  []
              let defaultOptions3 =  []

            dataFetchTerm?.forEach(data => {                           
              return defaultOptions2.push({label : data?.name, value: data?.id, id:"periodId"})        
            })

            companyCard?.forEach(data => {   
              
              return defaultOptions3.push({label : data?.periods.name, value: data?.companyId, id:data?.periodId})        
            })

            useEffect(() => {

              if (datas.length > 0 ) {
                const result = defaultOptions2.filter(ad =>
                  datas.every(fd => fd.name !== ad.label));
                  setFilter(result);
                  if(result.length< 1)
                  {
                    errorToast("Tüm dönemler için şirket kaydı mevcut!")
                  }   
              }else  {
                const result = defaultOptions2.filter(ad =>
                  datas.every(fd => fd.name == ad.label));
                  setFilter(result);
              }    
            }, [datas]);
            const handleChangeGroupDropdown = (e) => {
              setNewCard({
                processStatus:0,status:0,companyId:'',periodId:''
              })
              setNewCardPlaceholder({
                processStatus:0,status:0,companyId:'',periodId:''
              })
              setCompanyGroup(e)
            }
            const  handleChangeDropdown = (e) => {
              let value = [];              
              defaultOptions3.filter(data =>data.value == e.value).map(
          (res) =>
            value.push({
              periodId: res?.id,
              companyId: res?.value,
              name:res?.label
            }),
            );
             setDatas(value);
     
                setNewCardPlaceholder(prev => ({...prev,[e.id]:e.label}))
                setNewCard(prev => ({...prev,[e.id]:e.value}))
            };

            const createCardBtn = async() => {
              setLoader(true)
               await request('/CompanyCard/Create',{method:'POST',data:newCard}).then(res => {
                 if (res?.data?.result) {
                   companyDispatch({type: COMPANY_CARD_POST_DATA, payload: res?.data?.result})
                     //navigate('/company-card')
                     if(!res?.response?.data?.errors?.PeriodId){
                      toast.success("Ekleme İşleminiz Başarılı !!!")
                    }  
                     alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
                 }else {
                    alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
                 }
               })
              closeModal()
              setLoader(false)
            }

            const closeModal = () => {
                alertDispatch({type: CREATE_COMPANY_MODAL, payload: false})
              }
  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createCompanyModal.open}  
        >
            <div className='modalCreateTarget'>
            <div className='modalCreateTarget-box-close'>
                   <div className='modalCreateTarget-box-close-h1'>Bonus Hedef Olustur</div>
                </div>
            <div className='company-modal'>
                <div className="company-modal--container">
                    <div className="company-modal--container--head">
                      <div className="company-modal--container--input-area">
                          <div className="company-modal--container--input-area--label" style={{marginBottom:'10px'}}>
                              Şirket Grubunu Seçiniz *
                          </div>
                          <Select  onChange={handleChangeGroupDropdown} placeholder={"Şirket Grubu Seçiniz"} name="nameCompany" options={dataGroup?.map(g => ({label:g.name,value:g.id}))} />
                      </div>
                    </div>
                    <div className="company-modal--container--head">
                      <div className="company-modal--container--input-area">
                          <div className="company-modal--container--input-area--label" style={{marginBottom:'10px'}}>
                              Şirket Seçiniz *
                          </div>
                          <Select isDisabled={!companyGroup?.value} value={newCard.companyId} onChange={handleChangeDropdown} placeholder={newCardPlaceholder.companyId ? newCardPlaceholder.companyId :"Şirket Seçiniz"} name="nameCompany" options={defaultOptions} />
                      </div>
                    </div>
                    <div className="company-modal--container--body">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label" style={{marginBottom:'10px'}}>
                            Dönem Seçiniz *
                            </div>
                            <Select isDisabled={!companyGroup?.value} value={newCard.periodId} onChange={handleChangeDropdown} placeholder={newCardPlaceholder.periodId ? newCardPlaceholder.periodId : "Dönem Seçiniz"} name="nameTerm" options={filter} />
                        </div>
                    </div>
                      
                    </div>
                    <div className='company-modal--container--footer'>
                       <IconButton buttonClick={createCardBtn} imgRight="OK"  title="KART OLUŞTUR" image={images.plus} maxWidth="210px" background="#1392E5" color='white' borderColor='#1392E5'/>
                      
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default CompanyCreateModal