import React from 'react'
import NavbarMenu from './NavbarMenu'
import images from '../../images/images';


const Navbar = () => {
  return (
    <div className='navbar'>
        <div className='navbar__logo'>
           <img className='navbar-img' src={images.logoNew} alt="Logo" />
        </div>
        <div className='navbar__menu'>
            <NavbarMenu/>
        </div>
    </div>
  )
}

export default Navbar