import {Backdrop} from '@mui/material'
import React,{useState}  from 'react'
import Select from 'react-select'
import {importExcel, returnExcelValue} from '../../api'

import {useAlertDispatch, useAlertState} from '../../context/alert/alertContext'
import images from '../../images/images'
import IconButton from '../button/IconButton'
import DefaultProgress from '../CircleProgress/DefaultProgress'
import {EXCEL_IMPORT_MODAL} from '../../context/alert/actions'
import useToastify from '../../hooks/useToastify'

const ExcelImportModal = ({companyCardId}) => {
    const { excelImportModal } = useAlertState()
    const alertDispatch = useAlertDispatch()
    const [excelFile,setExcelFile] = useState()
    const [excelData,setExcelData] = useState()
    const [isLoading,setIsLoading] = useState(false)
    const { errorToast } = useToastify()
    //close
    const closeModal = () => {
        alertDispatch({type:EXCEL_IMPORT_MODAL, payload: {...excelImportModal,open:false}})
        setExcelData()
        setExcelFile()
    }
    // cancel upload file
    const handleCancel = () => {
        setExcelFile()
    }
    // handles
    const handleInputChange = (e) => {
        const {name,value} = e.target
        setExcelData(prev => ({...prev,[name]:value}))
    }

    const handleSheetChange = (e) =>{
        setExcelData(prev=>({...prev,sheet:e.value}))
    }
    
    const handleUpload = () => {
        const formData = new FormData()
        formData.append('excel',excelFile)
        setIsLoading(true)
        importExcel(formData).then(res => {
            setExcelData(res.data)
            setIsLoading(false)
        }).catch(error => {

            setIsLoading(false)
            setExcelData()
            setExcelFile()
        })
    }
    const handleSubmit = () => {
        setIsLoading(true)
        let formData = new FormData()
        formData.append('fileName',excelData.fileName)
        formData.append('sheet',excelData.sheet)
        formData.append('col',excelData.col.toUpperCase())
        formData.append('row',Number(excelData.row))
       if (excelImportModal.index || excelImportModal.index == 0) {
        formData.append('companyCardId',companyCardId)
        formData.append('tag',`@${excelImportModal?.tag}`)
       }

        returnExcelValue(formData).then(res =>{

            console.log( excelImportModal.index, " excelImportModal.index");
            if (!res.data || res.data == '') {
                if(res.data !== 0){
                    errorToast('Seçtiğiniz hücrede herhangi bir kayıt bulunamadı')
                }
            }
            if (excelImportModal.index || excelImportModal.index == 0) {
                alertDispatch({type:EXCEL_IMPORT_MODAL, payload: {...excelImportModal,open:false,data:res.data}})
            }
            else {
                alertDispatch({type:EXCEL_IMPORT_MODAL, payload: {...excelImportModal,open:false,dataCreateTarget:res.data}})
            }
            setExcelData()
            setExcelFile()
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
            setExcelData()
            setExcelFile()
        })
    }
    // on select file
    const onSelectFile = async(e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        setExcelFile(e.target.files[0])
    }

  return (
    <Backdrop
    sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={excelImportModal.open || false}
    >
        <div className="modalCreateTarget">
            <div className="excel-modal">
                <div className="excel-modal--close" onClick={closeModal}>
                    <img src={images.close} alt="Close Button" />
                </div>
                <div className="excel-modal--container">
                    <DefaultProgress isLoading={isLoading}/>
                    {
                        !excelData && !isLoading && <div className="excel-modal--container--upload-area">
                        {
                            excelFile ?
                            <div className='excel-modal--container--upload-area--file'>
                                {
                                    isLoading ? <></>
                                    : <>{excelFile.name} <div><button onClick={handleUpload} className='excel-modal--container--upload-area--file--upload'>Yükle</button> <button onClick={handleCancel} className='excel-modal--container--upload-area--file--cancel'>İptal</button></div></>
                                }
                            </div>
                            : <div className="excel-modal--container--upload-area--text">
                                Yüklemek için bir dosya <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onSelectFile} id="file-uploader" hidden type="file" className='excel-modal--container--upload-area--text--upload-input'/> <label htmlFor="file-uploader" className='excel-modal--container--upload-area--text--upload-label'>SEÇ</label>
                            </div> 
                        }
                    </div>
                    }
                    <div className="excel-modal--container--select-area">
                        {
                            excelData?.sheets && !isLoading && <>
                                <div className='react-dropdown-div'>
                                    <Select onChange={handleSheetChange} options={excelData.sheets?.map(s => ({label:s,value:s}))} placeholder="Tablo Seçiniz"  name='excelSheet'/>
                                </div>
                                <div className='excel-modal--container--select-area--inputs'>
                                    <div className='excel-modal--container--select-area--inputs--input'>
                                        <label htmlFor="excelColumn">Kolon Adı</label>
                                        <input onChange={handleInputChange} disabled={!excelData.sheet} type='text' name='col'/>
                                    </div>
                                    <div className="excel-modal--container--select-area--inputs--input">
                                        <label htmlFor="excelRow">Satır Numarası</label>
                                        <input onChange={handleInputChange} disabled={!excelData.sheet} type='number' name='row' />
                                    </div>
                                </div>
                                <div className="excel-modal--container--select-area--submit">
                                <IconButton disabled={!excelData.col || !excelData.row} buttonClick={handleSubmit} imgRight="OK" title="DIŞA AKTAR" image={images.rightArrow} maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </Backdrop>
  )
}

export default ExcelImportModal