const SelectStyle = {
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  valueContainer: (provided, state) => {
    const width = state.selectProps.name == 'name' ? '300px' : '80px'
    const textAlign = 'center'
    return { ...provided,  textAlign, width };
  },
    container: () => ({
      // none of react-select's styles are passed to <Control />
      position:'relative',
    }),
    placeholder: (provided) => {
      const whiteSpace='nowrap'
      const gridArea = '1/1/2/3'
      const maxWidth = '100%'
      const overflow = 'hidden'
      const textOverflow = 'ellipsis'
      const textAlign = 'center'
      return {...provided,whiteSpace, gridArea, maxWidth, overflow, textOverflow,textAlign}
    },
    control: (styles,{isDisabled}) => {
      // none of react-select's styles are passed to <Control />
      return {
        display:'flex',
        justifyContent:'space-between',
        border:'none',
        boxShadow:'none',
        backgroundColor:isDisabled ? 'rgb(242, 242, 242)' : '#E9EEF4'
      }
    }
}
const SelectStyleBonus = {
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  valueContainer: (provided, state) => {
    const textAlign = 'center'
    const width = '420px'
    return { ...provided,  textAlign, width };
  },
    container: () => ({
      // none of react-select's styles are passed to <Control />
      position:'relative'
    }),
    placeholder: (provided) => {
      const whiteSpace='nowrap'
      const gridArea = '1/1/2/3'
      const maxWidth = '100%'
      const overflow = 'hidden'
      const textOverflow = 'ellipsis'
      const textAlign = 'center'
      return {...provided,whiteSpace, gridArea, maxWidth, overflow, textOverflow,textAlign}
    },
    control: (styles,{isDisabled}) => {
      // none of react-select's styles are passed to <Control />
      return {
        display:'flex',
        justifyContent:'space-between',
        border:'none',
        boxShadow:'none',
        backgroundColor:isDisabled ? 'rgb(242, 242, 242)' : '#E9EEF4'
      }
    }
}

export {SelectStyleBonus}
export default SelectStyle