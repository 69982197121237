import React, { useEffect, useState } from "react";
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import Backdrop from "@mui/material/Backdrop";
import * as api from "../../../api/index";
import CheckBox from "../../checkbox/Checkbox";
import "react-data-table-component-extensions/dist/index.css";
import images from "../../../images/images";
import IconButton from "../../button/IconButton";
import { toast } from "react-toastify";
import DefaultProgress from "../../CircleProgress/DefaultProgress";
import {PERMISION_TABLE_MODAL} from "../../../context/alert/actions";
import {useLoader} from "../../../hooks/useLoader";

const PermissionsTableModal = () => {
  const { permissionTableModal } = useAlertState();
  const alertDispatch = useAlertDispatch();
  const setLoader = useLoader()
  const [permissions, setPermissions] = useState();
  useEffect(() => {
    setLoader(true);
    api.getByPermissionRoleId(permissionTableModal.role.id).then((res) => {
      setPermissions(
        res.data?.groupByRolePermissions.map((p) => {
          const View = p.permisisons.includes("View") ? "View" : "None";
          const Create = p.permisisons.includes("Create") ? "Create" : "None";
          const Update = p.permisisons.includes("Update") ? "Update" : "None";
          const Delete = p.permisisons.includes("Delete") ? "Delete" : "None";
          const permissionsForPush = [
            {
              name: "View",
              exPermission: View,
              permission: View,
              isChecked: View == "None" ? false : true,
            },
            {
              name: "Create",
              exPermission: Create,
              permission: Create,
              isChecked: Create == "None" ? false : true,
            },
            {
              name: "Update",
              exPermission: Update,
              permission: Update,
              isChecked: Update == "None" ? false : true,
            },
            {
              name: "Delete",
              exPermission: Delete,
              permission: Delete,
              isChecked: Delete == "None" ? false : true,
            },
          ];
          return { ...p, permisisons: permissionsForPush };
        })
      );
      setLoader(false);
    });
  }, []);
  const handleCheckboxChanged = (e) => {
    const { value, name, checked } = e.target;
    setPermissions(
      permissions.map((p) => {
        if (p.module == value) {
          return {
            ...p,
            permisisons: [
              ...p.permisisons.map((per) => {
                if (per.name == name) {
                  return {
                    ...per,
                    permission: checked ? name : "None",
                    isChecked: checked,
                  };
                } else {
                  return per;
                }
              }),
            ],
          };
        } else {
          return p;
        }
      })
    );
  };
  const handleSubmit = async () => {
    let datas = [];
    setLoader(true);
    await permissions.forEach((per) => {
      per.permisisons.forEach((permission) => {
        if (permission.exPermission != permission.permission) {
          datas.push({
            roleId: permissionTableModal.role.id,
            module: per.module,
            exPermission: permission.exPermission,
            permission: permission.permission,
          });
        }
      });
    });
    await api
      .updatePermission(datas)
      .then((res) => {
        toast.success("Rol Yetkileri Güncellendi");
      })
      .catch((error) => toast.error(error.message,{
        autoClose: false 
      }));
    setLoader(false);
    closeModal();
  };
  const closeModal = () => {
    alertDispatch({
      type: PERMISION_TABLE_MODAL,
      payload: { open: false },
    });
  };
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(1px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={permissionTableModal.open}
    >
      <div className="modalCreateTarget">
        <div className="permission-modal react-dropdown-div">
          <div className="permission-modal--container">
            <div
              className="permission-modal--container--close-btn"
              onClick={closeModal}
            >
              <img src={images.close} alt="" />
            </div>
              <>
                <div className="permission-table-wrapper">
                <table className="permission-table">
                  <thead className="permission-table--head">
                    <tr className="permission-table--head--row">
                      <th className="permission-table--head--row--title">
                        Modül
                      </th>
                      <th className="permission-table--head--row--title">
                        Okuma
                      </th>
                      <th className="permission-table--head--row--title">
                        Oluşturma
                      </th>
                      <th className="permission-table--head--row--title">
                        Güncelleme
                      </th>
                      <th className="permission-table--head--row--title">
                        Silme
                      </th>
                    </tr>
                  </thead>
                  <tbody className="permission-table--body">
                    {permissions &&
                      permissions.map((p) => (
                        <tr
                          key={p.module}
                          className="permission-table--body--row"
                        >
                          <td className="permission-table--body--row--content">
                            {p.displayName}
                          </td>
                          {p.permisisons?.map((permission, i) => {
                            return (
                              <td
                                key={i}
                                className="permission-table--body--row--content"
                              >
                                <CheckBox
                                  checked={permission.isChecked}
                                  handleChange={handleCheckboxChanged}
                                  module={p.module}
                                  name={permission.name}
                                />
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
                <IconButton
                  imgRight="OK"
                  title="KAYDET"
                  image={images.rightArrow}
                  maxWidth="130px"
                  background="#1392E5"
                  color="white"
                  borderColor="#1392E5"
                  buttonClick={handleSubmit}
                />
              </>
            
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default PermissionsTableModal;
