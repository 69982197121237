import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from "../../api/index";
import {
  useAlertDispatch,
  useAlertState,
} from "../../context/alert/alertContext";
import Button from "../button/Button";
import { MAIL_MANAGEMENT_MODAL } from "../../context/alert/actions";
import TableLoader from "../loader/TableLoader";
import MailManagementModal from "../modal/userManagementPageModals/MailManagementModal";
const MailManagementDataTable = () => {
  const [mailsData, setMailsData] = useState();
  const [roles, setRoles] = useState();
  const { mailManagementModal } = useAlertState();
  const alertDispatch = useAlertDispatch();

  useEffect(() => {
    const getRoles = async () => {
      try {
        const data = await api.getAllRoles();
        setRoles(data.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchData = async () => {
      try {
        const datas = await api.getAllMailTemplates();
        setMailsData(datas.data?.result);
        //setDataFetch(datas.data?.result);
      } catch (error) {
        //console.log("error", error.message);
      }
    };
    if (mailManagementModal.open == false) {
        fetchData();
        getRoles();
    }
  }, [mailManagementModal]);

  const getRoleById = (roleId) => {
    const splittedId = roleId.split(',')
    let rolesMap = []
    for (let index = 0; index < splittedId.length; index++) {
      const element = splittedId[index];
      const role = roles?.find(r => r.id == element)
      rolesMap.push(role)
    }
    return rolesMap?.map(el => el?.name).join(',')
  }

  const columns = [
    {
      name: "Mail Tipi",
      selector: (row) => row.displayName,
      sortable: true,
      width:'38rem',
      cell:d => <span key={d.displayName}>{d.displayName}</span>
    },
    {
      name: "TO",
      selector: (row) => row.to,
      sortable: true,
      cell: d => <span key={d.to}>{getRoleById(d.to)}</span>
    },
    {
      name: "CC",
      selector: (row) => row.cc,
      sortable: true,
      cell: d => <span key={d.cc}>{getRoleById(d.cc)}</span>
    },
    {
      name: "",
      selector: (row) => row.genres,
      //sortable: true,
      cell: (d) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            handleClick={() =>
              alertDispatch({
                type: MAIL_MANAGEMENT_MODAL,
                payload: { open: true, id: d.id },
              })
            }
            title="Detaylar"
            maxWidth="130px"
            height="35px"
          />
        </div>
      ),
    },
  ];

  let data = [];
  mailsData?.map((res) =>
    data.push({
      id: res.id,
      displayName: res.displayName,
      to: res.to.toLowerCase(),
      cc: res.cc.toLowerCase(),
    })
  );
  const tableData = {
    columns,
    data,
  };
  return (
    <div>
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          //defaultSortAsc={false}
          pagination
          highlightOnHover
          fixedHeader={true}
          noDataComponent={<TableLoader />}
          //fixedHeaderScrollHeight={'388px'}
        />
      </DataTableExtensions>

      {mailManagementModal.open && <MailManagementModal setMailsData={setMailsData} roles={roles} />}
    </div>
  );
};

export default MailManagementDataTable;
