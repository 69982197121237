import moment from 'moment';
import React from 'react'
import Accordion from './Accordion';

   const OpenBox = ({log}) => {
      
      return (
        <div key={log.id} className='wrapper-openBox'>
              <div className="wrapper-openBox-row">
                <div>{moment(log.createdDateAt).format('LLLL')}</div>
                <div>{log.message}</div>
              </div>
        </div>
      )
   }

   const LgDropdown = ({icon,title,allLogs}) => (
    <div className="wrapper">
      <Accordion icon={icon} title={title}>
        {/* {description} */}
        {
          allLogs.length > 0 ? allLogs.sort((a,b) => moment(b.createdDateAt) - moment(a.createdDateAt)).map(log => (
            <OpenBox log={log}/>
          ))
          :<div>Henuz log bulunmuyor</div>
        }
      </Accordion>
    </div>
  );
  
  export default LgDropdown
  