import React, {useEffect, useState} from 'react'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from '../../api/index'
import {useAlertDispatch, useAlertState} from '../../context/alert/alertContext';
import {useCompanyDispatch, useCompanyState} from '../../context/company/companyContext';
import IconButton from '../button/IconButton';
import CreateUserModal from '../modal/userManagementPageModals/CreateUserModal';
import images from '../../images/images';
import Button from '../button/Button';
import UpdateUserModal from '../modal/userManagementPageModals/UpdateUserModal';
import { ALL_USER_DATA } from '../../context/company/actions';
import {USER_DETAIL_MANAGEMENT_MODAL, USER_MANAGEMENT_MODAL} from '../../context/alert/actions';
import TableLoader from '../loader/TableLoader';
import moment from 'moment';
const UserManagementDataTable = () => {
  const [companies,setCompanies] = useState()
    const {userManagementModal,userDetailManagementModal} = useAlertState()
      const alertDispatch = useAlertDispatch();
      const {allUsersData} = useCompanyState()
      const companyDispatch = useCompanyDispatch()
      const getCompanyName= (companyCode) => {
        const company = companies?.find(c => c.code == companyCode)
        if (company) {
          return company.name
        }else {
          return companyCode
        }
      }

      
      //delete post


      useEffect(()=>{ 

        const fetchData = async () => {
          try {
          
          const datas = await api.getAllUsers();
          companyDispatch({type:ALL_USER_DATA,payload:datas.data?.result})
          //setDataFetch(datas.data?.result);
          } catch (error) {
          
          //console.log("error", error.message);
          
          }
          
          }
          api.fetchData().then(res => {
            setCompanies(res?.data?.result)
          })
          fetchData()
          
          },[])

        const columns = [
          {
            name: "Adı",
            selector: row => row.firstName,
            sortable: true
          },
          {
            name: "Soyadı",
            selector: row => row.lastName,
            sortable: true,
          },
          {
            name: "E Posta",
            selector: row => row.ePosta,
            sortable: true,
          },
          {
            name: "Şirket Adı",
            selector: row => row.companyName,
            sortable: true,
          },
          {
            name: "Son Giriş Tarihi",
            selector: row => row.lastLoginDateAt,
            sortable: true,
            cell: d => <span>{d.lastLoginDateAt == '0001-01-01T00:00:00' ? '-' : moment(d.lastLoginDateAt).format('LLLL')}</span>
          },
          {
            name: "",
            selector: row => row.genres,
            //sortable: true,
            cell: d => <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}><Button handleClick={()=> alertDispatch({type: USER_DETAIL_MANAGEMENT_MODAL, payload: {open: true, user:{...d}} })} title="Detaylar" maxWidth='130px' height='35px'/></div>
          }
      ]
      
        let data = [];
        allUsersData?.map(res => (
          data.push(
            {
              id: res.id,
              firstName:res.firstName,
              lastName:res.lastName,
              userName:res.userName,
              lastLoginDateAt:res.lastLoginDateAt,
              ePosta:res.ePosta,
              roles:res.roles,
              genres:res.generalManagerId,
              company:res.company,
              companyName:getCompanyName(res.company),
              status:res.status,
            }
            )
        ))

      const tableData = {
        columns,
        data
      };
  return (
    <div>
        <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          //defaultSortAsc={false}
          pagination
          highlightOnHover
          fixedHeader={true}
          noDataComponent= {<TableLoader/>} 
          //fixedHeaderScrollHeight={'388px'}
        />
      </DataTableExtensions> 

      {/* deneme işlemleri sonrası alttaki yapılar aktif hale getirilecek */}
       <div 
      onClick={()=>alertDispatch({type:USER_MANAGEMENT_MODAL, payload: {open:true}})}
      className='createRowBtn'>
      <IconButton maxWidth='253px' title="YENİ KULLANICI OLUŞTUR" image={images.plusBlue} color='#1392E5' borderColor='#1392E5'/>
      </div> 
      {userManagementModal.open && <CreateUserModal title="Kullanıcı Ekle" companies={companies}/>} 
      {userDetailManagementModal.open && <UpdateUserModal title= "Kullanıcı Düzenle"/>}
       {/* deneme inputları */}

    </div>
  )
}

export default UserManagementDataTable