import React from 'react'
import Backdrop from "@mui/material/Backdrop";
import { useCompanyState } from '../../../context/company/companyContext';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';
import {useLoader} from '../../../hooks/useLoader';
import useToastify from '../../../hooks/useToastify';

const CeoGmCompanyApproveModal = ({modal,setModal,companyDetail,setCompanyDetail}) => {
    const {dataFetchCompanyCardDetail, dataFetchCompanyCardBonusDetail} = useCompanyState()
    const setLoader = useLoader()
    const { errorToast } = useToastify()
  
      const approveSend = async (id) => {
        setLoader(true)
        try {
          const updateStatus = await api.updatePostDataCardCompany(JSON.stringify({
            "id": companyDetail.id,
            "companyId": companyDetail.companyId,
            "periodId": companyDetail.periodId,
            "status": companyDetail?.status,
            "processStatus": id == 4 ? companyDetail.permission.apprvalNextStatus : companyDetail.permission.forwardNextAuthorityStatus,
            "subTotalScore":companyDetail.subTotalScore,
            "totalScore": companyDetail.totalScore,
            "ceoTotalScore": companyDetail?.ceoTotalScore,        
            "occuredBonusScore": companyDetail?.occuredBonusScore, 
          })) 
          //companyDispatch({type: COMPANY_CARD_UPDATE_DATA, payload: updateStatus.data.result})
          setCompanyDetail(updateStatus.data.result)
          let msg
          if(id == 1){
              msg = "Ceo onayına gönderme işlemi başarılı" 
          } else if(id == 2) {
              msg = "Başkan onayına gönderme işlemi başarılı" 
          }  else if(id == 3) {
              msg = "Şirket onayına gönderme işlemi başarılı" 
          } else if(id == 4) {
              msg = "Dönem sonu süreci başlatma işlemi başarılı"
          }
          setLoader(false)
          toast.success(msg)
      } catch (error) {
        setLoader(false)
      }
      }
      const checkOkay = () => {
        let isOkay = true
        if(companyDetail.processStatus == "ActualGmDataEntry" || companyDetail.processStatus == "ActualGmDataEntryFinish") {
          dataFetchCompanyCardDetail.forEach(item => {
            const {occured, actualValue, actualDefinition, actualPeriod} = item
              if ( (occured != 0 && !occured) || (actualValue != 0 && !actualValue) || (actualDefinition != 0 && !actualDefinition) || (actualPeriod != 0 && !actualPeriod) ) {
                isOkay = false
              }
            })
          dataFetchCompanyCardBonusDetail.forEach(item => {
            const {occured, actualValue, actualDefinition, actualPeriod} = item
              if ((occured != 0 && !occured) || (actualValue != 0 && !actualValue) || (actualDefinition != 0 && !actualDefinition) || (actualPeriod != 0 && !actualPeriod)) {
                isOkay = false
              }
            })
        }
       
        return isOkay
      }
      const checked = async(nextStatu) => {
        if (!checkOkay()) {
          errorToast("Lütfen tüm alanları doldurduğunuzdan emin olun")
        }
        else {
          setLoader(true)
        try {
          const updateStatus = await api.updatePostDataCardCompany(JSON.stringify({
            "id": companyDetail.id,
            "companyId": companyDetail.companyId,
            "periodId": companyDetail.periodId,
            "status": companyDetail.status,
            "processStatus": nextStatu ? nextStatu : companyDetail.permission.apprvalNextStatus,
            "subTotalScore":companyDetail.subTotalScore,
            "totalScore": companyDetail.totalScore,
            "ceoTotalScore": companyDetail?.ceoTotalScore,        
            "occuredBonusScore": companyDetail?.occuredBonusScore, 
            
          })) 
          setLoader(false)
          //companyDispatch({type: COMPANY_CARD_UPDATE_DATA, payload: updateStatus.data.result})
          setCompanyDetail(updateStatus.data.result)
          toast.success("Onay istegi Başarılı")
      } catch (error) {
        setLoader(false)
          // console.log("error", error.response.data.errors)
      }
        }
      } 
  

    const workFuncSend = () => {
       if(modal.workFunc.name === 'approveSend'){
        approveSend(modal.workFunc.status)
       }else if(modal.workFunc.name === 'checked'){
        modal.workFunc?.nextStatu ? checked(modal.workFunc?.nextStatu) : modal.workFunc.prevStatu ? checked(modal.workFunc.prevStatu) : checked()
       }
       setModal({...modal, open: false})
    }
  return (
    <>
    <Backdrop
      sx={{
        backdropFilter: "blur(3px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={modal.open}
    >
     <div className='ceoPagesModal'>
         <h1>İşlemi Gerçekleştirmek İstediğinize Emin misiniz ?</h1>
         <div className='ceoPagesModal-yes-no'>
            <div onClick={workFuncSend}>Evet</div>
            <div onClick={()=>setModal({...modal , open : false})}>Hayır</div>
         </div>
     </div>
    </Backdrop>
  </>
  )
}

export default CeoGmCompanyApproveModal