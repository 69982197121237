import {Backdrop} from '@mui/material'
import {useCompanyState} from '../../../context/company/companyContext'
import CheckBox from '../../checkbox/Checkbox'
import * as api from '../../../api/index' 
import {useEffect, useState} from 'react'
import {useRequest} from '../../../hooks/useRequest'
import {useLoader} from '../../../hooks/useLoader'
import {toast} from 'react-toastify'
const TargetApprovalModal = ({approvalModal, setApprovalModal, setCompanyDetail, companyDetail}) => {
  const {dataFetchCompanyCardDetail, dataFetchCompanyCardBonusDetail} = useCompanyState()
  const [companyTargets, setCompanyTargets] = useState([])
  const [companyBonusTargets, setCompanyBonusTargets] = useState([])
  const setLoader = useLoader()
  const request = useRequest()

  useEffect(() => {
    setCompanyTargets(dataFetchCompanyCardDetail)
  },[dataFetchCompanyCardDetail])
  useEffect(() => {
    setCompanyBonusTargets(dataFetchCompanyCardBonusDetail)
  },[dataFetchCompanyCardBonusDetail])

  const approveSend = async () => {
    setLoader(true)
    try {
      const updateStatus = await api.updatePostDataCardCompany(JSON.stringify({
        "id": companyDetail.id,
        "companyId": companyDetail.companyId,
        "periodId": companyDetail.periodId,
        "status": companyDetail?.status,
        "processStatus": companyDetail.permission.apprvalNextStatus,
        "subTotalScore":companyDetail.subTotalScore,
        "totalScore": companyDetail.totalScore,
        "ceoTotalScore": companyDetail?.ceoTotalScore,        
        "occuredBonusScore": companyDetail?.occuredBonusScore, 
      })) 
      //companyDispatch({type: COMPANY_CARD_UPDATE_DATA, payload: updateStatus.data.result})
      setCompanyDetail(updateStatus.data.result)
      setLoader(false)
      toast.success("Dönem sonu süreci başlatma işlemi başarılı")
      setApprovalModal({open:false})
  } catch (error) {
    setLoader(false)
    setApprovalModal({open:false})
  }
  }

 const handleCheckboxCompanyCard = async(e,item) => {
    setLoader(true)
    try {
        request('/CompanyCardDetail/UpdateSubPeriod',{
            method:'POST',
            params:{
                id:item.id,
                isSubPeriod:!item.isSubPeriod
            }
        }).then(res => {
            setLoader(false)
            setCompanyTargets(prev => ([...prev.map(p => {
                if(p.id == res.data.result.id) {
                    return {...res.data.result}
                }else {
                    return p
                }
            })]))
        }).catch(error => {
            setLoader(false)
        })

    } catch (error) {
       
    }
 }
 const handleCheckboxCompanyCardBonus = async(e,item) => {
  setLoader(true)
    try {
        request('/CompanyCardBonusDetail/UpdateSubPeriod',{
            method:'POST',
            params:{
                id:item.id,
                isSubPeriod:!item.isSubPeriod
            }
        }).then(res => {
            setLoader(false)
            setCompanyBonusTargets(prev => ([...prev.map(p => {
                if(p.id == res.data.result.id) {
                    return {...res.data.result}
                }else {
                    return p
                }
            })]))
        }).catch(error => {
            setLoader(false)
        })

    } catch (error) {
    
    }
 }
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(3px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={approvalModal?.open}
    >
        <div className="modalCreateTarget">
            <div className="approval-target">
                <div className="approval-target--title">
                    Şirkete veri girişi için gönderilecek hedefleri seçiniz
                </div>
                <div className="approval-target--list">
                    <div className='approval-target--list--title'>Hedefler</div>
                    {
                        companyTargets && companyTargets.map(item => (
                            <CheckBox key={item.id} disabled={!item.targetKpi.isPossibleCompany} checked={item.isSubPeriod} handleChange={(e) => handleCheckboxCompanyCard(e,item)} label={item.targetKpi.name}/>
                        ))
                    }
                    <div className='approval-target--list--title'>Bonus Hedefler</div>
                    {
                        companyBonusTargets && companyBonusTargets.map(item => (
                            <CheckBox key={item.id} disabled={!item.targetKpi.isPossibleCompany} checked={item.isSubPeriod} handleChange={(e) => handleCheckboxCompanyCardBonus(e,item)} label={item.targetKpi.name}/>
                        ))
                    }
                </div>
                <div className='ceoPagesModal-yes-no'>
                    <div onClick={approveSend}>Onayla</div>
                    <div onClick={() => setApprovalModal({open:false,detail:{}})}>Vazgeç</div>
                </div>
            </div>
        </div>
    </Backdrop>
  )
}

export default TargetApprovalModal