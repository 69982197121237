import React, { useEffect, useState } from 'react'
import { useAlertDispatch, useAlertState } from '../../context/alert/alertContext'
import images from '../../images/images'
import ChangedButton from '../button/ChangedButton'
import IconButton from '../button/IconButton'
import { RiDeleteBinLine } from 'react-icons/ri';
import Button from 'react-bootstrap/Button'
import TargetBonusModal from '../modal/targetPageModals/TargetBonusModal'
import {CREATE_TARGET_BONUS_MODAL, DELETE_TARGET_BONUS_MODAL} from '../../context/alert/actions'
import { toast } from 'react-toastify';
import { useCompanyDispatch, useCompanyState } from '../../context/company/companyContext'
import * as api from '../../api/index'
import moment from 'moment'
import TargetDeleteBonusModal from '../modal/targetPageModals/TargetDeleteBonusModal'
import  CreatableSelect  from 'react-select/creatable';
import currencyFormatter from '../../utils/InputFormatter'
import {Tooltip} from '@mui/material'
import {FcInfo} from 'react-icons/fc'
import { getUserInfo } from '../../utils/Auth'
import roles from '../../constants/Roles'
import SelectStyle, {SelectStyleBonus} from '../../constants/SelectStyle'
import useToastify from '../../hooks/useToastify'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const TargetBonus = ({id,companyDetail,setCompanyDetail, updatedTotal, setUpdatedTotal}) => {
   const {createTargetBonusModal,deleteTargetBonusModal} = useAlertState()
   const alertDispatch = useAlertDispatch();
   const companyDispatch = useCompanyDispatch()
   const {dataFetchCompanyCardBonusDetail} = useCompanyState()
   const [dataFetchAim,setDataFetchAim] = useState([])
   const [dataFetchUnit,setDataFetchUnit] = useState([])
   const [random,setRandom] = useState(0)
   const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
   const [detailId, setDetailId] = useState();
   const {errorToast} = useToastify()
   const [value, setValue] = useState('')
   const [firstVal,setFirstVal]= useState();
   const [updatedRow, setUpdatedRow] = useState(null)
   const [rowIndex, setRowIndex] = useState(null)
   const [newRowUnit, setNewRowUnit] = useState({
      name: "",
      description: "deneme",
      hyperionField: "deneme",
    });
  const ceoTotalBtnVisible = companyDetail?.processStatus == "ActualCeoWaitingForApproval" || companyDetail?.processStatus == "ActualCeoComended" || companyDetail?.processStatus == "ActualComplated"

   const buttonClick = () => {
      alertDispatch({type: CREATE_TARGET_BONUS_MODAL, payload: {open: true} })
   }
   const openDeleteDialog = (bonus) => {
    alertDispatch({type: DELETE_TARGET_BONUS_MODAL, payload: {open: true} })
    setDetailId(bonus.id);

 }

 useEffect(()=>{
  const companyCreateBonusCardDetail = async () => {
   try {
     const detailData = await api.fetchDataCardCompanyBonusDetail(id) 
     companyDispatch({type:"COMPANYCARDFETCHDATABONUSDETAIL", payload: detailData?.data?.result}) 

   } catch (error) {
     
   }
  }
  
  companyCreateBonusCardDetail()
 },[companyDetail,random])

useEffect(() => {
  setUpdatedTotal({...companyDetail})
},[companyDetail,random])
 const IsShown = (index) =>{
  setSelectedItemIndex(index)
}


 useEffect(()=>{ 
  const fetchDataAim = async () => {
    try {   
    const datas = await api.fetchDataAim(); 
    setDataFetchAim(datas.data?.result)
    } catch (error) {   
     
    } 
    }

    const fetchDataUnit = async () => {
      try {   
      const datas = await api.fetchDataUnit(); 
      setDataFetchUnit(datas.data?.result)
      } catch (error) {   
       
        } 
      }
    
      fetchDataUnit()
      fetchDataAim()
    
    },[random])

let defaultOptions =  []
let defaultOptions2 =  []
let defaultOptions3 = [
  {value:8,label:"8. Ay",id:'actualPeriod'},
  {value:9,label:"9. Ay",id:'actualPeriod'},
  {value:10,label:"10. Ay",id:'actualPeriod'},
 ]

dataFetchAim?.forEach(data => {
   return defaultOptions.push(
      {value:data?.id, label:data?.name, id:'targerKPIId' }
  )
})
dataFetchUnit?.forEach(data => {
  return defaultOptions2.push(
      {value:data?.id, label:data?.name, id:'unitId' }
  )
})


    const onChange = (index, e, text) => {
      e.target.value = text ? e.target.value : e.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1')
      // let indexRow = e.nativeEvent.data
      //setValue(e.target.value)
      // setUpdatedRow({...updatedRow, [ e.target.name] : Number(e.target.value)})
      if(!updatedRow) return
      setRowIndex(index)
      setUpdatedRow(prev => ({
         ...prev,
         [index]: {
            ...prev[index],
            [e.target.name]:  e.target.value == '' ? null : text ? e.target.value : Number(e.target.value)
         }
      }))
   }
   const onChangeCeoTotalScore = (e) => {
    // let indexRow = e.nativeEvent.data
    //setValue(e.target.value)
    // setUpdatedRow({...updatedRow, [ e.target.name] : Number(e.target.value)})
    setUpdatedTotal(prev => ({
       ...prev,
          [e.target.name]:  e.target.value == '' ? null : e.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1') 
    }))
 }
   const onCeoScoreInputBlur = () => {
     if(updatedTotal){
       try {
         api.updatePostDataCardCompany(JSON.stringify(updatedTotal)).then(res => {
          if (res.status == 200) {
            setUpdatedTotal(prev => ({
              ...prev,         
                  ["id"]: res?.data?.result?.id,
                  ["companyId"]: res?.data?.result?.companyId,
                  ["periodId"]: res?.data?.result?.periodId,
                  ["status"]: res?.data?.result?.status,
                  ["processStatus"]: res?.data?.result?.processStatus,
                  ["subTotalScore"]: res?.data?.result?.subTotalScore,
                  ["totalScore"]: res?.data?.result?.totalScore,     
                  ["ceoTotalScore"]: res?.data?.result?.ceoTotalScore,        
                  ["occuredBonusScore"]: res?.data?.result?.occuredBonusScore,        
           }))
           setCompanyDetail(prev => ({
            ...prev,
            subTotalScore:res?.data?.result?.subTotalScore,
            totalScore:res?.data?.result?.totalScore,
            ceoTotalScore:res?.data?.result?.ceoTotalScore,
            occuredBonusScore:res?.data?.result?.occuredBonusScore,
           }))
           toast.success('Update İşlemi Başarılı')
          }else if (res.response.status == 400) {
            setUpdatedTotal(prev => ({
              ...prev,         
                  ["ceoTotalScore"]:companyDetail?.ceoTotalScore,        
           }))
          }
         }) 
        } catch (error) {
         
        }
   }
  }

  useEffect(() => {
    if(dataFetchCompanyCardBonusDetail?.length > 0){
    setUpdatedRow(dataFetchCompanyCardBonusDetail);  
    }
  }, [dataFetchCompanyCardBonusDetail])
    

   const focusUpdate = (index, e) => {
    if(!updatedRow) return
    setRowIndex(index)
    setFirstVal(e.target.value)
    setUpdatedRow(prev => ({
       ...prev,
       [index]: {
          ...prev[index],
          [e.target.name]: e.target.value
       }
    })) 
 }
 
 const onBlur = async(index, e,str) => {
 try {
    if(str){
      const updatePost = await api.updatePostDataCardCompanyBonusDetail(JSON.stringify(updatedRow[rowIndex])) 
      companyDispatch({type: "COMPANYCARDUPDATEDATABONUSDETAIL", payload: updatePost.data.result})
      setUpdatedTotal(prev => ({...prev,ceoTotalScore: updatePost.data.result.companyCard.ceoTotalScore,occuredBonusScore: updatePost.data.result.companyCard.occuredBonusScore,totalScore:updatePost.data.result.companyCard.totalScore}))
      toast.success('Update İşlemi Başarılı')
    }else {
      if(firstVal !== e.target.value){
        const updatePost = await api.updatePostDataCardCompanyBonusDetail(JSON.stringify(updatedRow[rowIndex])) 
        companyDispatch({type: "COMPANYCARDUPDATEDATABONUSDETAIL", payload: updatePost.data.result})
        if (updatePost.data.result.companyCard.totalScore <= 150) {
          setUpdatedTotal(prev => ({...prev,ceoTotalScore: updatePost.data.result.companyCard.ceoTotalScore,occuredBonusScore: updatePost.data.result.companyCard.occuredBonusScore,totalScore:updatePost.data.result.companyCard.totalScore}))
          toast.success('Update İşlemi Başarılı')
        }else {
          errorToast('Toplam Şirket Puanı 150yi geçmemelidir')
        }
       }
    }
 } catch (error) {
  Object.keys(dataFetchCompanyCardBonusDetail[rowIndex]).forEach(key => {
    if (key == e.target.name) {
      e.target.value = dataFetchCompanyCardBonusDetail[rowIndex][key]
    }
  })
  errorToast(error.response.data.errors.ScoreValue[0])
 }
}


   const handleChangeDropdown = (index,e) => {
       if(!updatedRow) return
    setRowIndex(index)
    setUpdatedRow(prev => ({
       ...prev,
       [index]: {
          ...prev[index],
          [e.id]: e.value
       }
    }))
  };

   let newUnitFunc = async () => {
      try {
        let newData = await api.createPostDataUnit(JSON.stringify(newRowUnit));
        let insertedUnit = {...newData?.data?.result};
        setDataFetchUnit([...dataFetchUnit, insertedUnit]);
        //dataFetchUnit.push({ ...insertedUnit  })
     
        let normalizedUnit = {id: insertedUnit?.id, name: insertedUnit?.name, hyperionField: insertedUnit?.hyperionField, description: insertedUnit?.description}
        let unitsExceptNew = dataFetchUnit.filter(
          (f) => f.name !== newRowUnit?.name
        );
        unitsExceptNew.push(normalizedUnit)
        //setDataFetchUnitt(prev=> ([...prev,]))
        //await companyDispatch({ type: UNIT_POST_DATA, payload: normalizedUnit });
        setUpdatedRow({ ...updatedRow, unitId: normalizedUnit.id });
        //newUnitGet()
      } catch (error) {
        console.log(error.message);
      }
    };
  
  
    const newUnitId = (e) => {
      if (e.key === "Enter") {
        //setCreateTargetCard({...createTargetCard, [e.id] : searchfilter[0]?.id})
        newUnitFunc();
      }
    };

    const onDragEnd = async(result) => {
      if (!result.destination) {
        return;
      }
      console.log(result, "result");
      // Sürüklenen öğeyi hedefe taşı
      const items = Array.from(dataFetchCompanyCardBonusDetail);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem); 
      console.log(items, "items");
      const newData = {
         companyCardId: id,
         cards: items.map((item,i) => ({id: item?.id, order: i}))
      }
      const updatePost = await api.updatePostDataCardCompanyDetailOrderBonus(JSON.stringify(newData));
      setRandom(Math.floor(Math.random() * 1000)) 
      // Sıralama verilerini güncelle
      // setState veya başka bir yöntem kullanarak verileri güncelle
    };

  return (
    <div className='target-bonus'>
  
       <div className='target-bonus-header'>
           <div className='h1'>Bonus Hedefler</div>
                  {
                    companyDetail?.permission?.isPeriodBegin && companyDetail?.permission?.isEdit ?
                    <IconButton buttonClick = {buttonClick} title="BONUS HEDEF OLUŞTUR" image={images.bluePlus} maxWidth="250px" color='#1392E5' borderColor='#1392E5'/>
                    : ''
                 }
       </div> 
       {dataFetchCompanyCardBonusDetail?.length > 0 ? <div>
        <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
        <table ref={provided.innerRef} style={companyDetail?.permission?.isMidtermShow  ? {width:'100%'} : {width:'unset'}}>
          <tr>
            <th></th>
            <th>Hedef Adı</th>
            {(companyDetail?.processStatus != "ActualGmDataEntry" && companyDetail?.processStatus != "ActualGmDataEntryFinish") && <th>Puan Değeri</th>}
            <th style={companyDetail?.permission?.isPeriodBegin ? {display:'none'} : {}}>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'Yıl Sonu Tahmini':'Gerçekleşen'}</th> 
            <th style={companyDetail?.permission?.isPeriodBegin || !companyDetail?.permission?.isMidtermOutFiledShow  ? {display:'none'} : {}}>Bonus Puanı</th> 
            <th style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Değer':'En Güncel Değer'}</th>
            <th style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Dönem':'En Güncel Dönem (Ay)'}</th>
            <th style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>Açıklama</th>
            {(getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) && <th>Sil</th>}
            
          </tr>
          {dataFetchCompanyCardBonusDetail?.map((bonus, index) => {
          const isOccuredEdit = companyDetail?.permission?.isOccureds?.find(o => o.targetId == bonus?.targerKPIId)?.isOccured
          return (
            <Draggable key={index} draggableId={index.toString()} index={index}>
         {(provided) => (
            <tr key={index}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            >
            <td>
               {
                  bonus.isSubPeriod && (getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID)&& <Tooltip title={<>
                    Şirket Tarafından Girilen Güncel Değer: {bonus.actualValue} <br /> <br />
                    Şirket Tarafından Girilen Güncel Dönem: {bonus.actualPeriod} <br /> <br />
                    Şirket Tarafından Girilen Açıklama: {bonus.actualDefinition} <br />
                 </>} placement='top-start'>
                    <span><FcInfo size={23} /></span>
               </Tooltip>
               }
            </td>
         <Tooltip disableHoverListener={bonus.targetKpi.name.length < 70} placement="top" title={bonus.targetKpi.name}>

          <td className='target-bonus-table-column p-0' >
               <CreatableSelect
                      styles={SelectStyleBonus}
                      formatCreateLabel={(inputText) => `"${inputText}" enter'a basınız...`}
                      className='target-targetKPI-area'
                      placeholder={bonus?.targetKpi?.name ? bonus?.targetKpi?.name :"Seçiniz"}
                      //value={newRowUnit.name}
                      name="name"
                      onBlur={(e)=>onBlur(index,e,'1')}
                      onFocus={(e)=>focusUpdate(index,e,'1')}
                      defaultValue={bonus?.targetKpi?.name}
                      isDisabled={!companyDetail?.permission?.isEdit }
                      //isValidNewOption
                      // onCreateOption={handleChangeDropdown}
                      //value={newRowUnit?.name}
                      onChange={e=>handleChangeDropdown(index,e)}
                      //onKeyDown={newUnitId}
                      options={defaultOptions}
                      //isDisabled={disabled ? disabled : false}
                    />
                    </td>
                    </Tooltip>
                    {
                      (companyDetail?.processStatus != "ActualGmDataEntry" && companyDetail?.processStatus != "ActualGmDataEntryFinish") && <td className='target-bonus-table-column p-0'> <ChangedButton disabled={!companyDetail?.permission?.isEdit} name="scoreValue" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(bonus?.scoreValue)}/></td>
                    }
         
         <td className='p-0' style={companyDetail?.permission?.isPeriodBegin ? {display:'none'} : {}}> <ChangedButton  disabled={!isOccuredEdit} name="occured" onChange={(e)=>onChange(index,e,'str')} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} value={currencyFormatter(bonus?.occured)} readValue={bonus?.occured}/></td> 
         <td className='p-0' style={companyDetail?.permission?.isPeriodBegin || !companyDetail?.permission?.isMidtermOutFiledShow ? {display:'none'} : {}}> <ChangedButton  disabled={!isOccuredEdit} name="score" onChange={(e)=>onChange(index,e)} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={bonus?.score} readValue={bonus?.score}/></td> 

         <td className='p-0' style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isMidtermUpdate} name="actualValue" onChange={(e)=>onChange(index,e,'str')} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={currencyFormatter(bonus?.actualValue)} /></td>
         <td className='p-0' style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}>
         <CreatableSelect
                      className='target-targetKPI-area'
                      placeholder={bonus?.actualPeriod ? `${bonus?.actualPeriod}. Ay` :"Seçiniz"}
                      styles={SelectStyle}
                      //value={newRowUnit.name}
                      name="actualPeriod"
                      onBlur={(e)=>onBlur(index,e,"1")}
                      defaultValue={bonus?.actualPeriod}
                      isDisabled={!companyDetail?.permission?.isMidtermUpdate || !companyDetail?.permission?.isMidtermShow }
                      onFocus={(e)=>focusUpdate(index,e,"1")}
                      //isValidNewOption
                      // onCreateOption={handleChangeDropdown}
                      //value={newRowUnit?.name}
                      onChange={e=>handleChangeDropdown(index,e)}
                      //onKeyDown={newUnitId}
                      options={defaultOptions3}
                      //isDisabled={disabled ? disabled : false}
                    />
         </td> 
         <Tooltip disableHoverListener={(!bonus.actualDefinition || bonus.actualDefinition?.length < 18) && companyDetail?.permission?.isMidtermShow} placement="top" title={bonus?.actualDefinition}>
         <td className='p-0' style={!companyDetail?.permission?.isMidtermShow ? {display:'none'} : {}}> <ChangedButton disabled={!companyDetail?.permission?.isMidtermUpdate} name="actualDefinition" onChange={(e)=>onChange(index,e,"text")} onBlur={(e)=>onBlur(index,e)} focusUpdate={(e)=>focusUpdate(index,e)} setValue={setValue} value={bonus?.actualDefinition} /></td>
         </Tooltip>

          {
           (getUserInfo()?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || getUserInfo()?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) && <td className='target-bonus-table-column'> <div className='block'  onMouseLeave={() => setSelectedItemIndex(-1)} >
                <div className='normal'> <RiDeleteBinLine onMouseOver={() => IsShown(index)} />        
                    </div>
                    {selectedItemIndex == index ?  (            
                    <div className='hover' >
                      <Button  key={index} className="buttonStyle" onClick={(e) =>openDeleteDialog(bonus)}>İlgili Kaydı Sil</Button> 
                    </div>
                    ): null}
                </div>
            </td>
          }

          
        </tr>
        )}
        </Draggable>
          )
         
        })        
          
          }        
        </table> 
              )}
              </Droppable>
            </DragDropContext> 
              </div> : <div className='target-bonus-noRow'>-</div>}
              <div className='total'> 
              {
           (!companyDetail?.permission?.isPeriodBegin && companyDetail?.permission?.isTotalPuansFields) && <div className='totalButton'>
            <p className='pStyle'>Gerçekleşen Toplam Bonus Puanı: 
            </p>  
             <div className='changed-button'>
             <input disabled={true} name="occuredBonusScore" type='text' value={currencyFormatter(updatedTotal?.occuredBonusScore)} />  
             </div>
            </div>
          }
          {
            (!companyDetail?.permission?.isPeriodBegin && companyDetail?.permission?.isTotalPuansFields) && ceoTotalBtnVisible && <div className='totalButton'>
            <p className='pStyle'>Ceo Düzeltme Puanı: 
            </p>  
             <div className='changed-button'>
                 <input name="ceoTotalScore" type='text' disabled={(companyDetail?.processStatus != 'ActualCeoWaitingForApproval' && companyDetail?.processStatus != 'ActualCeoComended' )|| !companyDetail?.permission?.isEdit} onBlur={onCeoScoreInputBlur} onChange={(e)=>onChangeCeoTotalScore(e)} defaultValue={currencyFormatter(updatedTotal?.ceoTotalScore)} value={updatedTotal?.ceoTotalScore}/>  
             </div>
            </div>
          }
          {
            (!companyDetail?.permission?.isPeriodBegin && companyDetail?.permission?.isTotalPuansFields)  &&  <div className='totalButton'>
             <p className='pStyle'>Şirket Puanı: 
             </p>  
             <div className='changed-button'>
             <input disabled={true} name="totalScore" type='text' value={currencyFormatter(updatedTotal?.totalScore)} placeholder={"Sonuçlanmadı"}/> 
             </div>
             </div>
          }
       
        
          </div>
                {createTargetBonusModal?.open && <TargetBonusModal id={id}/>}
                {deleteTargetBonusModal?.open && <TargetDeleteBonusModal  id={detailId}/>}
              </div>
            )
          }



export default TargetBonus