import React, { useEffect, useState, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import * as api from "../../../api/index";
import { toast } from "react-toastify";
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import IconButton from "./../../button/IconButton";
import images from "../../../images/images";
import {
  useCompanyDispatch,
  useCompanyState,
} from "../../../context/company/companyContext";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import {useLoader} from "../../../hooks/useLoader";
import {useRequest} from "../../../hooks/useRequest";
import {EXCEL_IMPORT_MODAL} from "../../../context/alert/actions";

const TargetModal = ({ id,companyDetail }) => {
  const [isHovered, setIsHovered] = useState(false)
  const styles = {
    width: "70px",
    height: "38px",
    outline: "none",
    border: "1px solid lightgray",
    borderRadius: "4px",
    textAlign: "center",
  };
  const { createTargetModal,excelImportModal } = useAlertState();
  const setLoader = useLoader()
  const request = useRequest()
  const alertDispatch = useAlertDispatch();
  const [dataFetchAim, setDataFetchAim] = useState([]);
  const [dataFetchUnitt, setDataFetchUnitt] = useState([]);
  const companyDispatch = useCompanyDispatch();
  const [selectedTarget, setSelectedTarget] = useState(
    {value: '',
    label: '',
    id: "targerKPIId",
    type:''})
  const [createTargetCard, setCreateTargetCard] = useState({
    companyCardId: id,
    targerKPIId: "",
    unitId: "",
    weight: null,
    lastYearTarget: null,
    target: null,
    underLimitMultiplier: null,
    upperLimitMultiplier: null,
    underLimitValue: null,
    upperLimitValue: null,
    occured: null,
    score: null,
    weightedScore: null,
  });
  const [newRowUnit, setNewRowUnit] = useState({
    name: "",
    description: "deneme",
    hyperionField: "deneme",
  });

  const closeModal = () => {
    alertDispatch({ type: "CREATETARGETMODAL", payload: false });
  };



  useEffect(() => {
    const fetchDataAim = async () => {
      try {
        const datas = await api.fetchDataAim();
        //companyDispatch({type: AIM_FETCH_DATA, payload: datas.data?.result});
        setDataFetchAim(datas.data?.result);
      } catch (error) {
   
      }
    };

    const fetchDataUnit = async () => {
      try {
        const datas = await api.fetchDataUnit();
         //companyDispatch({type: UNIT_FETCH_DATA, payload: datas.data?.result})
        setDataFetchUnitt(datas.data?.result);
      } catch (error) {
     
      }
    };

    fetchDataUnit();
    fetchDataAim();
  }, []);

  let defaultOptions = [];
  let defaultOptions2 = [];

  useEffect(() => {
    dataFetchAim?.forEach((data) => {
      return defaultOptions.push({
        value: data?.id,
        label: data?.name,
        id: "targerKPIId",
        type:data?.type
      });
    });
    dataFetchUnitt?.forEach((data) => {
      return defaultOptions2.push({
        value: data?.id,
        label: data?.name,
        id: "unitId",
      });
    });
  }, [defaultOptions, defaultOptions2]);

  useEffect(() => {
    if(excelImportModal.dataCreateTarget) {
      setCreateTargetCard({
        ...createTargetCard,
        'target': Number(excelImportModal.dataCreateTarget),
      });
      alertDispatch({type:EXCEL_IMPORT_MODAL, payload: {...excelImportModal,dataCreateTarget:null}})
    }
  },[excelImportModal.dataCreateTarget])
  const handleChangeDropdown = (e) => {
    if (e.id == 'targerKPIId') {
      setSelectedTarget(e)
    }

      setCreateTargetCard({ ...createTargetCard, [e.id]: e.value });
  };
  const handleChangeText = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1')

    setCreateTargetCard({
      ...createTargetCard,
      [e.target.name]: e.target.value ?  Number(e.target.value) : null,
    });
  };
  const handleChangeCalculatorText = (e) => {
    const {name,value} = e.target
    if (name == 'target') {
        setCreateTargetCard({
          ...createTargetCard,
          underLimitValue:createTargetCard.underLimitMultiplier ? Number(value) * createTargetCard.underLimitMultiplier : createTargetCard.upperLimitValue,
          upperLimitValue:createTargetCard.upperLimitMultiplier ? Number(value) * createTargetCard.upperLimitMultiplier : createTargetCard.underLimitValue,
          [name]:value ? Number(value) : null
        })
    }
    if (name == 'underLimitMultiplier') {
      setCreateTargetCard({
        ...createTargetCard,
        underLimitValue:createTargetCard.target * Number(value),
        [name]:value ? Number(value) : null
      })
    }
    if (name == 'upperLimitMultiplier') {
      setCreateTargetCard({
        ...createTargetCard,
        upperLimitValue:createTargetCard.target * Number(value),
        [name]:value ? Number(value) : null
      })
    }
  }
  const newCreateTargetCard = async () => {
    setLoader(true)
    try {
      await request('/CompanyCardDetail/Create',{method:'POST',data:createTargetCard}).then(res => {
        if (res) {
          companyDispatch({ 
            type: "COMPANYCARDPOSTDATADETAIL",
            payload: res.data.result,
          });
          setLoader(false)
          //setDataFetch([...dataFetch, newData.data.result])
          alertDispatch({ type: "CREATETARGETMODAL", payload: false });
          toast.success("Ekleme İşleminiz Başarılı !!!");
        }
    })
    } catch (error) {
      setLoader(false)
    }
  };
  let newUnitFunc = async () => {
    try {
      let newData = await api.createPostDataUnit(JSON.stringify(newRowUnit));
      let insertedUnit = {...newData?.data?.result};
      setDataFetchUnitt([...dataFetchUnitt, insertedUnit]);
      //dataFetchUnit.push({ ...insertedUnit  })
   
      let normalizedUnit = {id: insertedUnit?.id, name: insertedUnit?.name, hyperionField: insertedUnit?.hyperionField, description: insertedUnit?.description}
      let unitsExceptNew = dataFetchUnitt.filter(
        (f) => f.name !== newRowUnit?.name
      );
      unitsExceptNew.push(normalizedUnit)
      setCreateTargetCard({ ...createTargetCard, unitId: normalizedUnit.id });
      //newUnitGet()
    } catch (error) {
      console.log(error.message);
    }
  };
  const getHyperionData = () => {

     const data = {
         companyId: companyDetail?.companyId,
         periodId: companyDetail?.periodId,
         targetKPIId: createTargetCard.targerKPIId,
         unitId: createTargetCard.unitId
     }
     request('/Hyperion/HyperionValue',{method:'POST',data:JSON.stringify(data)}).then(res=>{
         if (res) {
            setCreateTargetCard({...createTargetCard,target:res.data})
         }
     })
}

  const newUnitId = (e) => {
    if (e.key === "Enter") {
      //setCreateTargetCard({...createTargetCard, [e.id] : searchfilter[0]?.id})
      newUnitFunc();
    }
  };

useEffect(() => {
  if (selectedTarget.type == 'Manuel') {
    setIsHovered(true)
  }
},[selectedTarget.type])
  const getTargetInput = () => {
    switch (selectedTarget.type) {
      case 'Manuel':
        return <input
        placeholder="Hedef"
        style={{ ...styles }}
        type="text"
        name="target"
        onChange={handleChangeCalculatorText}
      />
      case 'Excel':
        return <input
        placeholder="Hedef"
        style={{ ...styles }}
        type="text"
        name="target"
        onChange={handleChangeCalculatorText}
      />
      case 'Hyperion':
        return <button onMouseLeave={() => setIsHovered(false)} onClick={getHyperionData} className='changed-button--button-sm' >Verileri Al</button>
      default:
        return <input
        placeholder="Hedef"
        readOnly={!selectedTarget.value}
        style={{ ...styles }}
        type="text"
        name="target"
        onChange={handleChangeCalculatorText}
      />
    }
  }

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(3px)",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={createTargetModal.open}
      >
        <div className="modalCreateTarget">
          <div className="modalCreateTarget-box">
            <div className="modalCreateTarget-box-close">
            <div className="modalCreateTarget-box-close-closeBtn" onClick={closeModal}>
              <img src={images.close} alt="" />
            </div>
              <div className="modalCreateTarget-box-close-h1">
                Hedef Olustur
              </div>
            </div>
            <div className="modalCreateTarget-box-kpi">
              <div
                style={{ marginBottom: "10px" }}
                className="modalCreateTarget-box-kpi-head"
              >
                Hedef Adı *
              </div>
              <div>
                <div>
                  <Select
                    onChange={handleChangeDropdown}
                    name="type"
                    placeholder={"Seçiniz"}
                    options={defaultOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="modalCreateTarget-box-kpi-sub"
            >
              <table style={{ width: "100%" }}>
                <tr>
                  <th>Birim*</th>
                  <th>Ağırlık</th>
                  <th>Hedef</th>
                  <th style={{ width: "85px" }}>Alt Limit Çarpanı</th>
                  <th style={{ width: "85px" }}>Üst Limit Çarpanı</th>
                  <th>0 Puan</th>
                  <th>150 Puan</th>
                </tr>
                <tr>
                  <td>
                    <CreatableSelect
                      formatCreateLabel={(inputText) => `"${inputText}" enter'a basınız...`}
                      placeholder={"Seçiniz"}
                      isSearchable={true}
                      //value={newRowUnit.name}
                      onInputChange={(e) =>
                        setNewRowUnit({ ...newRowUnit, name: e })
                      }
                      //isValidNewOption
                      // onCreateOption={handleChangeDropdown}
                      //value={newRowUnit?.name}
                      onChange={handleChangeDropdown}
                      onKeyDown={newUnitId}
                      options={defaultOptions2}
                      //isDisabled={disabled ? disabled : false}
                    />
                  </td>
                  <td>
                    <input
                      placeholder="Ağırlık"
                      style={{ ...styles }}
                      type="text"
                      name="weight"
                      onChange={handleChangeText}
                    />
                  </td>
                  <td>
                    {
                     isHovered ? getTargetInput() : <input
                     placeholder="Hedef"
                     readOnly={true}
                     value={createTargetCard.target}
                     style={{ ...styles }}
                     type="text"
                     name="target"
                     onChange={handleChangeCalculatorText}
                     onMouseEnter = {() => setIsHovered(true)}
                    />
                    }
                  </td>
                  <td>
                    <input
                      placeholder="ALÇ"
                      style={{ ...styles }}
                      type="text"
                      name="underLimitMultiplier"
                      onChange={handleChangeCalculatorText}
                    />
                  </td>
                  <td>
                    <input
                      placeholder="ÜLÇ"
                      style={{ ...styles }}
                      type="text"
                      name="upperLimitMultiplier"
                      onChange={handleChangeCalculatorText}
                    />
                  </td>
                  <td>
                    <input
                      placeholder="Puan"
                      style={{ ...styles }}
                      type="text"
                      value={createTargetCard.underLimitValue}
                      name="underLimitValue"
                      onChange={handleChangeText}
                    />
                  </td>
                  <td>
                    <input
                      placeholder="Puan"
                      style={{ ...styles }}
                      type="text"
                      name="upperLimitValue"
                      value={createTargetCard.upperLimitValue}
                      onChange={handleChangeText}
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div
              onClick={newCreateTargetCard}
              className="modalCreateTarget-box-btn"
            >
              <IconButton
                imgRight="OK"
                title="OLUŞTUR"
                image={images.rightArrow}
                maxWidth="130px"
                background="#1392E5"
                color="white"
                borderColor="#1392E5"
              />
            </div>
          </div>
          
        </div>
      </Backdrop>
    </>
  );
};

export default TargetModal;
