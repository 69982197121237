import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import * as api from "../../../api/index";
import { toast } from "react-toastify";

import Select from "react-select";
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import { useCompanyDispatch } from "../../../context/company/companyContext";
import images from "../../../images/images";
import IconButton from "./../../button/IconButton";
import { AIM_UPDATE_DATA } from "../../../context/company/actions";
import { DELETE_POST, DETAIL_MODAL } from "../../../context/alert/actions";
import { RiDeleteBinLine, RiEditBoxLine } from "react-icons/ri";
import { useLoader } from "../../../hooks/useLoader";
import { useRequest } from "../../../hooks/useRequest";
import useToastify from "../../../hooks/useToastify";

const AimDeleteUpdateModal = ({ title, whichModal }) => {
  const { detailModal } = useAlertState();
  const alertDispatch = useAlertDispatch();
  const companyDispatch = useCompanyDispatch();
  const setLoader = useLoader();
  const request = useRequest();
  const { errorToast } = useToastify();
  const [detail, setDetail] = useState(null);
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    isPossibleCompany: "",
    type: "",
  });
  const [newRow, setNewRow] = useState({
    status: 0,
    name: "",
    description: "",
    isPossibleCompany: false,
    type: "",
    isCurrencyCycleRequired: false,
    sourceCurrency: "",
    targetCurrency: "",
    targetOfCurrencyCycleCompanies: [],
    dialogField: ''
  });
  const [companies, setCompanies] = useState([]);

  let defaultOptions = [
    { value: true, label: "Evet", id: "isPossibleCompany" },
    { value: false, label: "Hayır", id: "isPossibleCompany" },
  ];
  let defaultOptions2 = [
    { value: 0, label: "Manuel", id: "type" },
    { value: 1, label: "Hyperion", id: "type" },
    { value: 2, label: "Excel", id: "type" },
  ];
  const defaultOptions3 = [
    { value: true, label: "Evet", id: "isCurrencyCycleRequired" },
    { value: false, label: "Hayır", id: "isCurrencyCycleRequired" },
  ];
  const defaultCompanies = newRow.targetOfCurrencyCycleCompanies.map(c => ({label:c.company?.name,value:c.companyId,id:c.companyId}))
  useEffect(() => {
    const getDetail = async () => {
      try {
        if (detailModal.id) {
          const data = await api.detailGetDataAim(detailModal.id);
          setDetail(data?.data?.result);
          setNewRow(data?.data?.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetail();
  }, [detailModal]);

  useEffect(() => {
    if (newRow.isCurrencyCycleRequired) {
      api.fetchData().then((res) => {
        console.log(res, "resssss");
        setCompanies(
          res.data.result.map((t) => ({ label: t.name, value: t.id, id:t.id }))
        );
      });
    }
  }, [newRow.isCurrencyCycleRequired]);

  const deleteFunc = async () => {
    setLoader(true);
    try {
      await api.detailDeleteDataAim(detailModal.id);
      alertDispatch({ type: DETAIL_MODAL, payload: false });
      alertDispatch({ type: DELETE_POST, payload: { id: detailModal.id } });
      toast.success("Silme İşlemi Başarılı");
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const updateFunc = async () => {
    if (
      (newRow?.type == 1 || newRow?.type == "Hyperion") &&
      !newRow.hyperionField
    ) {
      errorToast("Hyperion alanı boş bırakılmamalıdır.");
    } else if (
        (newRow?.type == 1 || newRow?.type == "Hyperion") &&
        newRow.isCurrencyCycleRequired &&
      (!newRow.sourceCurrency || !newRow.targetCurrency)
    ) {
      errorToast("Kaynak ve Hedef Kur alanları boş bırakılmamalıdır.");
    } else {
      setLoader(true);
      await request("/TargetKPI/Update", { method: "POST", data: detail }).then(
        (res) => {
          if (res) {
            companyDispatch({
              type: AIM_UPDATE_DATA,
              payload: res.data.result,
            });
            alertDispatch({ type: DETAIL_MODAL, payload: false });
            toast.success("Update İşlemi Başarılı");
          }
        }
      ).finally(() => setLoader(false));
    }
  };

  const closeModal = () => {
    alertDispatch({ type: DETAIL_MODAL, payload: false });
  };
  const handleChangeDropdown = (e) => {
    setNewRow((prev) => ({ ...prev, [e.id]: e.value }));
    setDetail({ ...detail, [e.id]: e.value });
    setErrors((prev) => ({ ...prev, [e.id]: "" }));
  };
  const handleNewRowChange = (e) => {
    setNewRow({ ...newRow, [e.target.name]: e.target.value });
    setDetail({ ...detail, [e.target.name]: e.target.value });
    setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
  };
  const handleChangeCompanies = (e) => {
    setNewRow((prev) => ({
      ...prev,
      targetOfCurrencyCycleCompanies: e.map((t) => ({ companyId: t.value })),
    }));
    setDetail((prev) => ({
      ...prev,
      targetOfCurrencyCycleCompanies: e.map((t) => ({ companyId: t.value })),
    }));
  };
  const validateForm = () => {
    let isValid = true;
    if (!newRow.description) {
      setErrors((prev) => ({ ...prev, description: "Bu alan zorunludur" }));
      isValid = false;
    }
    if (!newRow.hyperionField) {
      setErrors((prev) => ({ ...prev, hyperionField: "Bu alan zorunludur" }));
      isValid = false;
    }
    if (!newRow.name) {
      setErrors((prev) => ({ ...prev, name: "Bu alan zorunludur" }));
      isValid = false;
    }
    if (!newRow.type) {
      setErrors((prev) => ({ ...prev, type: "Lütfen bir seçim yapın" }));
      isValid = false;
    }
    if (!newRow.isPossibleCompany) {
      setErrors((prev) => ({
        ...prev,
        isPossibleCompany: "Lütfen bir seçim yapın",
      }));
      isValid = false;
    }
    return isValid;
  };
  return (
    <>
      {detail && (
        <Backdrop
          sx={{
            backdropFilter: "blur(1px)",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={detailModal.open}
        >
          <div className="modalCreateTarget">
            <div className="company-modal">
              <div className="company-modal--container">
                <div className="company-modal--container--head">
                  <div className="company-modal--container--input-area">
                    <div className="company-modal--container--input-area--label">
                      Hedef Adı *
                    </div>
                    <input
                      type="text"
                      placeholder="Hedef adı girin"
                      className="company-modal--container--input-area--input"
                      defaultValue={detail?.name}
                      name="name"
                      onChange={handleNewRowChange}
                      value={newRow.name}
                    />
                    {errors.name && (
                      <span className="company-modal--container--error">
                        {errors.name}
                      </span>
                    )}
                  </div>
                </div>

              

                <div className="company-modal--container--body">
                  <div className="company-modal--container--body--half-width">
                    <div className="company-modal--container--input-area">
                      <div className="company-modal--container--input-area--label">
                        Hedef Açıklaması
                      </div>
                      <input
                        type="text"
                        placeholder="Hedef açıklaması girin"
                        className="company-modal--container--input-area--input"
                        defaultValue={detail?.description}
                        name="description"
                        onChange={handleNewRowChange}
                        value={newRow.description}
                      />
                      {errors.description && (
                        <span className="company-modal--container--error">
                          {errors.description}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="company-modal--container--body--half-width">
                    <div className="company-modal--container--input-area">
                      <div className="company-modal--container--input-area--label">
                        Hedef Tipi *
                      </div>
                      <div className="react-dropdown-div">
                        <Select
                          defaultValue={{
                            label: detail.type && detail.type,
                            value: detail.type && detail.type,
                            id: "type",
                          }}
                          onChange={handleChangeDropdown}
                          name="type"
                          placeholder={
                            String(detail?.type)
                              ? defaultOptions2.find(
                                  (def) => def.value == detail?.type
                                )?.label
                              : "Seçiniz"
                          }
                          options={defaultOptions2}
                        />
                      </div>
                      {errors.type && (
                        <span className="company-modal--container--error">
                          {errors.type}
                        </span>
                      )}
                    </div>

                    <div className="company-modal--container--input-area">
                      <div className="company-modal--container--input-area--label">
                        Şirket Girişi Mümkün Mü? *
                      </div>
                      <div className="react-dropdown-div">
                        <Select
                          onChange={handleChangeDropdown}
                          placeholder={
                            detail?.isPossibleCompany == true ? "Evet" : "Hayır"
                          }
                          name="isPossibleCompany"
                          options={defaultOptions}
                        />
                      </div>
                      {errors.isPossibleCompany && (
                        <span className="company-modal--container--error">
                          {errors.isPossibleCompany}
                        </span>
                      )}
                    </div>
                  </div>
                  {(newRow?.type == 1 || newRow?.type == "Hyperion") && (
                    <div className="company-modal--container--body--half-width">
                      <div className="company-modal--container--input-area">
                        <div className="company-modal--container--input-area--label">
                          Hyperion Alanı *
                        </div>
                        <input
                          type="text"
                          placeholder="Hedef adı girin"
                          className="company-modal--container--input-area--input"
                          defaultValue={detail?.hyperionField}
                          name="hyperionField"
                          onChange={handleNewRowChange}
                          value={newRow.hyperionField}
                        />
                        {errors.hyperionField && (
                          <span className="company-modal--container--error">
                            {errors.hyperionField}
                          </span>
                        )}
                      </div>
                      <div className="company-modal--container--input-area">
                        <div className="company-modal--container--input-area--label">
                          Kur Çevrimi Gerekli Mi? *
                        </div>
                        <div className="react-dropdown-div">
                          <Select
                            onChange={handleChangeDropdown}
                            placeholder={"Seçiniz"}
                            name="isCurrencyCycleRequired"
                            defaultValue={{
                              value: detail.isCurrencyCycleRequired,
                              label: detail.isCurrencyCycleRequired
                                ? "Evet"
                                : "Hayır",
                              id: "isCurrencyCycleRequired",
                            }}
                            options={defaultOptions3}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newRow.isCurrencyCycleRequired && (
                    <div className="company-modal--container--body--half-width">
                      <div className="company-modal--container--input-area">
                        <div className="company-modal--container--input-area--label">
                          Kaynak Kur *
                        </div>
                        <input
                          type="text"
                          placeholder="Hyperion alanı girin"
                          className="company-modal--container--input-area--input"
                          defaultValue={detail?.sourceCurrency}
                          name="sourceCurrency"
                          onChange={handleNewRowChange}
                          value={newRow.sourceCurrency}
                        />
                      </div>
                      <div className="company-modal--container--input-area">
                        <div className="company-modal--container--input-area--label">
                          Hedef Kur *
                        </div>
                        <input
                          type="text"
                          placeholder="Hyperion alanı girin"
                          className="company-modal--container--input-area--input"
                          defaultValue={detail?.targetCurrency}
                          name="targetCurrency"
                          onChange={handleNewRowChange}
                          value={newRow.targetCurrency}
                        />
                      </div>
                    </div>
                  )}
                  {newRow.isCurrencyCycleRequired && (
                    <div className="company-modal--container--head-100">
                      <div className="company-modal--container--input-area">
                        <div className="company-modal--container--input-area--label">
                          Şirketler
                        </div>
                        <div className="react-dropdown-div w-100">
                          <Select
                            isMulti
                            defaultValue={defaultCompanies ? [...defaultCompanies] : null}
                            onChange={handleChangeCompanies}
                            options={companies}
                            placeholder="Seçiniz"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="company-modal--container--footer">
                  <IconButton
                    icon={
                      <RiEditBoxLine
                        style={{ width: "20px" }}
                        color="#fff"
                        fill="#fff"
                      />
                    }
                    buttonClick={updateFunc}
                    imgRight="OK"
                    title="Güncelle"
                    maxWidth="130px"
                    background="#1392E5"
                    color="white"
                    borderColor="#1392E5"
                  />
                  <IconButton
                    icon={
                      <RiDeleteBinLine
                        style={{ width: "20px" }}
                        color="#fff"
                        fill="#fff"
                      />
                    }
                    buttonClick={deleteFunc}
                    imgRight="OK"
                    title="Sil"
                    maxWidth="130px"
                    background="#f9423a"
                    color="white"
                    borderColor="#f9423a"
                  />
                </div>
                <div
                  className="company-modal--container--close-btn"
                  onClick={closeModal}
                >
                  <img src={images.close} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default AimDeleteUpdateModal;
