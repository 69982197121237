import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { KullanimKlavuzu } from "../../resources/resources";
const SidebarButton = React.forwardRef(
  ({ icon, text, open, btnClick }, ref) => {
    const navigate = useNavigate();

    return (
      <div style={{ width: "100px" }} onClick={btnClick}>
        <div className="sidebar-button">
          <img src={icon} alt="" />
          <p style={{ fontSize: "12px" }}>{text}</p>
        </div>
        {open && (
          <div ref={ref} className="sidebar-openMenu">
            {open.map((opn, index) =>
              opn.link == "/resources/guide" ? (
                <Link
                  to={KullanimKlavuzu}
                  target="_blank"
                  className="sidebar-openMenu-sub"
                  key={index}
                >
                  <img src={opn?.icon} alt="" />
                  <span id={opn.link}>{opn?.displayName}</span>
                </Link>
              ) : (
                <div
                  onClick={() => navigate(opn?.link)}
                  className="sidebar-openMenu-sub"
                  key={index}
                >
                  <img src={opn?.icon} alt="" />
                  <span id={opn.link}>{opn?.displayName}</span>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }
);

export default SidebarButton;
