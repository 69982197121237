
//  let initialState = {
//     dataFetch:[],
//    }

import { initialState } from "../initialState"
import {
  ALL_USER_DATA,
  ALL_ROLES_DATA,
  COMPANY_FETCH_DATA,
  COMPANY_POST_DATA,
  COMPANY_DELETE_DATA,
  COMPANY_UPDATE_DATA,
  AIM_FETCH_DATA,
  AIM_POST_DATA,
  AIM_DELETE_DATA,
  AIM_UPDATE_DATA,
  UNIT_FETCH_DATA,
  UNIT_POST_DATA,
  UNIT_DELETE_DATA,
  UNIT_UPDATE_DATA,
  TERM_FETCH_DATA,
  TERM_POST_DATA,
  TERM_DELETE_DATA,
  TERM_UPDATE_DATA,
  GROUP_FETCH_DATA,
  GROUP_POST_DATA,
  GROUP_DELETE_DATA,
  GROUP_UPDATE_DATA,
  COMPANY_CARD_FETCH_DATA,
  COMPANY_CARD_POST_DATA,
  COMPANY_CARD_DELETE_DATA,
  COMPANY_CARD_UPDATE_DATA,
  PAGINATION_INFO,
  FILTER_OPTIONS,
  COMPANY_FETCH_DATA_DETAIL
} from "./actions";
  
   const companyReducer = (state = initialState, action) => {
       switch (action.type) {
           // USER CONTEXT API

           case ALL_USER_DATA:
               return {
                 //...state,
                 allUsersData: action.payload
               }  
            // ROLES CONTEXT API
            case ALL_ROLES_DATA:
               return {
                 //...state,
                 allRolesData: action.payload
               }  
           // COMPANY CONTEXT API AREA   
           
           case COMPANY_FETCH_DATA:
               return {
                 //...state,
                 dataFetch: action.payload
               }   
            case COMPANY_POST_DATA:
                return {
                  //...state,
                  dataFetch: [...state?.dataFetch, action.payload]
                } 
            case COMPANY_DELETE_DATA:
                return {
                  //...state,
                  dataFetch: state.dataFetch?.filter(data => data.id !== action.payload)
                } 
            case COMPANY_UPDATE_DATA:
                return {
                  //...state,
                  dataFetch: state.dataFetch?.map(data => data.id === action.payload.id ? action.payload : data)
                }   
             // AIM CONTEXT API AREA  
             
             case AIM_FETCH_DATA:
               return {
                 ...state,
                 dataFetchAim: action.payload
               }   
            case AIM_POST_DATA:
                return {
                  //...state,
                  dataFetchAim: [...state.dataFetchAim, action.payload]
                } 
            case AIM_DELETE_DATA:
                return {
                  //...state,
                  dataFetchAim: state.dataFetchAim?.filter(data => data.id !== action.payload)
                } 
            case AIM_UPDATE_DATA:
                return {
                  //...state,
                  dataFetchAim: state.dataFetchAim?.map(data => data.id === action.payload.id ? action.payload : data)
                } 


                // UNIT CONTEXT API AREA  
             
             case UNIT_FETCH_DATA:
              return {
                ...state,
                dataFetchUnit: action.payload
              }   
           case UNIT_POST_DATA:
               return {
                 ...state,
                 dataFetchUnit: [...state.dataFetchUnit, action.payload]
               } 
           case UNIT_DELETE_DATA:
               return {
                 //...state,
                 dataFetchUnit: state.dataFetchUnit?.filter(data => data.id !== action.payload)
               } 
           case UNIT_UPDATE_DATA:
               return {
                 //...state,
                 dataFetchUnit: state.dataFetchUnit?.map(data => data.id === action.payload.id ? action.payload : data)
               } 

               // TERM CONTEXT API AREA  
             
             case TERM_FETCH_DATA:
              return {
                //...state,
                dataFetchTerm: action.payload
              }   
           case TERM_POST_DATA:
               return {
                 //...state,
                 dataFetchTerm: [...state?.dataFetchTerm, action.payload]
               } 
           case TERM_DELETE_DATA:
               return {
                 //...state,
                 dataFetchTerm: state.dataFetchTerm?.filter(data => data.id !== action.payload)
               } 
           case TERM_UPDATE_DATA:
               return {
                 //...state,
                 dataFetchTerm: state.dataFetchTerm?.map(data => data.id === action.payload.id ? action.payload : data)
               } 

               // COMPANYGROUP CONTEXT API AREA  
             
             case GROUP_FETCH_DATA:
              return {
                //...state,
                dataFetchGroup: action.payload
              }   
           case GROUP_POST_DATA:
               return {
                 //...state,
                 dataFetchGroup: [...state?.dataFetchGroup, action.payload]
               } 
           case GROUP_DELETE_DATA:
               return {
                 //...state,
                 dataFetchGroup: state.dataFetchGroup?.filter(data => data.id !== action.payload)
               } 
           case GROUP_UPDATE_DATA:
               return {
                 //...state,
                 dataFetchGroup: state.dataFetchGroup?.map(data => data.id === action.payload.id ? action.payload : data)
               } 


               // COMPANY CARD CONTEXT API AREA  
             
             case COMPANY_CARD_FETCH_DATA:
              return {
                ...state,
                dataFetchCompanyCard: action.payload
              }   
           case COMPANY_CARD_POST_DATA:
               return {
                 ...state,
                 dataFetchCompanyCard: [...state.dataFetchCompanyCard, action.payload]
               } 
           case COMPANY_CARD_DELETE_DATA:
               return {
                 //...state,
                 dataFetchCompanyCard: state.dataFetchCompanyCard?.filter(data => data.id !== action.payload)
               } 
           case COMPANY_CARD_UPDATE_DATA:
               return {
                 //...state,
                 dataFetchCompanyCard: state.dataFetchCompanyCard?.map(data => data.id === action.payload.id ? action.payload : data)
               } 


               // COMPANY CARD DETAIL CONTEXT API AREA  
             
             case COMPANY_FETCH_DATA_DETAIL:
              return {
                ...state,
                dataFetchCompanyCardDetail: action.payload
              }   
           case 'COMPANYCARDPOSTDATADETAIL':
               return {
                 ...state,
                 dataFetchCompanyCardDetail: [...state.dataFetchCompanyCardDetail, action.payload]
               } 
           case 'COMPANYCARDDELETEDATADETAIL':
               return {
                 ...state,
                 dataFetchCompanyCardDetail: state.dataFetchCompanyCardDetail?.filter(data => data.id !== action.payload)
               } 
           case 'COMPANYCARDUPDATEDATADETAIL':
               return {
                 ...state,
                 dataFetchCompanyCardDetail: state.dataFetchCompanyCardDetail?.map(data => data.id === action.payload.id ? action.payload : data)
               } 


               // COMPANY BONUS CARD DETAIL CONTEXT API AREA  
             
             case 'COMPANYCARDFETCHDATABONUSDETAIL':
              return {
                ...state,
                dataFetchCompanyCardBonusDetail: action.payload
              }   
           case 'COMPANYCARDPOSTDATABONUSDETAIL':
               return {
                 ...state,
                 dataFetchCompanyCardBonusDetail: [...state.dataFetchCompanyCardBonusDetail, action.payload]
               } 
           case 'COMPANYCARDDELETEDATABONUSDETAIL':
               return {
                 ...state,
                 dataFetchCompanyCardBonusDetail: state.dataFetchCompanyCardBonusDetail?.filter(data => data.id !== action.payload)
               } 
           case 'COMPANYCARDUPDATEDATABONUSDETAIL':
               return {
                 ...state,
                 dataFetchCompanyCardBonusDetail: state.dataFetchCompanyCardBonusDetail?.map(data => data.id === action.payload.id ? action.payload : data)
               } 
            

          // filter options
          case FILTER_OPTIONS:
            return {
              ...state,
              filterOptions:action.payload
            }
          // pagination

            case PAGINATION_INFO:
              return {
                ...state,
                paginationInfo:action.payload
              }

              case "MESSAGE_TAKE":
                return {
                  ...state,
                  takeMessage:action.payload
                }

           default:
               return state


       }
   }

   
 
   export {initialState, companyReducer}