import {useState} from 'react'
import PageHeader from '../components/pageHeader/PageHeader'
import {getUserInfo} from '../utils/Auth'
import {useAlertDispatch, useAlertState} from '../context/alert/alertContext'
import {CFO_APPROVAL_MODAL} from '../context/alert/actions'
import CfoApprovalModal from '../components/modal/cfoApprovalModal/CfoApprovalModal'
const Home = () => {
  const [userInfo,setUserInfo] = useState()
  const { cfoApprovalModal } = useAlertState()
  const dt = new Date()
  const year = dt.getFullYear()
  const dispatch = useAlertDispatch()
  useState(() => {
    setTimeout(() => {
      const isFirstLogin = JSON.parse(localStorage.getItem('isFirstLogin'))
      setUserInfo(getUserInfo())
       if (getUserInfo()?.IsPopup == 'true' && isFirstLogin == true) {
         localStorage.setItem('isFirstLogin',false)
         dispatch({
           type:CFO_APPROVAL_MODAL,
           payload:{open:true}
         })
       }
    }, 100);
    }
  ,[])
  return (
    <div className='page-wrapper'>
     <PageHeader title="Ana Sayfa" />
     {cfoApprovalModal.open && <CfoApprovalModal/>}
    {
      userInfo && <div className="welcome-title-area">
      <h2 className='welcome-title-area--title'>Hoşgeldin <span className='welcome-title-area--title--red'>{userInfo.FirstName} {userInfo.LastName}</span></h2>
      <p className='welcome-title-area--text'>{year} şirket hedef kartı süreci başlamıştır. Sol menüyü kullanarak ilgili işlemlerinizi yapabilirsiniz.</p>
    </div>
    }
    {/* <div className="welcome-anouncement-boxes">
      <div className="welcome-anouncement-boxes--large">
        <h3 className='welcome-anouncement-boxes--title'>Loraipsum</h3>
      </div>
      <div className="welcome-anouncement-boxes--small">
        <h3 className='welcome-anouncement-boxes--title'>Duyurular</h3>
      </div>
    </div> */}
    </div>
  )
}

export default Home