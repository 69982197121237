import React, { useState } from 'react'

const Accordion = ({icon, title, children }) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <div className="accordion-wrapper">
        
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          >
          <div style={{fontSize:'18px'}}>
              {icon &&  <img src={icon} alt="" />}
             {title}
          </div>
         {
            isOpen && (
             <span className='accordion-title-mid'>
              <div className='accordion-title-afterText'>Tarih</div>
              <div className='accordion-title-afterText'>Durum Mesajı</div>
            </span>
            )
          }
          
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };

export default Accordion