const ALL_USER_DATA = "ALLUSERSDATA"
const ALL_ROLES_DATA = "ALLROLESDATA"
const COMPANY_FETCH_DATA = "COMPANYFETCHDATA"
const COMPANY_POST_DATA = "COMPANYPOSTDATA"
const COMPANY_DELETE_DATA = "COMPANYDELETEDATA"
const COMPANY_UPDATE_DATA = "COMPANYUPDATEDATA"
const AIM_FETCH_DATA = "AIMFETCHDATA"
const AIM_POST_DATA = "AIMPOSTDATA"
const AIM_DELETE_DATA = "AIMDELETEDATA"
const AIM_UPDATE_DATA = "AIMUPDATEDATA"
const UNIT_FETCH_DATA = "UNITFETCHDATA"
const UNIT_POST_DATA = "UNITPOSTDATA"
const UNIT_DELETE_DATA = "UNITDELETEDATA"
const UNIT_UPDATE_DATA = "UNITUPDATEDATA"
const TERM_FETCH_DATA = "TERMFETCHDATA"
const TERM_POST_DATA = "TERMPOSTDATA"
const TERM_DELETE_DATA = "TERMDELETEDATA"
const TERM_UPDATE_DATA = "TERMUPDATEDATA"
const GROUP_FETCH_DATA = "GROUPFETCHDATA"
const GROUP_POST_DATA = "GROUPPOSTDATA"
const GROUP_DELETE_DATA = "GROUPDELETEDATA"
const GROUP_UPDATE_DATA = "GROUPUPDATEDATA"
const COMPANY_CARD_FETCH_DATA = "COMPANYCARDFETCHDATA"
const COMPANY_CARD_POST_DATA = "COMPANYCARDPOSTDATA"
const COMPANY_CARD_DELETE_DATA = "COMPANYCARDDELETEDATA"
const COMPANY_CARD_UPDATE_DATA = "COMPANYCARDUPDATEDATA"
const PAGINATION_INFO = "PAGINATIONINFO"
const FILTER_OPTIONS = "FILTEROPTIONS"
const COMPANY_FETCH_DATA_DETAIL = "COMPANYCARDFETCHDATADETAIL"
export {
    ALL_USER_DATA,
    ALL_ROLES_DATA,
    COMPANY_FETCH_DATA,
    COMPANY_POST_DATA,
    COMPANY_DELETE_DATA,
    COMPANY_UPDATE_DATA,
    AIM_FETCH_DATA,
    AIM_POST_DATA,
    AIM_DELETE_DATA,
    AIM_UPDATE_DATA,
    UNIT_FETCH_DATA,
    UNIT_POST_DATA,
    UNIT_DELETE_DATA,
    UNIT_UPDATE_DATA,
    TERM_FETCH_DATA,
    TERM_POST_DATA,
    TERM_DELETE_DATA,
    TERM_UPDATE_DATA,
    GROUP_FETCH_DATA,
    GROUP_POST_DATA,
    GROUP_DELETE_DATA,
    GROUP_UPDATE_DATA,
    COMPANY_CARD_FETCH_DATA,
    COMPANY_CARD_POST_DATA,
    COMPANY_CARD_DELETE_DATA,
    COMPANY_CARD_UPDATE_DATA,
    PAGINATION_INFO,
    FILTER_OPTIONS,
    COMPANY_FETCH_DATA_DETAIL
}