import React from 'react'
import IconButton from '../components/button/IconButton';
import LgDropdown from '../components/dropdown/lgDropdown/LgDropdown'
import SmDropdown from '../components/dropdown/SmDropdown'
import TextSquare from '../components/textSquare/TextSquare';
import images from '../images/images';
import { ExportExcel } from '../utils/ExcelExport';
import ScreenSize from '../utils/ScreenSize';
import Button from '../components/button/Button';


import AutocompleteSelect from '../components/dropdown/AutocompleteSelect';
import ChangedButton from '../components/button/ChangedButton';
import ExcelImportModal from '../components/modal/ExcelImportModal';
import { useAlertState} from '../context/alert/alertContext';
import { useLoader } from '../hooks/useLoader'

const TestPage = () => {
    const {excelImportModal} = useAlertState()
    // excel export fonksiyonu icin deneme degerleri...
    const setLoader = useLoader()
    const handleLoader = () => {
      setLoader(true)
      setTimeout(() => {
        setLoader(false)
      },1500)
  
    }
  
    const screenSize = ScreenSize();
  
  return (
    <div>
     
        {/* dropdown denemeleri icin öylesine buraya kondu */}
        <Button title="Yüklemeyi Başlat" handleClick={handleLoader}/>
        <AutocompleteSelect maxWidth='230px'/>
        <AutocompleteSelect maxWidth='100px'/>
        <ChangedButton/>
        <SmDropdown/>
        <TextSquare title="Berkant" maxWidth="180px"/>
        <TextSquare title="Deneme İşi" maxWidth="130px"/>
        <TextSquare title="Deneme İşi" maxWidth="130px" image={images.home}/>
        <LgDropdown icon={images.home} title="dropdown trial" description="Lorem Ipsum pasajlarının birçok çeşitlemesi vardır. Ancak bunların büyük bir çoğunluğu mizah katılarak veya rastgele sözcükler eklenerek değiştirilmişlerdir. Eğer bir Lorem Ipsum pasajı kullanacaksanız, metin aralarına utandırıcı sözcükler gizlenmediğinden emin olmanız gerekir. "/>
        <LgDropdown icon={images.home} title="dropdown trial" description="Lorem Ipsum pasajlarının birçok çeşitlemesi vardır. Ancak bunların büyük bir çoğunluğu mizah katılarak veya rastgele sözcükler eklenerek değiştirilmişlerdir. Eğer bir Lorem Ipsum pasajı kullanacaksanız, metin aralarına utandırıcı sözcükler gizlenmediğinden emin olmanız gerekir. "/>
        <LgDropdown icon={images.home} title="dropdown trial" description="Lorem Ipsum pasajlarının birçok çeşitlemesi vardır. Ancak bunların büyük bir çoğunluğu mizah katılarak veya rastgele sözcükler eklenerek değiştirilmişlerdir. Eğer bir Lorem Ipsum pasajı kullanacaksanız, metin aralarına utandırıcı sözcükler gizlenmediğinden emin olmanız gerekir. "/>
           {screenSize}
         <Button title="HEDEFİ KAYDET"/>
         <ExcelImportModal/>
         <ExportExcel />
         {
          excelImportModal?.data && excelImportModal.data
         }
         <IconButton title="Kaydet" image={images.activeHome} color='#1392E5' borderColor='#1392E5'/>
         <IconButton title="Kaydet" image={images.activeHome} color='#1392E5' borderColor='#1392E5'/>
         <IconButton title="Kaydet" image={images.activeHome} color='#1392E5' borderColor='#1392E5'/>
         <IconButton title="Kaydet" image={images.activeHome} color='#1392E5' borderColor='#1392E5'/>
         <IconButton title="Kaydet" image={images.activeHome} color='#1392E5' borderColor='#1392E5'/>
         <IconButton title="Kaydet" image={images.activeHome} color='#1392E5' borderColor='#1392E5'/>
 
    </div>
  )
}

export default TestPage