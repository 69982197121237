import React,{ useEffect, useState } from "react";
import images from "../../../images/images";
import { onMessageListener } from "../../../api/firebase";
import {getUserInfo} from "../../../utils/Auth";
import * as api from '../../../api/index'
import moment from "moment";
import DefaultProgress from "../../CircleProgress/DefaultProgress";

const SubNotification = ({date,title,description,color,key}) => {
    return (
        <div key={key} style={{borderColor:color}} className='notification-menu-sub'>
           <p className='notification-menu-sub-date'>{date}</p>
           <p className='notification-menu-sub-title'>{title}</p>
           <p className='notification-menu-sub-description'>{description}</p>
        </div>
    )
}

 const NotificationMenu = () => {
    const token = JSON.parse(localStorage.getItem('auth'));
    const [notification, setNotification] = useState(false);
    const [notifications,setNotifications] = useState([])
    const [notificationsCount,setNotificationsCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [seeAllLoading,setSeeAllLoading] = useState(false)

    const user  = getUserInfo()
    useEffect(() => {
        if (user && token) {
            api.getCount(user.UserId).then(res => {
                setNotificationsCount(res?.data)
            })
        }
    },[])
    const handleClick = () => {
        setNotificationsCount(0)
        setNotification(!notification)
        if (user && token && notifications.length < 1) {
            setLoading(true)
            api.getAllNotifications({FilterUserId:user.UserId,SkipCount:0,MaxResultCount:20}).then(res => {
                setNotifications(res?.data?.result)
                setLoading(false)
            }).catch(error => {
                console.log(error.message)
                setLoading(false)
            })
        }
    }
    const handleSeeAll = () => {
        if (user) {
            setSeeAllLoading(true)
            api.getAllNotifications({FilterUserId:user.UserId}).then(res => {
                setNotifications([...notifications,...res?.data?.result?.slice(20)])
                setSeeAllLoading(false)
            }).catch(error => {
                console.log(error.message)
                setSeeAllLoading(false)
            })
        }
    }
    // notification listener
    onMessageListener().then(res => {
        setNotifications(prev => ([...prev,res.notification]))
      })
    return (
        <div className='notification-menu'>
            <div className="notification-menu-icon">
                <img className="notification-menu-icon-img" onClick={handleClick} src={images.kocimage} alt="" />
                <img onClick={handleClick} src={images.iconsBell} alt="" />
                <div style={!notificationsCount ? { display:'none'}:{}} className="notification-menu-icon-count" >{notificationsCount && notificationsCount}</div>
            </div>
            <div style={notification ? {display:'inline-block'} : {display:'none'}} className='drdown'>
                {
                   loading ? <DefaultProgress isLoading={loading}/> :
                   notifications.length > 0 ? notifications.map((n,i)=> (
                    <>
                    <SubNotification key={i} color={n.isNew ? 'green' : 'black'} date={moment(n.createdDateAt).format('LLLL')} title={n.notifyMessage}/>
                    <hr style={{border:'0.5px solid whitesmoke',marginRight:'5px'}}/>
                    </>
                )) 
                : <div>Henüz bir bildirim bulunamadı</div>
                }
                {
                    notifications.length == 20 && !seeAllLoading ? <button onClick={handleSeeAll} className="drdown-seeall">Tümünü Gör</button> :<DefaultProgress size={15} isLoading={seeAllLoading}/>
                }
            </div>
        </div>
    )
}

export default NotificationMenu