const defautlFormatterOptions = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.'
  }

  const currencyFormatter = (value, options) => {
    if (value == null) {
      return ''
    } else {
      if (isNaN(value)) {
        return value
      }else {
        value = Number(value)
        options = { ...defautlFormatterOptions, ...options }
        value = value.toFixed(options.significantDigits)

        const [currency, decimal] = value.split('.')
        return `${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
        )}${Number(decimal) ? options.decimalSeparator + decimal : ''}`
      }
    }
    
  }

  export default currencyFormatter