import React, { useEffect } from 'react'

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from '../../api/index'
import Button from '../button/Button';
import { useAlertDispatch, useAlertState } from '../../context/alert/alertContext';
import IconButton from '../button/IconButton';
import images from '../../images/images';
import { useCompanyDispatch, useCompanyState } from '../../context/company/companyContext';
import AimDeleteUpdateModal from './../modal/aimPageModals/AimDeleteUpdateModal';
import AimCreateModal from './../modal/aimPageModals/AimCreateModal';
import { AIM_FETCH_DATA, AIM_DELETE_DATA } from '../../context/company/actions';
import {CREATE_COMPANY_MODAL, DETAIL_MODAL} from '../../context/alert/actions';
import useToastify from '../../hooks/useToastify';
import {Tooltip} from '@mui/material'

     
      const DataTableComp = () => { 
      //const alertDispatch = useAlertDispatch();
      const {detailModal,createCompanyModal,deletePost} = useAlertState()
      const alertDispatch = useAlertDispatch();
      const {dataFetchAim} = useCompanyState()
      const companyDispatch = useCompanyDispatch()
      const {errorToast} = useToastify()
      //delete post
      useEffect(()=>{
        if(deletePost?.id){
          companyDispatch({type:AIM_DELETE_DATA, payload: deletePost.id})
          //setDataFetch(arr => arr.filter(arr => arr.id !== deletePost.id))
        }    
      },[detailModal.open])

      useEffect(()=>{ 
        const fetchData = async () => {

          try {
          
          const datas = await api.fetchDataAim();
          
          companyDispatch({type:AIM_FETCH_DATA, payload: datas.data?.result})
          
          //setDataFetch(datas.data?.result);
          
          if(datas.data.result.length < 1) {            
              errorToast("Kayıt bulunmamaktadır.")
            }

          } catch (error) {
          //console.log("error", error.message);
          }
          
          }
          
          fetchData()
          
          },[])

        const columns = [
          {
            name: "Hedef Adı",
            selector: row => row.title,
            sortable: true,
            cell: d => <><Tooltip disableHoverListener={d.title.length <= 70} title={d.title}>
              <span>{d.title.slice(0,70)}{d.title.length > 70 && '...' }</span>
              </Tooltip></>

          },
         
          {
            name: "Hedef Açıklaması",
            selector: row => row.year,
            sortable: true,
            cell: d => <>
            <Tooltip disableHoverListener={d.year.length <= 70} title={d.year}>
            <span>{d.year.slice(0,70)}{d.year.length > 70 && '...' }</span>
            </Tooltip></>

          },
          {
            name: "Hedef Tipi",
            selector: row => row.type,
            sortable: true,
            width:'10rem'
            //cell: d => <span>{d.genres.join(", ")}</span>
          },
          {
            name: "Şirket Girişi Mümkün Mü?*",
            selector: row => row.isPossibleCompany,
            sortable: true,
            width:'10rem',
            cell: d => <>{d.isPossibleCompany == true ? 'Evet': 'Hayır'}</>
          },
        
          {
            name: "",
            selector: row => row.genres,
            width:'10rem',
            //sortable: true,
            cell: d => <div onClick={()=> alertDispatch({type: DETAIL_MODAL, payload: {open: true, id : d.id} })} style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'end'}}><Button title="Detaylar" maxWidth='130px' height='35px'/></div>
          }
      ]

        let data = [];
        dataFetchAim?.map(res => (
          data.push(
            {
              id: res.id,
              hyperionField:res.hyperionField,
              title:res.name,
              year:res.description,
              type:res.type,
              isPossibleCompany:res.isPossibleCompany,
              genres:res.isPossibleCompany
            }
            )
        ))

      const tableData = {
        columns,
        data
      };
  
  return (
    <div>
        <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          //defaultSortAsc={false}
          pagination
          highlightOnHover
          fixedHeader={true}
          noDataComponent= {""} 
          //fixedHeaderScrollHeight={'388px'}
        />
      </DataTableExtensions>

      {/* deneme işlemleri sonrası alttaki yapılar aktif hale getirilecek */}
       <div 
      onClick={()=>alertDispatch({type: CREATE_COMPANY_MODAL, payload: {open:true}})}
      className='createRowBtn'>
      <IconButton maxWidth='253px' title="YENİ HEDEF OLUŞTUR" image={images.plusBlue} color='#1392E5' borderColor='#1392E5'/>
      </div> 
       {detailModal.open && <AimDeleteUpdateModal/>} 
      {createCompanyModal.open && <AimCreateModal/>} 
    </div>
  )
}

export default DataTableComp