import React from 'react'
import DataTableComp from '../components/aimManagementPage/DataTableComponent'

const AimManagementPage = () => {
  return (
    <div className='aim-management-page' >
        <DataTableComp/>    
    </div>
  )
}

export default AimManagementPage