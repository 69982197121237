const DETAIL_MODAL = "DETAILMODAL";
const DELETE_POST = "DELETEPOST";
const DELETE_COMPANY_CARD_MODAL = "DELETECOMPANYCARDMODAL"
const CREATE_TARGET_MODAL = "CREATETARGETMODAL";
const CREATE_TARGET_BONUS_MODAL = "CREATETARGETBONUSMODAL";
const DELETE_TARGET_MODAL = "DELETETARGETMODAL";
const DELETE_TARGET_BONUS_MODAL = "DELETETARGETBONUSMODAL";
const CREATE_COMPANY_MODAL = "CREATECOMPANYMODAL";
const USER_MANAGEMENT_MODAL = "USERMANAGEMENTMODAL";
const USER_DETAIL_MANAGEMENT_MODAL = "USERDETAILMANAGEMENTMODAL";
const ROLE_MANAGEMENT_MODAL = "ROLEMANAGEMENTMODAL";
const MAIL_MANAGEMENT_MODAL = "MAILMANAGEMENTMODAL"
const ROLE_DETAIL_MANAGEMENT_MODAL = "ROLEDETAILMANAGEMENTMODAL";
const PERMISION_TABLE_MODAL = "PERMISSIONTABLEMODAL";
const CFO_APPROVAL_MODAL = "CFOAPPROVALMODAL"
const EXCEL_IMPORT_MODAL = "EXCELIMPORTMODAL";
const ERROR_STATUS = "ERRORSTATUS";
const NOTIFICATION = "NOTIFICATION";
const LOADER = "LOADER";
export {
  DETAIL_MODAL,
  DELETE_POST,
  DELETE_COMPANY_CARD_MODAL,
  CREATE_TARGET_MODAL,
  CREATE_TARGET_BONUS_MODAL,
  DELETE_TARGET_MODAL,
  DELETE_TARGET_BONUS_MODAL,
  CREATE_COMPANY_MODAL,
  USER_MANAGEMENT_MODAL,
  USER_DETAIL_MANAGEMENT_MODAL,
  ROLE_MANAGEMENT_MODAL,
  ROLE_DETAIL_MANAGEMENT_MODAL,
  PERMISION_TABLE_MODAL,
  CFO_APPROVAL_MODAL,
  EXCEL_IMPORT_MODAL,
  ERROR_STATUS,
  NOTIFICATION,
  LOADER,
  MAIL_MANAGEMENT_MODAL
};
