import {Backdrop} from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import {CFO_APPROVAL_MODAL} from '../../../context/alert/actions'
import {useAlertDispatch, useAlertState} from '../../../context/alert/alertContext'
import images from '../../../images/images'

const CfoApprovalModal = () => {
const {cfoApprovalModal} = useAlertState()
const dispatch = useAlertDispatch()
const closeModal = () => {
  dispatch({
    type:CFO_APPROVAL_MODAL,
    payload:{open:false}
  })
}
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(3px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={cfoApprovalModal.open}
    >
     <div className='cfoapprovalModal'>
         <img className='cfoapprovalModal--close' onClick={closeModal} src={images.close} alt="Close Icon" />
         <h1 className='cfoapprovalModal--text'>Şirket yetkili ataması yapılmamıştır. Atama yapmak için <Link onClick={closeModal} className='cfoapprovalModal--text--link' to={'/company'}>tıklayınız</Link>.</h1>
         <h1 className='cfoapprovalModal--text'>Atama yapılmadığı durumda veri girişlerinin CFO veya GM tarafından yapılması gerekmektedir.</h1>
     </div>
    </Backdrop>
  )
}

export default CfoApprovalModal