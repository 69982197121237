import React from 'react'
import DataTableComp from '../components/companyCardManagementPage/DataTableComp'

const CompanyCardManagementPage = () => {
  return (
    <div className='company-card-management-page' >
        <DataTableComp/>    
    </div>
  )
}

export default CompanyCardManagementPage