import React, {useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import Select from 'react-select'
import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import { useCompanyDispatch } from '../../../context/company/companyContext';
import images from '../../../images/images';
import IconButton from './../../button/IconButton';
import {COMPANY_CARD_UPDATE_DATA} from '../../../context/company/actions';
import {DELETE_POST, DETAIL_MODAL} from '../../../context/alert/actions';
import {RiDeleteBinLine, RiEditBoxLine} from 'react-icons/ri';


const CompanyCardDeleteUpdateModal = () => {
    const {detailModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const companyDispatch = useCompanyDispatch()

    const [detail, setDetail] = useState(null)
    const [errors,setErrors] = useState({companyId:'',periodId:''})
    const [newCard, setNewCard] = useState({
        processStatus:0,status:0,companyId:'',periodId:''
       })
     
       const [newCardPlaceholder, setNewCardPlaceholder] = useState({
        companyId:'', periodId:''
       })
   

    const [dataFetchTerm, setDataFetchTerm] = useState([])
    const [dataFetch, setFetchTerm] = useState([])

              let defaultOptions =  []
              let defaultOptions2 =  []
           
             dataFetch?.forEach(data => {
               return defaultOptions.push({label : data?.name, value: data?.id, id:"companyId"})        
             })
              
            dataFetchTerm?.forEach(data => {
                return defaultOptions2.push({label : data?.name, value: data?.id, id:"periodId"})        
            })

            useEffect(()=>{ 
                const fetchDataCompany = async () => {
                  try { 
                  const dataCompany = await api.fetchData();
                  setFetchTerm(dataCompany.data?.result)
                  } catch (error) {   
                  //console.log("error", error.message); 
                  }    
                  }    
        
                  const fetchDataTerm = async () => {
                     try { 
                       const dataTerm = await api.fetchDataTerm();    
                       setDataFetchTerm(dataTerm.data?.result)
                     } catch (error) {   
                        //console.log("error", error.message); 
                  
                        }    
                    } 
                    fetchDataTerm()   
                    fetchDataCompany() 
                    },[])

    useEffect(()=> {
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetDataCardCompany(detailModal.id)
                setDetail(data?.data?.result)
                setNewCard(data?.data?.result)
             }
         } catch (error) {
             console.log(error);
         }   
     }
     getDetail()
    },[detailModal])
   

    const deleteFunc = async() => {
     try {
           await api.detailDeleteDataCardCompany(detailModal.id)
           alertDispatch({type: DETAIL_MODAL, payload: false})
           alertDispatch({type:DELETE_POST, payload: {id:detailModal.id}})
           toast.success('Silme İşlemi Başarılı')

     } catch (error) {
    
     }
    }

    const updateFunc = async() => {
     try {
         const updatePost = await api.updatePostDataCardCompany(JSON.stringify(detail)) 
         companyDispatch({type:COMPANY_CARD_UPDATE_DATA, payload: updatePost.data.result})
         alertDispatch({type: DETAIL_MODAL, payload: false})
         toast.success('Update İşlemi Başarılı')
        } catch (error) {
         
     }
    }
   

    const closeModal = () => {
      alertDispatch({type: DETAIL_MODAL, payload: false})
    }
    const  handleChangeDropdown = (e) => {
        setNewCard(prev => ({...prev,[e.id]:e.value}))
        setNewCardPlaceholder(prev => ({...prev,[e.id]:e.label}))
        setDetail({...detail , [e.id]: e.value})
        setErrors(prev => ({...prev,[e.id]:""}))
    };
   
    const validateForm = () => {
        let isValid = true
        if (!newCard.companyId) {
            setErrors(prev =>  ({...prev,description:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newCard.periodId) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        return isValid
    }

  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={detailModal.open}  
        >
          
        <div className='modalCreateTarget'>
            <div className='company-modal'>
                <div className="company-modal--container">
                    <div className="company-modal--container--body">
                        <div className="company-modal--container--body--half-width">
                        <div className='company-modal--container--input-area'>
                                    <div className="company-modal--container--input-area--label">
                                        Şirket *
                                    </div>
                                    <div className='react-dropdown-div'>
                                        <Select onChange={handleChangeDropdown} name="companyId" placeholder={detail?.companies?.name ? detail?.companies?.name : "Seçiniz"}  options={defaultOptions} />
                                    </div>
                                    {errors.companyId && <span className='company-modal--container--error'>{errors.companyId}</span>}
                                </div>
                        </div>


                        <div className="company-modal--container--body--half-width">
                            
                       


                            <div className="company-modal--container--input-area">
                                <div className="company-modal--container--input-area--label">
                                    Dönem *
                                </div>
                                <div className='react-dropdown-div'>
                                        <Select onChange={handleChangeDropdown} placeholder={detail?.periods?.name ? detail?.periods?.name : "Seçiniz"} name="periodId" options={defaultOptions2} />
                                </div>
                                {errors.periodId && <span className='company-modal--container--error'>{errors.periodId}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='company-modal--container--footer'>
                    <IconButton icon ={ <RiEditBoxLine style={{width:"20px"}} color="#fff" fill="#fff" /> }  buttonClick={updateFunc} imgRight="OK" title="Güncelle" maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                    <IconButton icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> } buttonClick={deleteFunc} imgRight="OK" title="Sil" maxWidth="130px" background="#f9423a" color='white' borderColor='#f9423a'/>
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default CompanyCardDeleteUpdateModal