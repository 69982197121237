import React, { useEffect } from 'react'

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as api from '../../api/index'
import Button from '../button/Button';
import { useAlertDispatch, useAlertState } from '../../context/alert/alertContext';
import IconButton from '../button/IconButton';
import images from '../../images/images';
import { useCompanyDispatch, useCompanyState } from '../../context/company/companyContext';
import GroupCreateModal from '../modal/groupPageModals/GroupCreateModal';
import GroupDeleteUpdateModal from '../modal/groupPageModals/GroupDeleteUpdateModal';
import { GROUP_FETCH_DATA, GROUP_DELETE_DATA } from '../../context/company/actions';
import {CREATE_COMPANY_MODAL, DETAIL_MODAL} from '../../context/alert/actions';
import useToastify from '../../hooks/useToastify';


     
      const DataTableComp = () => { 
      //const alertDispatch = useAlertDispatch();
      const {detailModal,createCompanyModal,deletePost} = useAlertState()
      const alertDispatch = useAlertDispatch();
      const { errorToast } = useToastify()
      const {dataFetchGroup} = useCompanyState()
      const companyDispatch = useCompanyDispatch()
   
      //delete post
      useEffect(()=>{
        if(deletePost?.id){
          companyDispatch({type:GROUP_DELETE_DATA, payload: deletePost.id})
          //setDataFetch(arr => arr.filter(arr => arr.id !== deletePost.id))
        }    
      },[detailModal.open])

      useEffect(()=>{ 
        const fetchData = async () => {

          try {
          
          const datas = await api.fetchDataGroup();
          
          companyDispatch({type:GROUP_FETCH_DATA, payload: datas.data?.result})
          
          //setDataFetch(datas.data?.result);
          
          if(datas.data.result.length < 1) {       
            errorToast("Kayıt bulunmamaktadır.")     
          }

          } catch (error) {
          
          //console.log("error", error.message);
          
          }
          
          }
          
          fetchData()
          
          },[])

        const columns = [
          {
            name: "Şirket Grup Adı",
            selector: "title",
            sortable: true
          },
         
          {
            name: "Şirket Grup Açıklaması",
            selector: "year",
            sortable: true,
          },
          {
            name: "",
            selector: "genres",
            //sortable: true,
            cell: d => <div onClick={()=> alertDispatch({type: DETAIL_MODAL, payload: {open: true, id : d.id} })} style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'end'}}><Button title="Detaylar" maxWidth='130px' height='35px'/></div>
          }
      ]

        let data = [];
        dataFetchGroup?.map(res => (
          data.push(
            {
              id: res.id,
              title:res.name,
              year:res.description,
              genres:res.description
            }
            )
        ))
      

      const tableData = {
        columns,
        data
      };

     // console.log('data',dataFetch)
      //console.log('newRow',newRow);
    
  
  return (
    <div>
        <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          //defaultSortAsc={false}
          pagination
          highlightOnHover
          fixedHeader={true}
          noDataComponent= {""} 
          //fixedHeaderScrollHeight={'388px'}
        />
      </DataTableExtensions>

      {/* deneme işlemleri sonrası alttaki yapılar aktif hale getirilecek */}
       <div 
      onClick={()=>alertDispatch({type: CREATE_COMPANY_MODAL, payload: {open:true}})}
      className='createRowBtn'>
      <IconButton maxWidth='253px' title="YENİ ŞİRKET GRUBU OLUŞTUR" image={images.plusBlue} color='#1392E5' borderColor='#1392E5'/>
      </div> 
       {detailModal.open && <GroupDeleteUpdateModal/>} 
      {createCompanyModal.open && <GroupCreateModal/>} 
    </div>
  )
}

export default DataTableComp