import React, {useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import * as api from '../../../api/index'
import { toast } from 'react-toastify';

import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import { useCompanyDispatch } from '../../../context/company/companyContext';
import IconButton from './../../button/IconButton';
import images from '../../../images/images';
import {GROUP_UPDATE_DATA} from '../../../context/company/actions';
import {DELETE_POST, DETAIL_MODAL} from '../../../context/alert/actions';
import {RiDeleteBinLine, RiEditBoxLine} from 'react-icons/ri';
import { useLoader } from '../../../hooks/useLoader';
import {useRequest} from '../../../hooks/useRequest';
import Select from 'react-select';
import roles from '../../../constants/Roles';


const GroupDeleteUpdateModal = ({title,whichModal}) => {
    const {detailModal} = useAlertState()
    const alertDispatch = useAlertDispatch();
    const [companies,setCompanies] = useState()
    const companyDispatch = useCompanyDispatch()
    const setLoader = useLoader()
    const request = useRequest()
    const [detail, setDetail] = useState(null)
    const [selectedGroupLead,setSelectedGroupLead] = useState()
    const [groupMinisterAsistants, setGroupMinisterAsistants] = useState([])
    const [users,setUsers] = useState([])
    const [errors,setErrors] = useState({name:"",description:""})
    const [newRow, setNewRow] = useState({
        name:'', description:'',hyperionField:'deneme', dialogField: ''
      })

    useEffect(()=> {
        const getAllUsers = async() => {
            try {
                const data = await api.getAllUsers(detailModal.id)
                setUsers(data?.data?.result?.filter(u => u.roles[0].roleId == roles.MINISTER_ROLE_ID))
                setGroupMinisterAsistants(data?.data?.result?.filter(u => u.roles[0].roleId == roles.VICE_PRESIDENT_ROLE_ID))
             } catch (error) {
                 console.log(error);
             }   
        }
     const getDetail = async() => {
         try {
            if(detailModal.id){
                const data = await api.detailGetDataGroup(detailModal.id)
                setDetail(data?.data?.result)
                setNewRow(data?.data?.result)
                setSelectedGroupLead({label:`${data?.data?.result.groupLeader.firstName} ${data?.data?.result.groupLeader.lastName} (${getCompanyName(data?.data?.result.groupLeader.company)})`,value:data?.data?.result.groupLeader.id,id:'groupLeaderId'})
             }
         } catch (error) {
             console.log(error);
         }   
     }
     api.fetchData().then(res => {
        setCompanies(res?.data?.result)
      })
     getDetail()
     getAllUsers()
    },[detailModal])
   

    const deleteFunc = async() => {
    setLoader(true)
     try {
           await api.detailDeleteDataGroup(detailModal.id)
           alertDispatch({type: DETAIL_MODAL, payload: false})
           alertDispatch({type: DELETE_POST, payload: {id:detailModal.id}})
           toast.success('Silme İşlemi Başarılı')
        setLoader(false)
     } catch (error) {
        setLoader(false)
             
     }
    }

    const updateFunc = async() => {
        if (validateForm()) {
            setLoader(true)
            await request('/CompanyGroup/Update',{method:'POST',data:JSON.stringify({...detail,companyOfCompanyVicePresidentUsers:detail.companyOfCompanyVicePresidentUsers.map(u => ({userId:u.userId ? u.userId : u.value}))})}).then(res => {
                if (res) {
                    companyDispatch({type: GROUP_UPDATE_DATA, payload: res.data.result})
                    alertDispatch({type: DETAIL_MODAL, payload: false})
                    toast.success('Güncelleme İşlemi Başarılı')
                }
            })
            setLoader(false)
        }
    }

    const getCompanyName= (companyCode) => {
        const company = companies?.find(c => c.code == companyCode)
        if (company) {
          return company.name
        }else {
          return companyCode
        }
      }
   
    const handleDropdownChange = (e) => {
        setNewRow({...newRow, [e.id]:e.value})
        setDetail({...detail, groupLeaderId:e.value})
        setSelectedGroupLead({...e})
        setErrors(prev => ({...prev,[e.id]:""}))
    }
    const handleMultiDropdownChange = (e) => {
        setNewRow({...newRow, companyOfCompanyVicePresidentUsers:e})
        setDetail({...newRow, companyOfCompanyVicePresidentUsers:e})
    }
    const closeModal = () => {
      alertDispatch({type: DETAIL_MODAL, payload: false})
    }
    const handleNewRowChange = (e) => {
        setNewRow({...newRow, [e.target.name]:e.target.value})
        setDetail({...detail , [e.target.name]: e.target.value})
        setErrors(prev => ({...prev,[e.target.name]:""}))
      }
    const validateForm = () => {
        let isValid = true
        if (!newRow.name) {
            setErrors(prev=> ({...prev,name:"Bu alan zorunludur"}))
            isValid =false
        }
        if (!newRow.groupLeaderId) {
            setErrors(prev=> ({...prev,groupLeaderId:"Bu alan zorunludur"}))
            isValid =false
        }
        return isValid
    }
    if (!detail) {
        return <div></div>
    }
  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(1px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={detailModal.open}  
        >
            <div className='modalCreateTarget'>
            <div className='company-modal'>
                <div className="company-modal--container">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Şirket Grup Adı *
                            </div>
                            <input type="text" placeholder='Birim grup adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.name} name="name" onChange={handleNewRowChange} value={newRow?.name} />
                            {errors.name && <span className='company-modal--container--error'>{errors.name}</span>}
                        </div>

                    </div>

                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Diyalog Alanı
                            </div>
                            <input type="text" placeholder=' Diyalog alanı girin' className='company-modal--container--input-area--input' defaultValue={detail?.dialogField} name="dialogField" onChange={handleNewRowChange} value={newRow?.dialogField} />
                            {/* {errors.name && <span className='company-modal--container--error'>{errors.name}</span>} */}
                        </div>

                    </div>

                    <div className="company-modal--container--body">
                    <div className="company-modal--container--head">
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                               Şirket Grup Açıklaması
                            </div>
                            <input type="text" placeholder='Şirket grup adı girin' className='company-modal--container--input-area--input' defaultValue={detail?.description} name="description" onChange={handleNewRowChange} value={newRow?.description} />
                        </div>
                    </div>
                    <div className="company-modal--container--head"> 
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                                Şirket Grup Başkanı *
                            </div>
                            <div className="react-dropdown-div">
                                <Select onChange={handleDropdownChange} value={selectedGroupLead ? selectedGroupLead : null} options={users?.map(u => ({label:`${u.firstName} ${u.lastName} (${getCompanyName(u.company)})`,value:u.id,id:'groupLeaderId'}))} placeholder='Seçiniz' />
                            </div>
                            {errors.groupLeaderId && <span className='company-modal--container--error'>{errors.groupLeaderId}</span>}
                        </div>
                    </div> 
                    <div className="company-modal--container--head"> 
                        <div className="company-modal--container--input-area">
                            <div className="company-modal--container--input-area--label">
                            Şirket Grup Başkan Yardımcıları 
                            </div>
                            <div className="react-dropdown-div">
                                <Select  defaultValue={detail?.companyOfCompanyVicePresidentUsers ? [...detail.companyOfCompanyVicePresidentUsers.map(u => ({value:u.userId,label:u.companyUser}))]:null} isMulti onChange={handleMultiDropdownChange} options={groupMinisterAsistants?.map(u => ({label:`${u.firstName} ${u.lastName} (${getCompanyName(u.company)})`,value:u.id}))}  placeholder='Seçiniz' />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='company-modal--container--footer'>
                            <IconButton icon ={ <RiEditBoxLine style={{width:"20px"}} color="#fff" fill="#fff" /> }  buttonClick={updateFunc} imgRight="OK" title="Güncelle" maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                            <IconButton icon ={ <RiDeleteBinLine style={{width:"20px"}} color="#fff" fill="#fff" /> } buttonClick={deleteFunc} imgRight="OK" title="Sil" maxWidth="130px" background="#f9423a" color='white' borderColor='#f9423a'/>
                    </div>
                    <div className='company-modal--container--close-btn' onClick={closeModal}>
                        <img src={images.close} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default GroupDeleteUpdateModal