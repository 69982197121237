import React, {useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import { toast } from 'react-toastify';
import { useAlertDispatch, useAlertState } from '../../../context/alert/alertContext';
import IconButton from './../../button/IconButton';
import images from '../../../images/images';
import {CREATE_TARGET_BONUS_MODAL} from '../../../context/alert/actions';
import * as api from '../../../api/index'
import { useCompanyDispatch, useCompanyState } from '../../../context/company/companyContext';
import Select  from 'react-select';
import {useLoader} from '../../../hooks/useLoader';
import useToastify from '../../../hooks/useToastify';



const TargetBonusModal = ({id}) => {
  const styles = {
    width:'100%',
    height:'38px',
    outline:'none',
    border:'1px solid lightgray',
    borderRadius:'4px',
    textAlign:'center',
    //marginLeft:'10px'
}
    const {createTargetBonusModal} = useAlertState()
    const {dataFetchCompanyCardBonusDetail} = useCompanyState()
    const alertDispatch = useAlertDispatch();
    const setLoader = useLoader()
    const { errorToast } = useToastify()
    const companyDispatch = useCompanyDispatch()
    const [dataFetchAim, setDataFetchAim] =useState([])
    const [dataFetchUnit, setDataFetchUnit] =useState([])
    const [createTargetBonusCard,setCreateTargetBonusCard ] = useState({
      companyCardId: id,
      targerKPIId:'',
      scoreValue: null
  })
  const [newRowUnit, setNewRowUnit] = useState({
    name: "",
    description: "deneme",
    hyperionField: "deneme",
  });

  useEffect(()=>{ 
    const fetchDataAim = async () => {
      try {   
      const datas = await api.fetchDataAim(); 
      setDataFetchAim(datas.data?.result)
      } catch (error) {   
       
      } 
      }

      const fetchDataUnit = async () => {
        try {   
        const datas = await api.fetchDataUnit(); 
        setDataFetchUnit(datas.data?.result)
        } catch (error) {   
          
        } 
        }
      
        fetchDataUnit()
        fetchDataAim()
      
      },[])

      let defaultOptions =  []
      let defaultOptions2 =  []
  
        dataFetchAim?.forEach(data => {
           return defaultOptions.push(
              {value:data?.id, label:data?.name, id:'targerKPIId' }
          )
        })
        dataFetchUnit?.forEach(data => {
          return defaultOptions2.push(
              {value:data?.id, label:data?.name, id:'unitId' }
          )
        })

      const handleChangeDropdown = (e) => {
        setCreateTargetBonusCard({...createTargetBonusCard, [e.id] : e.value})
      }
      const handleChangeText = (e) => {
        setCreateTargetBonusCard({...createTargetBonusCard, [e.target.name] : Number( e.target.value)})
      }

      const newCreateTargetBonusCard = async() => {
        setLoader(true)
        try { 
            const newData = await api.createPostDataCardCompanyBonusDetail(JSON.stringify(createTargetBonusCard)); 
            companyDispatch({type:"COMPANYCARDPOSTDATABONUSDETAIL", payload: newData.data.result})
            //setDataFetch([...dataFetch, newData.data.result])
            alertDispatch({type:'CREATETARGETBONUSMODAL', payload: false})
            toast.success("Ekleme İşleminiz Başarılı !!!")
            setLoader(false)
        } catch (error) {
          setLoader(false)
          errorToast(error.response.data.errors.ScoreValue[0])
        }
      }

    const closeModal = () => {
      alertDispatch({type: CREATE_TARGET_BONUS_MODAL, payload: false})
    }
 
    let newUnitFunc = async () => {
      try {
        let newData = await api.createPostDataUnit(JSON.stringify(newRowUnit));
        let insertedUnit = {...newData?.data?.result};
        setDataFetchUnit([...dataFetchUnit, insertedUnit]);
        //dataFetchUnit.push({ ...insertedUnit  })
        let normalizedUnit = {id: insertedUnit?.id, name: insertedUnit?.name, hyperionField: insertedUnit?.hyperionField, description: insertedUnit?.description}
        let unitsExceptNew = dataFetchUnit.filter(
          (f) => f.name !== newRowUnit?.name
        );
        unitsExceptNew.push(normalizedUnit)
        //setDataFetchUnitt(prev=> ([...prev,]))
        //await companyDispatch({ type: UNIT_POST_DATA, payload: normalizedUnit });
        setCreateTargetBonusCard({ ...createTargetBonusCard, unitId: normalizedUnit.id });
        //newUnitGet()
      } catch (error) {
        console.log(error.message);
      }
    };
  
    const newUnitId = (e) => {
      if (e.key === "Enter") {
        //setCreateTargetCard({...createTargetCard, [e.id] : searchfilter[0]?.id})
        newUnitFunc();
      }
    };
  

  return (
      <>
       <Backdrop
        sx={{ backdropFilter:'blur(3px)',color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createTargetBonusModal.open}  
        >
        <div className='modalCreateTarget'>
            <div className='modalCreateTarget-box'>
                <div className='modalCreateTarget-box-close'>
                <div className='modalCreateTarget-box-close-closeBtn' onClick={closeModal}>
                  <img src={images.close} alt="" />
                </div>
                   <div className='modalCreateTarget-box-close-h1'>Bonus Hedef Oluştur</div>
                </div>
                <div className='modalCreateTarget-box-kpi'>
                    <div className='modalCreateTarget-box-kpi-head'>Hedef Adı *</div>
                    <div style={{marginTop:'10px'}}>
                    <Select onChange={handleChangeDropdown} name="type" placeholder={"Seçiniz"}  options={defaultOptions} />
                    </div>
                </div>
                
                    <div  className='modalCreateTarget-box-kpi-sub'>
                    <table style={{width:'60%',margin:'auto'}}>
                        <tr>
                        <th>Puan Değeri</th>
                        </tr>
                        <tr>
                        <td><input  onInput={(e) => {if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0,e.target.maxLength);
                             }}    maxLength={5} placeholder='Puan Değeri' style={{...styles}} type="number" name='scoreValue' onChange={handleChangeText}/></td>
                        </tr>
                </table>
                    </div>
                
               
                <div onClick={newCreateTargetBonusCard} className='modalCreateTarget-box-btn'>
                <IconButton imgRight="OK" title="OLUŞTUR" image={images.rightArrow} maxWidth="130px" background="#1392E5" color='white' borderColor='#1392E5'/>
                </div>
            </div>
        </div>
        </Backdrop>
        </>
   
  )
}

export default TargetBonusModal