import React from 'react'
import {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useAlertState} from '../../context/alert/alertContext';

const Relocator = () => {
const {errorStatus} = useAlertState()
const navigate = useNavigate()
useEffect(()=>{
  if (errorStatus?.navigate) {
    navigate(errorStatus.navigate)
  }
},[errorStatus])
  return (
    <></>
  )
}

export default Relocator