import React, { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import CompanyCreateTarget from '../components/createTarget/CompanyCreateTarget'
import Opinions from '../components/createTarget/opinions/Opinions'
import Target from '../components/createTarget/Target'
import TargetBonus from '../components/createTarget/TargetBonus'
import * as api from '../api/index'
import {useCompanyState} from '../context/company/companyContext'
import LgDropdown from '../components/dropdown/lgDropdown/LgDropdown'
import {getUserInfo} from '../utils/Auth'
import {useAlertState} from '../context/alert/alertContext'
import roles from '../constants/Roles'
import moment from 'moment'

const CreateTarget = () => {

  const {id} = useParams();
  const userInfo = getUserInfo()
  const [companyDetail, setCompanyDetail] = useState([])
  const [allLogs,setAllLogs] = useState([])
  const [updatedTotal, setUpdatedTotal] = useState(null)
  const {dataFetchCompanyCardDetail, dataFetchCompanyCardBonusDetail} = useCompanyState()
  const { createTargetModal } = useAlertState()
  const token = JSON.parse(localStorage.getItem('auth'))
  const expireTime = JSON.parse(localStorage.getItem('expireTime'))
     const getSSOLink = async () => {
       const ssoLink = await api.loginSSOpage();
       window.location.href = ssoLink.data
       //console.log("ssoLink",ssoLink.data)
     }  
  useEffect(() => {
    
     if(expireTime && expireTime < moment(new Date()).format()){
       localStorage.clear(); 
       getSSOLink()
     }
     if((!token || !expireTime) && window.location.pathname != '/noauth'){
      localStorage.clear(); 
      getSSOLink()
    } 
  },[])
  useEffect(()=>{
    const fetchCompany = async() => {
      try {
        const data = await api.detailGetDataCardCompany(id)
        setCompanyDetail(data?.data?.result)
      } catch (error) {

      }
    }
    if (id && !createTargetModal?.open && token && expireTime && expireTime > moment(new Date()).format()) {
      fetchCompany()
    }
  },[createTargetModal])
  useEffect(() => {
    const fetchLogs = async() => {
      const logsData = await api.getAllLogs(id)
      setAllLogs(logsData?.data?.result)
    }
    if (token && expireTime && expireTime > moment(new Date()).format()) {
      fetchLogs()
    }
  },[companyDetail, id])
  return (
    //<div className='pagesBox-container'>
    (token && expireTime && expireTime > moment(new Date()).format()) ? <>
    <div style={{minWidth:'1225px',width:'100%', margin:'auto',overflowX:'auto'}}>
        <CompanyCreateTarget companyDetail={companyDetail} setCompanyDetail={setCompanyDetail} id={id}/>
        <Target setUpdatedTotal={setUpdatedTotal} companyDetail={companyDetail} setCompanyDetail={setCompanyDetail} id={id}/>
        <TargetBonus updatedTotal={updatedTotal} setUpdatedTotal={setUpdatedTotal} companyDetail={companyDetail} setCompanyDetail={setCompanyDetail} id={id && id}/>
        {
          (dataFetchCompanyCardDetail?.length > 0 || dataFetchCompanyCardBonusDetail?.length > 0 ) && <Opinions id={id} setCompanyDetail={setCompanyDetail} companyDetail={companyDetail} dataFetchCompanyCardDetail={dataFetchCompanyCardDetail} dataFetchCompanyCardBonusDetail={dataFetchCompanyCardBonusDetail}/>
        }
       {
        (userInfo?.RoleId == roles.STRATEGY_TEAM_ROLE_ID || userInfo?.RoleId == roles.STRATEGY_TEAM_DIRECTOR_ROLE_ID) && <LgDropdown allLogs={allLogs}  title="Loglar"/>
       } 
   </div>
    </> :
    <>Lütfen Giriş Yaptığınızdan Emin Olun</>
  )
}

export default CreateTarget