import React from 'react'
import DataTableComp from '../components/unitManagementPage/DataTableComp'

const UnitManagementPage = () => {
  return (
    <div className='unit-management-page' >
        <DataTableComp/>    
    </div>
  )
}

export default UnitManagementPage