import Backdrop from "@mui/material/Backdrop";
import React, { useReducer, useState } from "react";
import * as api from '../../../api/index'
import {
  useAlertDispatch,
  useAlertState,
} from "../../../context/alert/alertContext";
import images from "../../../images/images";
import IconButton from "../../button/IconButton";
import Input from "../../input/Input";
import {toast} from "react-toastify";
import {useCompanyDispatch, useCompanyState} from "../../../context/company/companyContext";
import { ALL_ROLES_DATA } from "../../../context/company/actions";
import {ROLE_MANAGEMENT_MODAL} from "../../../context/alert/actions";
import { useLoader } from '../../../hooks/useLoader'
import {useRequest} from "../../../hooks/useRequest";
const CreateRoleModal = () => {
  const [role,setRole] = useState()
  const { roleManagementModal } = useAlertState();
  const alertDispatch = useAlertDispatch();
  const companyDispatch = useCompanyDispatch()
  const {allRolesData} = useCompanyState()
  const setLoader = useLoader()
  const request = useRequest()

  const handleChange = (e) => {
    setRole(prev => ({...prev,name:e.target.value}))
  }
  const closeModal = () => {
    alertDispatch({
      type: ROLE_MANAGEMENT_MODAL,
      payload: { open: false , isChaneged:true},
    });
    setRole()
  };
  const handleSubmit = async() => {
    setLoader(true)
    await request('/Role/Create',{method:'POST',data:role}).then(res => {
      if (res) {
          companyDispatch({type: ALL_ROLES_DATA,payload:[...allRolesData,res.data?.result]})
          toast.success('Rol Ekleme Başarılı')
          closeModal()

      }
    })
    setLoader(false)
  }
  return (
    <Backdrop
      sx={{
        backdropFilter: "blur(1px)",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={roleManagementModal.open}
    >
      <div className="modalCreateTarget">
          <div className="user-modal react-dropdown-div">
            <div className="user-modal--container">
              <div
                className="user-modal--container--close-btn"
                onClick={closeModal}
              >
                <img src={images.close} alt="" />
              </div>
            <Input handleChange={handleChange} label='Rol Adı' placeholder='Rol Adını Giriniz'/>
             <IconButton
                imgRight="OK"
                title="KAYDET"
                image={images.rightArrow}
                maxWidth="130px"
                background="#1392E5"
                color="white"
                borderColor="#1392E5"
                buttonClick={handleSubmit}
              />
            </div>
          </div>
      </div>
    </Backdrop>
  );
};

export default CreateRoleModal;
