import axios from "axios";
import moment from "moment";
import {toast} from "react-toastify";

const token = JSON.parse(localStorage.getItem('auth'));
const expireTime = JSON.parse(localStorage.getItem('expireTime'))


  axios.interceptors.response.use(response => {
    return response;
  }, error => {
  // if(expireTime < moment(new Date()).format()){
  //   localStorage.clear(); 
  //   getSSOLink()
  // }
  if (token && expireTime && window.location.pathname == '/noauth') {
    return error
  }
  // if (!token || !expireTime) {
  //   getSSOLink()
  // }
  if (error.response.status === 401) {
      window.location = '/noauth';
  }
  if (error.response.status === 404) {
      window.location = '/not-found';
    }
    if (error.response.status === 500) {
        window.location = '/server-error';
    }
    else{
      if (typeof error.response.data.errors == 'string') {
          toast.error(error.response.data.errors,{
              autoClose: false 
            })
        return error
      }
      else {
      Object.values(error.response.data.errors).forEach(val =>{
          toast.error(val[0],{
            autoClose:false
          })
      })
      return error
  }
  }
  });

const isDevelopment = process.env.NODE_ENV !== 'production'

const apiUrl = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PILOT_URL
axios.defaults.baseURL = apiUrl
const option = {
    headers: {
        'Authorization': `Bearer ${token}`,
        //"Content-Type": "multipart/form-data",
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
      }
}

const optiontwo = {
  headers: {
      'Authorization': `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      //'Content-Type': 'application/json',
      // 'Accept': 'application/json'
      //"enctype":'multipart/form-data'
    }
}
//sidebar
export const sidebarFetch = () => axios.get(`/Menu/GetAll`,option)


//company management
export const fetchData = () => axios.get(`/Company/GetAll`,option)
export const createPostData = (data) => axios.post(`/Company/Create`,data,option)
export const detailGetData = (id) => axios.get(`/Company/GetById?id=${id}`,option)
export const detailDeleteData = (id) => axios.delete(`/Company/Delete?id=${id}`,option)
export const updatePostData = (data) => axios.post(`/Company/Update`,data,option)

//company card management
export const fetchDataCardCompany = (id="",id2="",id3="") => axios.get(`/CompanyCard/GetAll?FilterCompanyGroupId=${id}&FilterPeriodId=${id2}&FilterProcessStatus=${id3}`,option)
export const fetchProcessStatus = () => axios.get(`/CompanyCard/GetProcessStatusAll`,option)
export const createPostDataCardCompany = (data) => axios.post(`/CompanyCard/Create`,data,option)
export const detailGetDataCardCompany = (id) => axios.get(`/CompanyCard/GetById?id=${id}`,option)
export const detailDeleteDataCardCompany = (id) => axios.delete(`/CompanyCard/Delete?id=${id}`,option)
export const updatePostDataCardCompany = (data) => axios.post(`/CompanyCard/Update`,data,option)

// all logs
export const getAllLogs = (companyCarId) => axios.get('/CompanyCard/GetLogAll',{...option,params:{FilterCompanyCardId:companyCarId}})


//company card detail (create target area) management
export const fetchDataCardCompanyDetail = (id) => axios.get(`/CompanyCardDetail/GetAll/?FilterCompanyCardId=${id}`,option)
export const createPostDataCardCompanyDetail = (data) => axios.post(`/CompanyCardDetail/Create`,data,option)
export const detailGetDataCardCompanyDetail = (id) => axios.get(`/CompanyCardDetail/GetById?id=${id}`,option)
export const detailDeleteDataCardCompanyDetail = (id) => axios.delete(`/CompanyCardDetail/Delete?id=${id}`,option)
export const updatePostDataCardCompanyDetail = (data) => axios.post(`/CompanyCardDetail/Update`,data,option)
export const updatePostDataCardCompanyDetailOrder = (data) => axios.post(`/CompanyCardDetail/OrderUpdate`,data,option)

//company card  bonus detail (create bonus target area) management
export const fetchDataCardCompanyBonusDetail = (id) => axios.get(`/CompanyCardBonusDetail/GetAll/?FilterCompanyCardId=${id}`,option)
export const createPostDataCardCompanyBonusDetail = (data) => axios.post(`/CompanyCardBonusDetail/Create`,data,option)
export const detailGetDataCardCompanyBonusDetail = (id) => axios.get(`/CompanyCardBonusDetail/GetById?id=${id}`,option)
export const detailDeleteDataCardCompanyBonusDetail = (id) => axios.delete(`/CompanyCardBonusDetail/Delete?id=${id}`,option)
export const updatePostDataCardCompanyBonusDetail = (data) => axios.post(`/CompanyCardBonusDetail/Update`,data,option)
export const updatePostDataCardCompanyDetailOrderBonus = (data) => axios.post(`/CompanyCardBonusDetail/OrderUpdate`,data,option)

//aim management 
export const fetchDataAim = () => axios.get(`/TargetKPI/GetAll`,option)
export const createPostDataAim = (data) => axios.post(`/TargetKPI/Create`,data,option)
export const detailGetDataAim = (id) => axios.get(`/TargetKPI/GetById?id=${id}`,option)
export const detailDeleteDataAim = (id) => axios.delete(`/TargetKPI/Delete?id=${id}`,option)
export const updatePostDataAim = (data) => axios.post(`/TargetKPI/Update`,data,option)

//unit management 
export const fetchDataUnit = () => axios.get(`/Unit/GetAll`,option)
export const createPostDataUnit = (data) => axios.post(`/Unit/Create`,data,option)
export const detailGetDataUnit = (id) => axios.get(`/Unit/GetById?id=${id}`,option)
export const detailDeleteDataUnit = (id) => axios.delete(`/Unit/Delete?id=${id}`,option)
export const updatePostDataUnit = (data) => axios.post(`/Unit/Update`,data,option)

//companyGroup management 
export const fetchDataGroup = () => axios.get('/CompanyGroup/GetAll',option)
export const createPostDataGroup = (data) => axios.post('/CompanyGroup/Create',data,option)
export const detailGetDataGroup = (id) => axios.get(`/CompanyGroup/GetById?id=${id}`,option)
export const detailDeleteDataGroup = (id) => axios.delete(`/CompanyGroup/Delete?id=${id}`,option)
export const updatePostDataGroup = (data) => axios.post('/CompanyGroup/Update',data,option)



//term management 
export const fetchDataTerm = () => axios.get('/Period/GetAll',option)
export const createPostDataTerm = (data) => axios.post('/Period/Create',data,option)
export const detailGetDataTerm = (id) => axios.get(`/Period/GetById?id=${id}`,option)
export const detailDeleteDataTerm = (id) => axios.delete(`/Period/Delete?id=${id}`,option)
export const updatePostDataTerm = (data) => axios.post('/Period/Update',data,option)

// user management
export const getAllUsers = () => axios.get('/User/GetAll',option)
export const searchUsers = (name) => axios.get('/Cupid/SearchToName',{...option,params:{name}})
export const createUser = (data) => axios.post('/User/Create', data, option)
export const updateUser = (data) => axios.post('/User/Update', data, option)
export const deleteUser = (userId) => axios.delete('/User/Delete', {...option,params:{id:userId}})
// role management
export const getAllRoles = () => axios.get('/Role/GetAll',option)
export const getAllMailTemplates = () => axios.get('/MailTemplate/GetAll',option)
export const getMailTemplateById = (id) => axios.get(`/MailTemplate/GetById?id=${id}`,option)
export const updateMailTemplate = (data) => axios.post('/MailTemplate/Update',data,option)
export const createRole = (data) => axios.post('/Role/Create',data, option)
export const updateRole = (data) => axios.post('/Role/Update',data, option)
export const deleteRole = (roleId) => axios.delete('/Role/Delete',{...option,params:{id:roleId}})
// Permission management
export const getByPermissionRoleId = (roleId) => axios.get('/Role/GetByPermissionRoleId', {...option,params:{id:roleId}})
export const updatePermission = (data) => axios.post('/Role/PermissionUpdateAll',data, option)

// opinions 
export const opinionCreateDocuments = (data) => axios.post('/Opinion/CreateDocuments',data,optiontwo)
export const opinionDeleteDocuments = (id) => axios.delete(`/Opinion/Delete?id=${id}`,optiontwo)
export const opinionCreate = (data) => axios.post(`/Opinion/Create`,data,option)
export const opinionUpdate = (data) => axios.post(`/Opinion/Update`,data,option)
export const opinionFileDelete = (id) => axios.delete(`/Opinion/DeleteDocument?id=${id}`,optiontwo)
export const opinionGetAll = (filterTag,id) => axios.get(`/Opinion/GetAll`,{...option,params:{FilterTag:filterTag, FilterCompanyCardId:id,Sorting:'SendMesssageDateAt asc'}})
export const opinionDelete = (id) => axios.delete(`/Opinion/Delete?id=${id}`,option)

// notifications
export const getAllNotifications = (data) => axios.get('/Notification/GetAll',{...option,params:{...data}})
export const getCount = (data) => axios.get('/Notification/GetCount',{...option,params:{FilterUserId:data}})

//dialog
export const dialogSendEnd = (data) => axios.post('/Dialog/Create',data, option)

// excel import

export const importExcel = (data) => axios.post('/Excel/ExcelLoad',data,optiontwo)
export const returnExcelValue = (data) => axios.post('/Excel/ExcelReturnValue',data,optiontwo)

//Auth SSO 
export const loginSSOpage = () => axios.post('/Auth/LoginSsoPage',optiontwo)
export const loginSSOSessionId = (key) => axios.get(`/Auth/successLogin?key=${key}`,optiontwo)
export const logoutSSO = (key) => axios.post(`/Auth/LoggedOut?key=${key}`,optiontwo)
