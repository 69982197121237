import React, {useRef,useEffect, useCallback} from 'react';
import { DownloadTableExcel,useDownloadExcel } from 'react-export-table-to-excel';
import IconButton from '../components/button/IconButton';
import images from '../images/images';
import currencyFormatter from './InputFormatter';
import {ws} from 'sheetjs-style';
var XLSX = require("xlsx-color");

const HtmlExport = ({companyDetail,dataFetchCompanyCardDetail,dataFetchCompanyCardBonusDetail,isBegin,takeMessage,fileName}) =>  {
    const tableRef = useRef(null);
    const tableHeadRef = useRef(null);
    const tableBonusRef = useRef(null);
    const tableMsgRef = useRef(null);
    const formatRef = useRef(null);

    function exportTableToExcel(tableID, filename = ''){
        var downloadLink;
        var dataType = 'application/vnd.ms-excel';
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect?.outerHTML.replace(/ /g, '%20');
        
        // Specify file name
        filename = filename?filename+'.xls':'excel_data.xls';
        
        // Create download link element
        downloadLink = document.createElement("a");
        
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob){
            var blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            navigator.msSaveOrOpenBlob( blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
        }
    }
    
    const x = dataFetchCompanyCardDetail.map((data, i )=> {
        if(data.upperLimitMultiplier){
            return {
                ...data, 
                //upperLimitMultiplier: (XLSX.SSF.format('$#,##0.00', Number(data.upperLimitMultiplier)).split(1))
               // upperLimitMultiplier: currencyFormatter(data.upperLimitMultiplier).toString()
            }
        }else {
            return {...data}
        }
    })
    

    const xport = useCallback(async () => {
                //formatRef.current.style.msoNumberFormat = "mm\\/dd\\/yyyy"
                tableRef.current.style.textAlign ='left';
                tableHeadRef.current.style.backgroundColor = "#3B9B92";
                tableBonusRef.current.style.backgroundColor = "#3B9B92";
                tableMsgRef.current.style.backgroundColor = "#3B9B92";
                
        /* Create worksheet from HTML DOM TABLE */
       

        const table = document.getElementById("Table2XLSX");
        table.style.backgroundColor = 'red';
        const wb = XLSX.utils.table_to_book(table);
        const plusCount = (companyDetail?.processStatus == "ActualComplated" || companyDetail?.processStatus == "ActualCeoWaitingForApproval" || companyDetail?.processStatus == "ActualCeoComended") ? 9 : 8 
        var arr;
        var format1; 
        var format2;

        if(isBegin){
           if(companyDetail?.permission?.isMidtermShow){
               arr =  ['A3','B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3',`A${dataFetchCompanyCardDetail?.length + 8}`,`B${dataFetchCompanyCardDetail?.length + 8}`,`C${dataFetchCompanyCardDetail?.length + 8}`,`D${dataFetchCompanyCardDetail?.length + 8}`,`E${dataFetchCompanyCardDetail?.length + 8}`,`A${dataFetchCompanyCardDetail?.length + 8 + dataFetchCompanyCardBonusDetail?.length + 7}`,`B${dataFetchCompanyCardDetail?.length + 8 + dataFetchCompanyCardBonusDetail?.length + 7}`]
               format1 = ['A','B','C','D','E','F','G','H','I','J','K','L']
               format2 = ['A','B','C','D','E']
            }else{
               arr =  ['A3','B3','C3','D3','E3','F3','G3','H3','I3',`A${dataFetchCompanyCardDetail?.length + 8}`,`B${dataFetchCompanyCardDetail?.length + 8}`,`A${dataFetchCompanyCardDetail?.length + 8 + dataFetchCompanyCardBonusDetail?.length + 7}`,`B${dataFetchCompanyCardDetail?.length + 8 + dataFetchCompanyCardBonusDetail?.length + 7}`]
               format1 = ['A','B','C','D','E','F','G','H','I']
               format2 = ['A','B']
            }
        }else{
           if(companyDetail?.permission?.isMidtermShow){
               arr =  ['A3','B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3','O3',`A${dataFetchCompanyCardDetail?.length + 8}`,`B${dataFetchCompanyCardDetail?.length + 8}`,`C${dataFetchCompanyCardDetail?.length + 8}`,`D${dataFetchCompanyCardDetail?.length + 8}`,`E${dataFetchCompanyCardDetail?.length + 8}`,`F${dataFetchCompanyCardDetail?.length + 8}` ,`G${dataFetchCompanyCardDetail?.length + 8}`,`A${dataFetchCompanyCardDetail?.length + 8 + dataFetchCompanyCardBonusDetail?.length + 7}`,`B${dataFetchCompanyCardDetail?.length + 8 + dataFetchCompanyCardBonusDetail?.length + 7}`]
               format1 = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O']
               format2 = ['A','B','C','D','E','F']
            }else{
               arr =  ['A3','B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3',`A${dataFetchCompanyCardDetail?.length + 8}`,`B${dataFetchCompanyCardDetail?.length + 8}`,`C${dataFetchCompanyCardDetail?.length + 8}`,`D${dataFetchCompanyCardDetail?.length + 8}`,`A${dataFetchCompanyCardDetail?.length + plusCount  + dataFetchCompanyCardBonusDetail?.length + 7}`,`B${dataFetchCompanyCardDetail?.length + plusCount+ dataFetchCompanyCardBonusDetail?.length + 7}`]
               format1 = ['A','B','C','D','E','F','G','H','I','J','K','L']
               format2 = ['A','B','C']
            }
        }

        var wscols = [
        ];

        for (let i = 0; i < arr.length; i++) {
            wscols.push({wch:17})
            if (wb.Sheets.Sheet1[arr[i]] != undefined ) {
                wb.Sheets.Sheet1[arr[i]].s = {	
               

                    fill : {
                        fgColor : {
                            theme : 8,
                            tint : 0.3999755851924192,
                            rgb : '3B9B92'
                        }
                      },				
                    font: {
                      //name: '宋体',
                      sz: 12,
                      bold: true,
                      color: {rgb : "#8B0000"},
                      //backgroundColor:'red'
                    }  ,
                    // alignment: {
                    //     wrapText:true
                    // } 
                }
            }
            //wb.SSF('$#,##0.00', arr[i])
        }
       wb.Sheets.Sheet1['!cols'] = wscols;

    
       for (let i = 4; i < dataFetchCompanyCardDetail.length + 4; i++) {
        for (let j = 0; j < format1.length; j++) {
            wb.Sheets.Sheet1[`${format1[j]}${i}`].s = {
                numFmt: "#,##0.00;(#,##0.00)",
            }   
        }
       
       }

     
        /* Export to file (start a download) */
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      });

        const changedColor = () => {
            tableRef.current.style.textAlign ='left';
            tableHeadRef.current.style.backgroundColor = "#3B9B92";
            tableBonusRef.current.style.backgroundColor = "#3B9B92";
            tableMsgRef.current.style.backgroundColor = "#3B9B92";
        }
        //     const { onDownload } = useDownloadExcel({
        //         currentTableRef: tableRef?.current,
        //         filename: {fileName},
        //         sheet: 'Users'
        //     })


        return (
            <div >
                 {/* <DownloadTableExcel
                    filename={fileName}
                    sheet="users"
                    currentTableRef={tableRef?.current}
                > */}
                   {/* <button onClick={changedColor}> Export excel </button> */}
                   <IconButton buttonClick={xport} title="Excel" image={images.excelPrint} maxWidth="100px" color='#4DCE69' borderColor='#4DCE69'/>
                {/* </DownloadTableExcel> */}
                <table id='Table2XLSX' style={{display:'none',textAlign:'left'}}  ref={tableRef}>
                <tbody>
                    <tr>
                        <th >{companyDetail?.companies?.name}</th>
                        <th >{companyDetail?.periods?.name}</th>
                    </tr>
                    <tr>
                        <th>Hedef Tablosu</th>
                    </tr>    
                  </tbody>
                {
                    isBegin ? <tbody>
                    <tr ref={tableHeadRef} className='excelTr'>
                        <td bgColor="red">Hedef Adı</td>
                        <th>Birim</th>
                        <th>Agırlık</th>
                        <th>{Number(companyDetail?.periods?.name) - 1 + " Gerçekleşen"}</th>
                        <th>Alt Limit Çarpanı</th>
                        <th>Üst Limit Çarpanı</th>
                        <th>Hedef</th>
                        <th>0 Puan</th>
                        <th>150 Puan</th>
                      {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Değer':'En Güncel Değer'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Dönem':'En Güncel Dönem (Ay)'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>Acıklama</th>}  
                    </tr>
                    {
                        dataFetchCompanyCardDetail?.map(data => {
                           return( <tr>
                                <td>{data?.targetKpi?.name}</td>
                                {
                                data?.unit?.name == "%" ? <td>&#8291;%</td> : <td>{data?.unit?.name}</td>
                                } 
                                <td>{(data?.weight)}</td>
                                <td>{(data?.lastYearTarget)}</td>
                                <td>{(data?.underLimitMultiplier)}</td>
                                <td ref={formatRef}>{data?.upperLimitMultiplier}</td>
                                <td >{(data?.target)}</td>
                                <td>{(data?.underLimitValue)}</td>
                                <td>{(data?.upperLimitValue)}</td>
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualValue}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualPeriod}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualDefinition}</td>}  
                            </tr>)
                         })      
                    }               
                  </tbody> :
                  <tbody>
                 <tr ref={tableHeadRef} className='excelTr'>
                        <th >Hedef Adı</th>
                        <th >Birim</th>
                        <th >Agırlık</th>
                        <th>{Number(companyDetail?.periods?.name) - 1 + " Gerçekleşen"}</th>
                        <th >Alt Limit Çarpanı</th>
                        <th >Üst Limit Çarpanı</th>
                        <th >Hedef</th>
                        <th >0 Puan</th>
                        <th >150 Puan</th>
                        <th >{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'Yıl Sonu Tahmini':'Gerçekleşen'}</th>
                        <th >Puan</th>
                        <th >Agırlıklı Puan</th>
                        {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Değer':'En Güncel Değer'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Dönem':'En Güncel Dönem (Ay)'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>Acıklama</th>}  
                    </tr>
                    {
                        dataFetchCompanyCardDetail?.map(data => (
                            <tr>
                                <td>{data?.targetKpi?.name}</td>
                               {
                                data?.unit?.name == "%" ? <td>&#8291;%</td> : <td>{data?.unit?.name}</td>
                               } 
                                <td>{(data?.weight)}</td>
                                <td>{(data?.lastYearTarget)}</td>
                                <td>{(data?.underLimitMultiplier)}</td>
                                <td>{data?.upperLimitMultiplier}</td>
                                <td>{(data?.target)}</td>
                                <td>{(data?.underLimitValue)}</td>
                                <td ref={formatRef}>{(data?.upperLimitValue)}</td>
                                <td>{(data?.occured)}</td>
                                <td>{(data?.score)}</td>
                                <td>{(data?.weightedScore)}</td>
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualValue}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualPeriod}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualDefinition}</td>}  
                            </tr>
                        ))      
                    }         
                </tbody>
                }

                 <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                           {/* {!isBegin && <th>Şirket puanı Ara Toplamı: 12</th>} */}
                           {
                            !isBegin && <th>Şirket Puanı Ara Toplamı: </th>
                           }
                           {
                            !isBegin && <th>{companyDetail?.subTotalScore}</th>
                           }
                            
                          
                        </tr>    
                 </tbody>   

                 <tbody>
                        <tr></tr>           
                        <tr></tr>                     
                 </tbody>   
                 
                 <tbody>
                        <tr>
                            <th>Bonus Hedef Tablosu</th>
                        </tr>    
                 </tbody>

                 {
                    isBegin ? <tbody>
                    <tr ref={tableBonusRef} className='excelTr'>
                        <th>Hedef Adı</th>
                        {(companyDetail?.processStatus != "ActualGmDataEntry" && companyDetail?.processStatus != "ActualGmDataEntryFinish") && <th>Puan Değeri</th>} 
                        {/* <th>Gerçekleşen</th> */}
                        {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Değer':'En Güncel Değer'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Dönem':'En Güncel Dönem (Ay)'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>Acıklama</th>}  
                    </tr>
                    {
                        dataFetchCompanyCardBonusDetail?.map(data => (
                            <tr>
                                <td>{data?.targetKpi?.name}</td>
                                {
                                    (companyDetail?.processStatus != "ActualGmDataEntry" && companyDetail?.processStatus != "ActualGmDataEntryFinish") &&<td>{currencyFormatter(data?.scoreValue)}</td>
                                }
                                {/* <td>{currencyFormatter(data?.occured)}</td> */}
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualValue}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualPeriod}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualDefinition}</td>}  
                            </tr>
                        ))      
                    }               
                  </tbody> :
                  <tbody>
                 <tr ref={tableBonusRef} className='excelTr'>
                        <th>Hedef Adı</th>
                        {(companyDetail?.processStatus != "ActualGmDataEntry" && companyDetail?.processStatus != "ActualGmDataEntryFinish") && <th>Puan Değeri</th>} 
                        <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'Yıl Sonu Tahmini':'Gerçekleşen'}</th>
                         {(!companyDetail?.permission?.isPeriodBegin || companyDetail?.permission?.isMidtermOutFiledShow) && <th>Bonus Puanı</th>} 
                        {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Değer':'En Güncel Değer'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>{(companyDetail?.processStatus == "ActualGmDataEntry" || companyDetail?.processStatus == "ActualGmDataEntryFinish") ? 'En Güncel Fiili Dönem':'En Güncel Dönem (Ay)'}</th>}  
                      {companyDetail?.permission?.isMidtermShow &&  <th>Acıklama</th>}  
                    </tr>
                    {
                        dataFetchCompanyCardBonusDetail.map(data => (
                            <tr>
                                <td>{data?.targetKpi?.name}</td>
                                {
                                   (!companyDetail?.permission?.isPeriodBegin || companyDetail?.permission?.isMidtermOutFiledShow) &&  <td>{currencyFormatter(data?.scoreValue)}</td>
                                }
                                <td>{currencyFormatter(data?.occured)}</td>
                                {(!companyDetail?.permission?.isPeriodBegin || companyDetail?.permission?.isMidtermOutFiledShow) && <td>{data?.score}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualValue}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualPeriod}</td>}  
                                {companyDetail?.permission?.isMidtermShow && <td>{data?.actualDefinition}</td>}  
                            </tr>
                        ))      
                    }         
                </tbody>
                }      

                     <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {!isBegin && <th>Gerçekleşen Toplam Bonus Puanı: </th>}
                            {!isBegin && <th>{companyDetail?.occuredBonusScore}</th>}
                        </tr>  
                        {
                          (!isBegin && (companyDetail?.processStatus == "ActualComplated" || companyDetail?.processStatus == "ActualCeoWaitingForApproval" || companyDetail?.processStatus == "ActualCeoComended")) && 
                          <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Ceo Düzeltme Puanı: </th>
                          <th>{companyDetail?.ceoTotalScore}</th>
                      </tr> 
                        } 
                       
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {!isBegin &&  <th>Şirket Puanı: </th>}
                            {!isBegin &&  <th>{companyDetail?.totalScore}</th>}
                        </tr>  
                 </tbody>   

                 <tbody>
                        <tr></tr>           
                        <tr></tr>           
                        <tr></tr>           
                        <tr></tr>           
                 </tbody>   

                 {
                    isBegin ? <tbody>
                    <tr ref={tableMsgRef} className='excelTr'>
                        <th>Dipnot</th>
                        <th>Açıklama</th>
                    </tr>
                    {
                        takeMessage?.map(data => (
                            <tr>
                                <td>{data.tag}</td>
                                <td>{data.message}</td>
                            </tr>
                        ))      
                    }               
                  </tbody> :
                  <tbody>
                 <tr ref={tableMsgRef} className='excelTr'>
                        <th>Dipnot</th>
                        <th>Açıklama</th>
                    </tr>
                    {
                        takeMessage?.map(data => (
                            <tr>
                                <td>{data.tag}</td>
                                <td>{data.message}</td>
                            </tr>
                        ))      
                    }         
                </tbody>
                }      



                </table>

            </div>
        );
    }

    export default HtmlExport