import { createContext, useContext } from "react";
import {ERROR_STATUS} from "../alert/actions";
import { useAlertDispatch } from "../alert/alertContext";
const RequestContext = createContext()

export const RequestProvider = ({children}) => {
    const isDevelopment = process.env.NODE_ENV !== 'production'
    const apiUrl = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PILOT_URL
    const alertDispatch = useAlertDispatch()
    const checkStatus = (response) => {
        if (response.status >= 500 && response.status < 600) {
            return  {navigate:"/server-error",status:response.status}
        }
         else if (response.status == 404) {
            return  {navigate:'/not-found',status:response.status}
        }
         else if (response.status == 401) {
            return  {navigate:'/unauthorized',status:response.status}
        }
        else if (response.status >= 200 && response.status < 300) {
            return response
        }
    }
    function parseJSON(response) {
        return response.json ? response.json() : response;
      }

    let token = JSON.parse(localStorage.getItem('auth'));
    const request  = async(url,options={}) => {
        //const token = ''
        // options.headers = {
        //     'Accept-Language': getCookieValue('B2bLocalizationKey') ? getCookieValue('B2bLocalizationKey') : 'ru-RU',
        //     'Content-Language': 'tr-TR',
        //     // 'B2bLocalization': B2bLocalization,
        //     pragma: 'no-cache',
        //     // ${auth.getCookieValue('ApiToken')}
        //     Authorization: `Bearer ${token}`,
        //     'Content-Type': 'application/json',
        //     'Cache-Control': 'no-cache',
        //     ...options.headers,
        //   };
        options.headers = {
                //"Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // 'Accept': 'application/json'
        }
        const data = fetch(apiUrl+url,options).then(res => {
            const status = checkStatus(res)
                if (status.navigate) {
                    alertDispatch({ type: ERROR_STATUS, payload: status});
                }
                else {
                    return status
                }
        }).then(()=>parseJSON())
        return data
    }
    return <RequestContext.Provider value={request}>{children}</RequestContext.Provider>
}

export const useRequestContext = () => useContext(RequestContext)