import {Backdrop, CircularProgress} from '@mui/material'
import React from 'react'
import {useAlertState} from '../../context/alert/alertContext'

const Loader = () => {
    const { loader } = useAlertState()
  return (
    <>
    {
       loader
       ?  <Backdrop
            style={{zIndex:'100000'}}
            open={loader.open || false}
        >
            <div className='default-progress'>
                <CircularProgress />
            </div>
        </Backdrop>
    :<></> 
        }
    </>
  )
}

export default Loader