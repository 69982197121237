import React from 'react'

import ScreenSize from '../../utils/ScreenSize'
import  NotificationMenu  from './navbarMenuSub/NotificationMenu';


const NavbarMenu = () => {
    const screenSize = ScreenSize();

  return (
    <div className='navbar-right'>
        {
             screenSize > 603 && (
                 <>
                <NotificationMenu/>
                </>
             )
        }
        
    </div>
  )
}

export default NavbarMenu